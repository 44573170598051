import React, { Component } from 'react';
import { bindingsToJsMap } from '../common/sparql/ResultSet';
import { postQuery } from '../common/sparql/PostQuery';
import * as d3 from 'd3';



class CORDISKeywords extends Component {

    async componentDidMount() {
        //extent the number of keyword based on the number of SDG?

        let query = `PREFIX : <http://unics.cloud/ontology#>
            SELECT
            ?keywordCORDIS (COUNT(DISTINCT ?projId) AS ?numProjs)
            WHERE {
            ?proj a :EC-Project .
            ?proj :unicsId ?projId .
            ?proj :startingYear ?year .
            
            ?proj :sdgReified ?sdgr .
            ?sdgr :sdg ?sdg .
            ?sdg :shortName ?sdgName .
            filter (?sdgName = "${this.props.sdgLabel}") .
            ?sdgr :keyword ?kw .
            ?kw :textualValue ?keyword .
            filter (?keyword = "${this.props.keyword}") .
            ?proj :keyword ?kwCORDIS .
            ?kwCORDIS :textualValue ?keywordCORDIS 
            }
            GROUP BY ?keywordCORDIS
            ORDER BY  DESC(?numProjs)
            limit 20`;

        try {
            const queryResults = await postQuery(query);
            this.setState({
                data: bindingsToJsMap(queryResults)
            });
            
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        const { data } = this.state || {};

        if(data) {
            const fontSizeScale = d3.scaleLinear()
                .domain(d3.extent(data, d => d.numProjs).reverse())
                .range([24, 10]);
            
            return <ul className="cordis-keyword-list">
                {
                    data.map( (d, i) => {
                        return <li key={i} style={{fontSize: fontSizeScale(d.numProjs) + 'px'}}> {d.keywordCORDIS}</li>
                    })
                }
            </ul>
        }
        else
            return <div/>;
    }
}
export default CORDISKeywords;