import React, { Component } from 'react';
import * as _ from 'lodash';
import * as d3 from 'd3';
import { SDG_ARR, SDG_INFO } from '../common'
import { postQuery } from '../common/sparql/PostQuery';
import { bindingsToJsMap } from '../common/sparql/ResultSet';
import Annotation from '../components/Annotation';
import * as vega from 'vega';
import SPARQL from 'vega-transform-sparql';
import Select from 'react-select';
import sdg_by_country_data from '../data/flower/3-SDGs_by_country.json';
import textures from 'textures';
vega.transforms['sparql'] = SPARQL;

class Treemap extends Component {

    constructor(props) {
        super(props);
        this.createChart      = this.createChart.bind(this);
        this.checkSizeForVega = this.checkSizeForVega.bind(this);
        this.onCompareCountry = this.onCompareCountry.bind(this);

        // object to populate the treemap
        this.data = {
            name:'root',
            children: []
        };

        // comparison obj to populate
        this.comparisonData = {
            name:'root',
            children: []
        };
        this.countryCompared = undefined;
        
        // dynamic query to retrieve SDG keyword
        // by SDG and (optionally) by country
        this.queryKeywordsBySDGAndCountry = (sdg='', country= '') => {
            let filterCountry = country === ''? '': 'FILTER (?country = "' + country + '") . ';
            return `PREFIX : <http://unics.cloud/ontology#>
            SELECT
            ?sdgName ?keyword (COUNT(DISTINCT ?projId) AS ?numProjs)
            WHERE {
            ?proj a :EC-Project .
            ?proj :unicsId ?projId .
            
            ?proj :sdgReified ?sdgr .
            ?sdgr :sdg ?sdg .
            ?sdg :shortName ?sdgName .
            ?sdgr :keyword ?kw .
            ?kw :textualValue ?keyword .
            
            ?proj :ecParticipant ?part .
            ?part :country ?cou .
            ?cou :extendedName ?country .
            ${filterCountry} 
            FILTER (?sdgName = "${sdg}")
            }
            GROUP BY ?sdgName ?keyword
            ORDER BY ?sdgName DESC(?numProjs)
            LIMIT 10`;
        };

        // svg textures for comparison mode
        this.textures = {};
        _.each(_.keys(SDG_INFO), key => {
            this.textures[key] = textures.lines()
                .size(8)
                .strokeWidth(1)
                .stroke(d3.rgb(SDG_INFO[key].color).darker(0.5))
                .background(SDG_INFO[key].color)
        });
        this.textureLegend = textures.lines().size(8).strokeWidth(1).stroke('black');
    }



    /**
     * Interval callback to check whether we 
     * have the markup ready to contain the
     * vega charts. If so, stop interval and
     * update state with the available height
     * for the vega wordclouds. Check document
     * object since it can be destroyed if modal
     * window is closed before we get results
     */
    checkSizeForVega() {
        clearInterval(this.interval);
        document !== null && this.createChart(
            document.querySelector('.modal-country').querySelector('.treemap-container').offsetWidth - (20 * 2), //padding of .modal-body
            document.querySelector('.modal-country').querySelector('.treemap-container').offsetHeight- (20 * 2) //padding of .modal-body
        );
    }



    createChart(width, height) {
        const self = this;
        // remove preloader text and exisiting treemap
        d3.select('.sdg-treemap').select('.preloader-text').style('display', 'none');
        d3.select('.sdg-treemap').select('.treemap').style('display', 'block');
        
        if(!_.isUndefined(this.countryCompared)) {
            d3.select('.country-legend').style('display', 'block');
            d3.select('#country-legend-svg').style('display', 'block');
            d3.select('.country-legend-label').html('Striped area corresponds to <strong>' + this.countryCompared + '</strong>');
        }

        d3.select('#treemap-keyword').selectAll("*").remove();

        let root = d3.treemap()
            .tile(d3.treemapSquarify)
            .size([width, height])
            .padding(_.isUndefined(this.countryCompared)? 0:1)
            .round(true)
        (d3.hierarchy(this.data)
            .sum(d => d.value)
            .sort((a, b) => b.value - a.value));
        
        const svg = d3.select('#treemap-keyword');
        _.each(this.textures, t => svg.call(t));
        svg.call(this.textureLegend);
        svg.attr('width', width);
        svg.attr('height', height);
        
        const leaf = svg.selectAll("g")
            .data(root.leaves())
            .join("g")
            .attr("transform", d => `translate(${d.x0},${d.y0})`)
            .on('mouseover', function(d) {           
                var ancestors = _.drop(d.ancestors().reverse(), 1).map(d => d.data.name);
                Annotation.show(
                    d.value + 
                    ' projects ' +
                    ((d.data.valueSecondary)? 
                        '(' + d.data.valuePrimary + ' ' + self.props.country + ', ' + d.data.valueSecondary + ' ' + d.data.countryCompared + ') '
                        : '') +
                    'mentioning "' +
                    _.capitalize(ancestors[1]) + 
                    '", classified as <span style="color:' + SDG_INFO[ancestors[0]].color + '"><strong>' + 
                    SDG_INFO[ancestors[0]].shortTitle + '</strong></span>'
                );
                d3.select(this).select('rect')
                    .style('fill', d3.rgb(SDG_INFO[ancestors[0]].color).darker(0.5));
            })
            .on('mouseout', function(d) {
                var ancestors = _.drop(d.ancestors().reverse(), 1).map(d => d.data.name);
                Annotation.hide()
                d3.select(this).select('rect')
                    .style('fill', SDG_INFO[ancestors[0]].color);
            });

        // clipping area, when comparing it clips to show only
        // the proportion of the main country
        leaf.append('clipPath')
            .attr('id', d => d.ancestors().reverse().map(d => d.data.name).join('/').replace(/ /g, ''))
            .append('rect')
            .attr("width", d => {
                // if we have comparison data, clip 
                // proportionally, othwerise, clip to 
                // 100% (no clip)
                return (d.data.valueSecondary)?
                    (d.x1 - d.x0) * (d.data.valuePrimary / d.data.value) : d.x1 - d.x0;
            })
            .attr("height", d => d.y1 - d.y0);

        // rect representing the 100% (whether is a single
        // country or the total of the two compared countries)
        leaf.append("rect")
            .attr('fill', d => {
                const sdg = _.drop(d.ancestors().reverse(), 1).map(d => d.data.name)[0];
                return this.textures[sdg].url();
            })
            .attr("width", d => d.x1 - d.x0)
            .attr("height", d => d.y1 - d.y0);

        // rect, clipped (when comparing) to the value
        // of the primary country relative to the sum
        // of both countries
        leaf.append("rect")
            .attr('class', 'treemap-cell')
            .attr('clip-path', d => 'url(#' + d.ancestors().reverse().map(d => d.data.name).join('/').replace(/ /g, '') + ')')
            .attr("fill", d => d.data.color)
            .attr("width", d => d.x1 - d.x0)
            .attr("height", d => d.y1 - d.y0);
        
        leaf.append("text")            
            .selectAll("tspan")
            .data(d => d.data.name.split(/(?=[A-Z][a-z])|\s+/g))
            .join("tspan")
            .attr('class', 'treemap-text-label')
            .attr("x", 3)
            .attr("y", (d, i, nodes) => `${(i === nodes.length - 1) * 0.3 + 1 + i * 0.8}em`)
            .attr("fill-opacity", (d, i, nodes) => i === nodes.length - 1 ? 0.7 : null)
            .text(d => d);
        leaf.selectAll('text')
            .attr('transform', function(d) {
                var datum = d3.select(this).datum();
                var bb = d3.select(this).node().getBBox();
                var width = datum.x1 - datum.x0;
                var height = datum.y1 - datum.y0;
                var widthTransform = width*0.9 / bb.width;
                var heightTransform = height*0.75 / bb.height; // be sure we leave v space for the value text
                var value = widthTransform < heightTransform ? widthTransform : heightTransform;
                d.scaleFactor = value;
                return ("transform", "matrix("+value+", 0, 0, "+value+", 0,0)");
            });
        /*
        leaf.append('text')
            .text(d => d.scaleFactor < 0.8 ? '' : d.value)
            .style('dominant-baseline', "baseline")
            //.style('text-anchor', 'middle')
            .attr('class', 'treemap-text-value')
            .attr('x', d => 5)
            .attr('y', d => (d.y1 - d.y0) - 5)
        */
    }


    async getSDGData(sdg, comparisonCountry = undefined) {

        // if we pass a country we are comparing against the country of the modal
        let comparisonMode = !_.isUndefined(comparisonCountry);

        // get for current SDG, all SDG keywords
        let queryResults = await postQuery(this.queryKeywordsBySDGAndCountry(sdg, comparisonCountry || this.props.country));
        queryResults = bindingsToJsMap(queryResults);

        // join and group by keyword
        let bySDG = _.groupBy(queryResults, 'sdgName');

        // prepare the object for the treemap
        _.keys(bySDG).forEach(key => {
            let grouped = bySDG[key];
            var g = {
                name: key,
                children: _.map(
                    grouped, 
                    d => { return {
                        'name': d.keyword, 
                        'value': d.numProjs, 
                        'color': SDG_INFO[d.sdgName].color,
                        'country': _.isUndefined(comparisonCountry)? 'primary':'secondary'
                    }}
                )
            };
            if(comparisonMode)
                this.comparisonData.children.push(g);
            else
                this.data.children.push(g);
        })
    };



    async onCompareCountry(option) {
        
        // update UI
        d3.select('.sdg-treemap').select('.preloader-text').style('display', 'block');
        d3.select('.sdg-treemap').select('.treemap').style('display', 'none');
        d3.select('.country-legend').style('display', 'none');
        d3.select('.country-legend-label').style('display', 'block');
        d3.select('#treemap-keyword').selectAll("*").remove();

        this.countryCompared = option.value;

        // recover original country data and clean comparison data
        this.data = _.cloneDeep(this.safeData);
        this.comparisonData = {
            name:'root',
            children: []
        };

        for(let i = 0; i < SDG_ARR.length; i++) {
            await this.getSDGData(SDG_ARR[i], option.value);
        }

        // loop to main country and add info about the 2nd country
        _.each(this.data.children, sdg => {
            _.each(sdg.children, keyword => {
                // look for the keyword on the 2nd country. If found
                // then sum values
                var o = _.find(this.comparisonData.children, sdg2 => sdg2.name === sdg.name);
                var k = _.find(o.children, keyword2 => keyword2.name === keyword.name);
                if(k) {
                    keyword.valuePrimary    = keyword.value;
                    keyword.valueSecondary  = k.value;
                    keyword.value           = keyword.value + k.value;
                    keyword.countryCompared = this.countryCompared
                }
            });
        });
        this.checkSizeForVega();
    }



    async componentDidMount() {
        for(let i = 0; i < SDG_ARR.length; i++) {
            await this.getSDGData(SDG_ARR[i]);
        }
        // make a safe copy of data
        this.safeData = _.cloneDeep(this.data);
        this.interval = setInterval(this.checkSizeForVega, 300);

        d3.select('#country-legend-svg')
            .attr('fill', this.textureLegend.url());
    }

    render() {
        return <div className="sdg-treemap">
            <div className='preloader-text'>Loading data...</div>
            <div className='treemap'>
                <div class='row'>
                    <div className='col-9 country-legend'>
                        <svg id="country-legend-svg" height='20' width='100'>
                            <rect x='0' y='0' width='80' height='20'></rect>
                        </svg>
                        <span className='country-legend-label'></span>
                    </div>
                    <div className='col-3'>                        
                        <div className='country-selector'>
                            <Select 
                                classNamePrefix={"country-selector"}
                                placeholder="Select country to compare..."
                                options={
                                    _(sdg_by_country_data.map(d => { return {value:d.countryName, label:d.countryName}}))
                                    .uniqBy('label')
                                    .sortBy('label')
                                    .value()
                                }
                                onChange={this.onCompareCountry}
                            />
                        </div>                
                    </div>
                </div>                
                <svg id="treemap-keyword"></svg>
                {/*<ul>
                    { _.range(1,17).map( n => <li><img src={'/images/SDG' + n + '@4x.png'}></img></li>) }
                </ul>*/}
            </div>
        </div>
    }
}
export default Treemap;