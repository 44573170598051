import {createBrowserHistory} from "history"
import * as _ from 'lodash';

export const history = createBrowserHistory();

export const IS_STATIC = true;
export var EMPTY_OBJECT = {
    SDG: undefined,
    numProjectsSDG: 0,
    p_fundingSDG: 0,
    p_numProjectsSDG: 0,
    SI_numProjects: 0,
    SI_funding: 0,
    p_totalNumCORDIS: 0,
    p_totalFundingCORDIS: 0,
    sorting: undefined,
    totalFundingCountry: 0,
    totalProjectsCountry: 0,
    shortTitle: ''
};

/**
 * Given and object with this structure:
 *  Finland: Array(16)
        {SDG: "SDG 8", numProjectsSDG: 297, totalProjectsCountry: 3313, p_numProjectsSDG: 0.08964684575913069, fundingSDG: 146950231.12, …}
        {SDG: "SDG 9", numProjectsSDG: 798, totalProjectsCountry: 3313, p_numProjectsSDG: 0.24086930274675522, fundingSDG: 415058013.64000005, …}
        {SDG: "SDG 10", numProjectsSDG: 100, totalProjectsCountry: 3313, p_numProjectsSDG: 0.03018412315122246, fundingSDG: 37271034.12, …}
        {SDG: "SDG 12", numProjectsSDG: 242, totalProjectsCountry: 3313, p_numProjectsSDG: 0.07304557802595835, fundingSDG: 140090716.81999996, …}
        {SDG: "SDG 1", numProjectsSDG: 36, totalProjectsCountry: 3313, p_numProjectsSDG: 0.010866284334440085, fundingSDG: 14957015.209999999, …}
        ...,
    France: Array(16)
        {SDG: "SDG 8", numProjectsSDG: 797, totalProjectsCountry: 12840, p_numProjectsSDG: 0.062071651090342676, fundingSDG: 509370337.61000013, …}
        {SDG: "SDG 9", numProjectsSDG: 2401, totalProjectsCountry: 12840, p_numProjectsSDG: 0.186993769470405, fundingSDG: 2105978119.34, …}

    Adds in every array properties with the sum of percentage based on prefefined groups of SDGs
    (economy, society, biosphere)
 * @param {Object} data 
 */
export function setAverageBySDGPillars(data) {
    const economy = ['SDG 8', 'SDG 9', 'SDG 10', 'SDG 12'],
          society = ['SDG 1', 'SDG 2', 'SDG 3', 'SDG 4', 'SDG 5', 'SDG 7', 'SDG 11', 'SDG 16'],
          biosphere = ['SDG 6','SDG 13', 'SDG 14', 'SDG 1'];

    // function to sum the % for sdg belonging to the group
    const reducer = function(sdgsLabels, country) {
        return _.reduce(
            sdgsLabels,
            (result, value) => {
                return result + 
                    _.find(
                        data[country], 
                        sdg => sdg.SDG === value
                    ).p_numProjectsSDG
            },
            0
        );
    };

    _.keys(data).forEach(country => {        
        data[country].economy   = reducer(economy, country);
        data[country].society   = reducer(society, country);
        data[country].biosphere = reducer(biosphere, country);
    });
};



export const SDG_INFO = {
    'SDG 1': { 
        'color': "#E5243B", 
        'shortTitle': 'No poverty',
        'title': 'End poverty in all its forms everywhere', 
        'order': 5, 
        'group': 'society' 
    },
    'SDG 2': { 
        'color': "#DDA63A",
        'shortTitle': 'No hunger', 
        'title': 'End hunger, achieve food security and improved nutrition and promote sustainable agriculture', 
        'order': 6, 
        'group': 'society' 
    },
    'SDG 3': { 
        'color': "#4C9F38", 
        'shortTitle': 'Good Health and Well-being',
        'title': 'Ensure healthy lives and promote well-being for all at all ages', 
        'order': 7, 
        'group': 'society' 
    },
    'SDG 4': { 
        'color': "#C5192D", 
        'shortTitle': 'Quality Education',
        'title': 'Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all', 
        'order': 8, 
        'group': 'society' 
    },
    'SDG 5': { 
        'color': "#FF3A21", 
        'shortTitle': 'Gender Equality',
        'title': 'Achieve gender equality and empower all women and girls', 
        'order': 9, 
        'group': 'society' 
    },
    'SDG 6': { 
        'color': "#26BDE2", 
        'shortTitle': 'Clean Water and Sanitation',
        'title': 'Ensure availability and sustainable management of water and sanitation for all', 
        'order': 13, 
        'group': 'biosphere' 
    },
    'SDG 7': { 
        'color': "#FCC30B", 
        'shortTitle': 'Affordable and Clean Energy',
        'title': 'Ensure access to affordable, reliable, sustainable and modern energy for all', 
        'order': 10, 
        'group': 'society' 
    },
    'SDG 8': { 
        'color': "#A21942", 
        'shortTitle': 'Decent Work and Economic Growth',
        'title': 'Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all', 
        'order': 1, 
        'group': 'economy' 
    },
    'SDG 9': { 
        'color': "#FD6925", 
        'shortTitle': 'Industry, Innovation and Infrastructure',
        'title': 'Build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation', 
        'order': 2, 
        'group': 'economy' 
    },
    'SDG 10': { 
        'color': "#DD1367", 
        'shortTitle': 'Reduced Inequality',
        'title': 'Reduce inequality within and among countries', 
        'order': 3, 
        'group': 'economy' 
    },
    'SDG 11': { 
        'color': "#FD9D24", 
        'shortTitle': 'Sustainable Cities and Communities',
        'title': 'Make cities and human settlements inclusive, safe, resilient and sustainable', 
        'order': 11, 
        'group': 'society' 
    },
    'SDG 12': {
        'color': "#BF8B2E", 
        'shortTitle': 'Responsible Consumption and Production',
        'title': 'Ensure sustainable consumption and production patterns', 
        'order': 4, 
        'group': 'economy' 
    },
    'SDG 13': { 
        'color': "#3F7E44", 
        'shortTitle': 'Climate Action',
        'title': 'Take urgent action to combat climate change and its impacts*', 
        'order': 14, 
        'group': 'biosphere' 
    },
    'SDG 14': { 
        'color': "#0A97D9", 
        'shortTitle': 'Life Below Water',
        'title': 'Conserve and sustainably use the oceans, seas and marine resources for sustainable development', 
        'order': 15, 
        'group': 'biosphere' 
    },
    'SDG 15': { 
        'color': "#56C02B", 
        'shortTitle': 'Life on Land',
        'title': 'Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and halt biodiversity loss', 
        'order': 16, 
        'group': 'biosphere' 
    },
    'SDG 16': { 
        'color': "#00689D", 
        'shortTitle': 'Peace and Justice Strong Institutions',
        'title': 'Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable and inclusive institutions at all levels', 
        'order': 12, 
        'group': 'society' 
    }
}

export const SDG_ARR = ["SDG 1", "SDG 2", "SDG 3", "SDG 4", "SDG 5", "SDG 6", "SDG 7", "SDG 8", "SDG 9", "SDG 10", "SDG 11", "SDG 12", "SDG 13", "SDG 14", "SDG 15", "SDG 16"];
export const SDG_COLOR_ARR = ["#E5243B", "#DDA63A", "#4C9F38", "#C5192D", "#FF3A21", "#26BDE2", "#FCC30B", "#A21942", "#FD6925", "#DD1367", "#FD9D24", "#BF8B2E", "#3F7E44", "#0A97D9", "#56C02B", "#00689D"];

export const SDG_GROUPING = [
    {name: 'ECONOMY', includes: ['SDG 8', 'SDG 9', 'SDG 10', 'SDG 12'], color: '#CCC'},
    {name: 'SOCIETY', includes: ['SDG 1', 'SDG 2', 'SDG 3', 'SDG 4', 'SDG 5', 'SDG 7', 'SDG 11', 'SDG 16'], color: '#AAA'},
    {name: 'BIOSPHERE', includes: ['SDG 6', 'SDG 13', 'SDG 14', 'SDG 15'], color: '#777'}
]

export const SELECTED_COUNTRIES = [
    {countryName: 'Germany'},
    {countryName: 'France'},
    {countryName: 'Italy'},
    {countryName: 'Spain'},
    {countryName: 'Barbados'}
]
export const REFS = {
    PRIMARY_COUNTRY     : 'primary-country',
    SECONDARY_COUNTRY_0 : 'secondary-country-0',
    SECONDARY_COUNTRY_1 : 'secondary-country-1',
    SECONDARY_COUNTRY_2 : 'secondary-country-2',
    SECONDARY_COUNTRY_3 : 'secondary-country-3',
    SECONDARY_HEADER    : 'secondary-header'
};

export const SPARQL_ENDPOINT = 'http://ec2-52-51-177-228.eu-west-1.compute.amazonaws.com/unics/sparql/query'

export const queries = {
    sdgKeys: `PREFIX : <http://unics.cloud/ontology#>
    SELECT
    ?sdgName ?keyword (COUNT(DISTINCT ?projId) AS ?numProjs)
    WHERE {
    ?proj a :EC-Project .
    ?proj :unicsId ?projId .
    
    ?proj :sdgReified ?sdgr .
    ?sdgr :sdg ?sdg .
    ?sdg :shortName ?sdgName .
    ?sdgr :keyword ?kw .
    ?kw :textualValue ?keyword .
    
    ?proj :ecParticipant ?part .
    ?part :country ?cou .
    ?cou :extendedName ?country .
    FILTER (?country = "Spain")
    }
    GROUP BY ?sdgName ?keyword
    ORDER BY ?sdgName DESC(?numProjs)`
}

export const CONTINENT_COLORS = {
    'Africa': '#01963e',
    'Asia': '#ffcc00',
    'Europe': '#026bb6',
    'North America': '#e42e13',
    'Oceania': '#8f644a', //'#f07d00',
    'South America': '#b82f7e'
};