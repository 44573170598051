import * as d3 from 'd3';

const myAnnotation = function(){

    function show (text) {

      //positionate tooltip
      var tooltip = d3.select('#node-annotation')
        .style('display', 'block')
        .style('left', (d3.event? d3.event.pageX:0) + 'px');
        //.style('top', (d3.event.pageY - 50) + 'px');

      //update text
      tooltip.select('.node-annotation-text').html(text);
      tooltip.style('top', (d3.event? d3.event.pageY:0) + 'px');
    }

    function hide() {
      d3.select('#node-annotation').style('display', 'none');
    };

    function move(coords) {
        var tooltip = d3.select('#node-annotation');

        tooltip.style('display', 'block')
            .style('left', (coords[0] + 15) + 'px')
            .style('top', (coords[1] - 50) + 'px');

        // if tooltip is placed out of the boundaries of the screen,
        // automatically placed it inside
        var tooltipWidth = tooltip.node().getBoundingClientRect().width,
            tooltipX = tooltip.node().getBoundingClientRect().left,
            windowWidth = window.innerWidth;
        if ((tooltipX + tooltipWidth) > windowWidth)
            tooltip.style('left', (tooltipX - tooltipWidth - 5) + 'px');        
    }
    


    return {
        show: show,
        hide: hide,
        move: move
    };
}
const Annotation = myAnnotation();
export default Annotation;