import React, { Component } from 'react';
import * as _ from 'lodash';
import { sdgKeywordTrend } from '../common/vega/VegaSpecifications';
import VegaChart from '../common/vega/VegaChart';
import CORDISKeywords from './CORDISKeywords';
import {SDG_INFO} from '../common/index';

class KeywordData extends Component {


    constructor(props) {
        super(props)
        this.checkSizeForVega = this.checkSizeForVega.bind(this);
    }



    /**
     * Interval callback to check whether we 
     * have the markup ready to contain the
     * vega charts. If so, stop interval and
     * update state with the available height
     * for the vega wordclouds
     */
    checkSizeForVega() {
        if(document.querySelector('.col-sdg') !== null) {
            clearInterval(this.interval);
            this.setState({
                // space to allocate the Vega chart
                columWidth: 0.9 * document.querySelector('.col-sdg').offsetWidth
            });
        }
    }



    componentDidMount() {
        let dynamicSpecs = [];

        this.props.sdgLabels.forEach( sdgLabel => {
            let spec = _.cloneDeep(sdgKeywordTrend);
            spec.data[1].transform[0].query = `PREFIX : <http://unics.cloud/ontology#>
                SELECT
                ?year ?sdgName ?keyword (COUNT(DISTINCT ?projId) AS ?numProjs)
                WHERE {
                    ?proj a :EC-Project .
                    ?proj :unicsId ?projId .
                    ?proj :startingYear ?year .
                    
                    ?proj :sdgReified ?sdgr .
                    ?sdgr :sdg ?sdg .
                    ?sdg :shortName ?sdgName .
                    FILTER(?sdgName = "${sdgLabel}")
                    ?sdgr :keyword ?kw .
                    ?kw :textualValue ?keyword .
                    FILTER(?keyword = "${this.props.keyword}")
                }
                GROUP BY ?year ?sdgName ?keyword
                ORDER BY ?year ?sdgName DESC(?numProjs)`;
            dynamicSpecs.push(spec);
        });
        
        // set a timeout to give time to generate
        // the markup so we can obtain a height
        // for the vega chart (the vega chart
        // needs the height in pixels)
        this.interval = setInterval(this.checkSizeForVega, 300);

        this.setState({ specs: dynamicSpecs });
    }



    render() {
        const {
            specs,
            columWidth
        } = this.state || {};
        
        let colWidth;
        // decide column width
        if(this.props.sdgLabels)
            colWidth = (this.props.sdgLabels.length === 1)? 12 : 
                (this.props.sdgLabels.length === 2)? 6 :
                (this.props.sdgLabels.length === 3)? 4 :
                (this.props.sdgLabels.length === 4)? 3 : 2;

        return <div className="container-fluid">
            {
                specs && <div className="row justify-content-center">
                    {
                        this.props.sdgLabels.map((sdgLabel, i) => {
                            return <div key={i} className={"col-sdg col-12 col-md-" + colWidth}>
                                <div className='img-header'>
                                    <img alt='' src={'images/' + _.replace(sdgLabel, ' ', '') + '.png'}/>
                                </div>
                                <p>Trend of research projects mentioning <span><strong>{this.props.keyword}</strong></span>:</p>
                                { columWidth && 
                                    <VegaChart
                                        id={"keyword-evolution"}
                                        spec={specs[i]}
                                        padding={{top: 0, left: 0, right: 10, bottom: 0}}
                                        autoSize={'fit'}
                                        height={100}
                                        width={columWidth}/>
                                }                                
                                <p>Find below the most frequent keywords for all projects related to <span><strong style={{color:SDG_INFO[sdgLabel].color}}>{SDG_INFO[sdgLabel].shortTitle}</strong></span>:</p>
                                <CORDISKeywords 
                                    sdgLabel={sdgLabel}
                                    keyword={this.props.keyword}/>
                            </div>
                        })
                    }
                    </div>
            }                
        </div>
    }
}
export default KeywordData;