export const sdgKeywordTrend ={
    "$schema": "https://vega.github.io/schema/vega/v5.json",
    "width": 400,
    "height": 100,
    "data": [
      {
        "name": "sdg_info",
        "values": [
          {
            "label": "SDG 1",
            "color": "#E5243B",
            "shortTitle": "No poverty",
            "title": "End poverty in all its forms everywhere"
          },
          {
            "label": "SDG 2",
            "color": "#DDA63A",
            "shortTitle": "No hunger",
            "title": "End hunger, achieve food security and improved nutrition and promote sustainable agriculture"
          },
          {
            "label": "SDG 3",
            "color": "#4C9F38",
            "shortTitle": "Good Health and Well-being",
            "title": "Ensure healthy lives and promote well-being for all at all ages"
          },
          {
            "label": "SDG 4",
            "color": "#C5192D",
            "shortTitle": "Quality Education",
            "title": "Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all"
          },
          {
            "label": "SDG 5",
            "color": "#FF3A21",
            "shortTitle": "Gender Equality",
            "title": "Achieve gender equality and empower all women and girls"
          },
          {
            "label": "SDG 6",
            "color": "#26BDE2",
            "shortTitle": "Clean Water and Sanitation",
            "title": "Ensure availability and sustainable management of water and sanitation for all"
          },
          {
            "label": "SDG 7",
            "color": "#FCC30B",
            "shortTitle": "Affordable and Clean Energy",
            "title": "Ensure access to affordable, reliable, sustainable and modern energy for all"
          },
          {
            "label": "SDG 8",
            "color": "#A21942",
            "shortTitle": "Decent Work and Economic Growth",
            "title": "Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all"
          },
          {
            "label": "SDG 9",
            "color": "#FD6925",
            "shortTitle": "Industry, Innovation and Infrastructure",
            "title": "Build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation"
          },
          {
            "label": "SDG 10",
            "color": "#DD1367",
            "shortTitle": "Reduced Inequality",
            "title": "Reduce inequality within and among countries"
          },
          {
            "label": "SDG 11",
            "color": "#FD9D24",
            "shortTitle": "Sustainable Cities and Communities",
            "title": "Make cities and human settlements inclusive, safe, resilient and sustainable"
          },
          {
            "label": "SDG 12",
            "color": "#BF8B2E",
            "shortTitle": "Responsible Consumption and Production",
            "title": "Ensure sustainable consumption and production patterns"
          },
          {
            "label": "SDG 13",
            "color": "#3F7E44",
            "shortTitle": "Climate Action",
            "title": "Take urgent action to combat climate change and its impacts"
          },
          {
            "label": "SDG 14",
            "color": "#0A97D9",
            "shortTitle": "Life Below Water",
            "title": "Conserve and sustainably use the oceans, seas and marine resources for sustainable development"
          },
          {
            "label": "SDG 15",
            "color": "#56C02B",
            "shortTitle": "Life on Land",
            "title": ""
          },
          {
            "label": "SDG 16",
            "color": "#00689D",
            "shortTitle": "Peace and Justice Strong Institutions",
            "title": "Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable and inclusive institutions at all levels"
          }
        ]
      },
      {
        "name": "table",
        "values": null,
        "transform": [
          {
            "type": "sparql",
            "endpoint": "http://ec2-52-51-177-228.eu-west-1.compute.amazonaws.com/unics/sparql/query",
            "query": null
          },
          {
            "type": "filter", 
            "expr": "datum.year > 2007 && datum.year < 2020"
          },
          {
            "type": "impute",
            "groupby": ["keyword", "sdgName"],
            "key": "year",
            "keyvals": [
              2008,
              2009,
              2010,
              2011,
              2012,
              2013,
              2014,
              2015,
              2016,
              2017,
              2018,
              2019
            ],
            "field": "numProjs",
            "method": "value",
            "value": 0
          },
          {
            "type": "lookup",
            "from": "sdg_info",
            "key": "label",
            "values": ["shortTitle", "color"],
            "fields": ["sdgName"]
          },
          {
            "type": "collect",
            "sort": {"field": "year"}
          },
          {
            "type": "formula",
            "expr": "datum.shortTitle + ' - ' + datum.sdgName",
            "as": "shortTitle"
          },
          {"type": "extent", "field": "numProjs", "signal": "extent"}
        ]
      }
    ],
    "scales": [
       {
        "name": "xscale",
        "type": "point",
        "domain": {"data": "table", "field": "year"},
        "range": [0, {"signal": "width"}],
        "padding": 0.05,
        "round": true
      },
      {
        "name": "yscale",
        "type": "linear",
        "domain": {"signal": "extent"},
        "range": [{"signal": "height"}, 0]
      }
    ],
    "axes": [
          {
            "orient": "left",
            "scale": "yscale",
            "ticks": false,
            "domain": false,
            "tickCount": 2,
            "grid": true,
            "gridDash": [2,2],
            "labelPadding": 5,
            "encode": {
              "labels": {
                "update": {
                  "opacity": {
                    "signal": "datum.value === 0? 0:1"
                  }
                }
              }
            }    
          },
          {
            "orient": "bottom",
            "scale": "xscale",
            "domain": true,
            "ticks": true,
            "encode": {
              "ticks": {
                "update": {
                  "opacity": {
                    "signal": "datum.value === 2008 || datum.value === 2019 ? 1:0"
                  }
                }
              },
              "labels": {
                "update": {
                  "text": {
                    "signal": "datum.value === 2008 || datum.value === 2019 ? datum.value : ''"
                  },
                  "align": {
                    "signal": "datum.value === 2019? 'right':'left'"
                  },
                  "dx": {
                    "signal": "(datum.value === 2018)? -5:(datum.value === 2019)? 5:0"
                  }
                }
              }
            }
          }
        ],
      "marks": [
        {
            "type": "line",
            "from": {"data": "table"},
            "encode": {
              "enter": {
                "x": {"scale": "xscale", "field": "year"},
                "y": {"scale": "yscale", "field": "numProjs"},
                "strokeWidth": {"value": 4},
                "stroke": {"field": "color"}
              },
              "update": {
                "interpolate": {
                  "value": "basis"
                },
                "stroke": {
                  "field": "color"
                }
              }
            }
          }
    ]
  };