import React, { Component } from 'react';
import paper from 'paper';
import * as d3 from 'd3';
import * as _ from 'lodash';
import CanvasSDG from './canvasSDG';
import { fisheye } from '../../common/fisheye';
import { getMarginTopFromVegaSVG } from '../../common/vega/VegaUtils';
import Annotation from '../../components/Annotation';


class WordleGenerator extends Component {

    constructor(props) {
        super(props);
        this.state = {
            numWordCloudCompleted: 0,
            textPaths: []
        }
        this.fisheye = fisheye.circular().radius(50).distortion(5);
        this.wordCloudCompletionListener = this.wordCloudCompletionListener.bind(this);
        this.showAnnotation = this.showAnnotation.bind(this);
    }


    // show annotation only if we detect
    // there is a hovered word
    showAnnotation(coords) {

        const hoveredText = d3.select('.stream-river svg')
            .selectAll('.curve-text')
            .filter(d => d ? d.selected : false)
            .selectAll('textPath')
            .nodes()
            .filter(function (el) {
                return 1 === +getComputedStyle(el).getPropertyValue('--my-hovered-state');
            })

        if (hoveredText.length > 0) {
            clearInterval(this.interval);
            Annotation.show("Click for details about <span class='dottedUnderline'><strong>" + hoveredText[0].textContent + '</strong></span>');
            Annotation.move(coords);
        }
    }



    findUpperPath(result, intersections) {
        let initialIndex, finalIndex, mySegments, segment, len;

        // get min and max x
        var min = Math.min(...intersections.map(function (i) { return i.point.x; }))
        var max = Math.max(...intersections.map(function (i) { return i.point.x; }))

        // get left and right points
        var leftPoints = intersections.filter(function (i) { return i.point.x === min; })
        var rightPoints = intersections.filter(function (i) { return i.point.x === max; })

        // re-group left and right points into top and bottom points
        var topPoints = [
            leftPoints.reduce(function (acumulator, currentValue) {
                return currentValue.point.y <= acumulator.point.y ? currentValue : acumulator;
            }, leftPoints[0]),
            rightPoints.reduce(function (acumulator, currentValue) {
                return currentValue.point.y <= acumulator.point.y ? currentValue : acumulator;
            }, rightPoints[0]),
        ];

        var bottomPoints = [
            leftPoints.reduce(function (acumulator, currentValue) {
                return currentValue.point.y > acumulator.point.y ? currentValue : acumulator;
            }, leftPoints[0]),
            rightPoints.reduce(function (acumulator, currentValue) {
                return currentValue.point.y > acumulator.point.y ? currentValue : acumulator;
            }, rightPoints[0]),
        ];

        // the first segment in the array of path segments can be any: the segments are sorted
        // in a circular manner around the path, but the first segment is not the one with the
        // most left-top point, per example. Order in segments is on its 'index' property, but
        // its read-only. So we have to create the segments in the right order to make operations
        // like cutting the path without headaches
        len = result.segments.length;

        // finding the top path, containing the upper intersection points:

        // find the index of the segment containing our left-top point
        initialIndex = result.segments.findIndex(function (o) {
            //return Math.round(o.point.x) === Math.round(topPoints[0].point.x) && 
            //        Math.round(o.point.y) === Math.round(topPoints[0].point.y);     
            return o.point.getDistance(topPoints[0].point) < 1;
        });
        mySegments = [];
        for (let i = 0; i < result.segments.length; i++) {
            segment = result.segments[(initialIndex + i) % len];
            mySegments.push(new paper.Segment(
                segment.point, segment.handleIn, segment.handleOut
            ));
        }
        var result2 = new paper.Path(mySegments);

        finalIndex = result2.segments.findIndex(function (o) {
            //return Math.round(o.point.x) === Math.round(topPoints[1].point.x) && 
            //        Math.round(o.point.y) === Math.round(topPoints[1].point.y);     
            return o.point.getDistance(topPoints[1].point) < 1;
        });
        result2.removeSegments(finalIndex + 1)



        // finding the path containing the lower intersection points:
        // find index of segment containing our right-bottom point
        initialIndex = result.segments.findIndex(function (o) {
            //return Math.round(o.point.x) === Math.round(bottomPoints[1].point.x) && 
            //        Math.round(o.point.y) === Math.round(bottomPoints[1].point.y);     
            return o.point.getDistance(bottomPoints[1].point) < 1;
        });
        len = result.segments.length;
        mySegments = [];
        for (let i = 0; i < result.segments.length; i++) {
            segment = result.segments[(initialIndex + i) % len];
            mySegments.push(new paper.Segment(
                segment.point, segment.handleIn, segment.handleOut
            ));
        }

        var result3 = new paper.Path(mySegments);

        finalIndex = result3.segments.findIndex(function (o) {
            //return Math.round(o.point.x) === Math.round(bottomPoints[0].point.x) && 
            //        Math.round(o.point.y) === Math.round(bottomPoints[0].point.y);     
            return o.point.getDistance(bottomPoints[0].point) < 1;
        });
        result3.removeSegments(finalIndex + 1)

        // in order to interpolate the two curves,
        // orientation of the path's points has to
        // be the same (both path with its points
        // going from left to right)
        result3.reverse();
        return [result2, result3];
    };


    wordCloudCompletionListener(e) {
        // there is no exact number of wordlcloud that will be resolved,
        // since depending on the dimensions (mobile, desktop, etc) the
        // numbers of allocated wordlclouds will vary
        // Put here a number which is close to the all possible wordclouds
        // but high enough to consider that we are close to have them
        // all resolved
        const MAX_WORDLCLOUD = 170;

        this.setState({
            textPaths: this.state.textPaths.concat(e.detail),
            numWordCloudCompleted: this.state.numWordCloudCompleted + 1
        })

        let percentage = Math.ceil((this.state.numWordCloudCompleted / MAX_WORDLCLOUD) * 100);

        document.querySelector('#global-preloader')
            .innerHTML = 'Processing data... ' +
            percentage + '%';
 
        const quadTreeRadius = 30,
            svg = document.querySelector('.stream-river svg');

        console.log(this.state.numWordCloudCompleted)
        if(percentage > 95) {
            document.querySelector('body').style['overflow-y'] = 'initial';
            document.querySelector('body').classList.remove('loading');
            document.querySelector('.stream-river').style.opacity = 1;
        }
        
        // without 2017 and 2020, MAX_WORDLCLOUD wordclouds
        if (this.state.numWordCloudCompleted > MAX_WORDLCLOUD) {

            let self = this;

            const curveText = d3.selectAll('.curve-text')
                .filter(d => !_.isUndefined(d))
                .on('click', function (d) {
                    // hide any current annotation
                    Annotation.hide();
                    if (self.interval)
                        clearInterval(self.interval);
                    // show modal
                    self.props.handleOpenModalKeyword(d.word);
                });

            // get only with elements visible on the screen:
            // https://stackoverflow.com/questions/123999/how-can-i-tell-if-a-dom-element-is-visible-in-the-current-viewport
            //.filter( function() { return isElementInViewport(this); });

            // !important: vega specification has a top margin. Take into account
            // this offset when getting the positions of the words
            const marginTop = getMarginTopFromVegaSVG(d3.select('.stream-river svg.marks'));

            const quadTree = d3.quadtree()
                .extent(
                    [-1, -1],
                    [+d3.select('.stream-river svg').attr("width") + 1, +d3.select('.stream-river svg').attr("height") + 1])
                .addAll(
                    curveText.data()
                );

            var buffer = {};
            const remap = function (value, istart, istop, ostart, ostop) {
                return ostart + (ostop - ostart) * ((value - istart) / (istop - istart));
            };

            // Find the nodes within the specified rectangle.
            const search = function (quadtree, x0, y0, x3, y3) {
                quadtree.visit(function (node, x1, y1, x2, y2) {
                    if (!node.length) {
                        do {
                            var d = node.data;
                            d.selected = (d[0] >= x0) && (d[0] < x3) && (d[1] >= y0) && (d[1] < y3);
                        } 
                        // eslint-disable-next-line
                        while (node = node.next);
                    }
                    return x1 >= x3 || y1 >= y3 || x2 < x0 || y2 < y0;
                });
            };

            const pt = svg.createSVGPoint();
            //https://stackoverflow.com/questions/10298658/mouse-position-inside-autoscaled-svg
            const cursorPoint = function (x, y) {
                pt.x = x;
                pt.y = y;
                return pt.matrixTransform(svg.getScreenCTM().inverse());
            }

            const searchAndFish = function (e) {

                // hide any current annotation
                Annotation.hide();
                if (self.interval)
                    clearInterval(self.interval);

                // check for a 'mouse stop' to show the annotation
                let pageCoords = [d3.event.pageX, d3.event.pageY],
                    clientCoords = [d3.event.clientX, d3.event.clientY]
                self.interval = setInterval(() => self.showAnnotation(pageCoords), 1000);

                requestAnimationFrame(function () {
                    // since throttling a mousemove event from d3 is 
                    // hard, do stuff to get mouse coords natively
                    const p = cursorPoint(clientCoords[0], clientCoords[1])
                    const mouse = [p.x, p.y - marginTop];

                    // keep the fisheye updated to the mouse
                    self.fisheye.focus(mouse);

                    // search all texts close to the mouse position
                    curveText
                        .each(function (d) { d.selected = false; });

                    search(
                        quadTree,
                        mouse[0] - quadTreeRadius,
                        mouse[1] - quadTreeRadius,
                        mouse[0] + quadTreeRadius,
                        mouse[1] + quadTreeRadius
                    );
                    curveText
                        .filter(d => d ? d.selected : false)
                        .each(function (d) {
                            d.fisheye = self.fisheye({ x: d[0], y: d[1] })
                        });

                    //curveText.classed('selected', function(d) { return d.selected; });

                    //
                    if (!_.isEmpty(buffer)) {
                        // get words selected in the previous search
                        // and get those not present in the new 
                        // quadtree selection
                        var oldWords = _.difference(
                            _.keys(buffer),
                            curveText
                                .filter(d => d.selected)
                                .data()
                                .map(d => d.word)
                        );
                        oldWords.forEach(w => {
                            // check whether is a textPath or a text
                            let selection = buffer[w].select('textPath');
                            (selection.size() > 0 ? selection : buffer[w] )
                                .attr('opacity', d => d.myOpacity)
                                .style('font-size', d => d.myFontSize);
                            /*buffer[w]
                                .select('textPath')
                                .attr('opacity', d => d.myOpacity)
                                .style('font-size', d => d.myFontSize);*/
                        });
                    }
                    // create new buffer with the current search
                    buffer = {}
                    curveText
                        .filter(function (d) { return d && d.selected; })
                        .each(function (d) {
                            buffer[d.word] = d3.select(this); // MAYBE THIS IS SLOW?
                        });

                    curveText
                        .filter(d => d ? d.selected : false)
                        .each( function(d, i) {
                            let selection = d3.select(this).select('textPath');
                            (selection.size() > 0 ? selection : d3.select(this) )
                                .attr('opacity', function (d) {
                                    // typical z values range between 1 and 4 aprox... 
                                    return remap(d.fisheye.z, 1, 3, d.myOpacity, 1);
                                })
                                .style('font-size', function (d) {
                                    const myFontSize = d.myFontSize;
                                    // max font size for the wordcloud this word belongs to
                                    const maxFontSize = d.myMaxFontSize;
                                    // don't let bigger font size than the maximum, otherwise the text
                                    // overflows the path and appears incomplete (maxFontSize is esimated)
                                    // by looking for the maximum font size with the available width canvas
                                    // of the wordcloud
                                    const fontSize = myFontSize * d.fisheye.z;
                                    //return fontSize;
                                    return (fontSize < maxFontSize ? fontSize : maxFontSize) + 'px';
                                });
                        });                        
                });
            };

            // throttle our mousemove function to avoid drops
            // in the performance. Do not rely on d3.on('mousemove',...)
            // when throttling since d3 resets info about the d3.event
            // and it's hard to mantain the context (d3.mouse and this)
            //svg.addEventListener('mousemove', _.throttle(searchAndFish, 100));
            //svg.addEventListener('mousemove', searchAndFish);
            d3.select('.stream-river svg').on('mousemove', searchAndFish);
            //svg.addEventListener('click', findNode);       
        }
    }

    generateCanvasLayout() {
        const {
            data,
            canvasLayout,
            scaleX,
            keywords
        } = this.props;

        // when creating the wordclouds, often there is empty space
        // between them. While this is correct (the wordcloud algorithm
        // starts from the center of its available space ot the outside)
        // it would be nice to reduce this blank space: to achieve this
        // we "expand" the width of the intersection area, so the wordcloud
        // will have more space to place the words. This will create some
        // overlapping, specially with the word with more weight (the 
        // wordcloud alg. places it using all the available width to determine
        // the max font size), so we pass this factor to the wordcloud in
        // order to by inverse applied when placing the most weighted word.
        const offsetWidthFactor = 1;

        // generated collection of wordclouds
        const wordClouds = //_.filter(data, d => (d.year < 2020))
            //_.filter(data, d => (d.SDG === 'SDG 13' || d.SDG === 'SDG 7' || d.SDG === 'SDG 11' || d.SDG === 'SDG 2' )
            _.filter(data, d => /*d.SDG === 'SDG 11' && */d.year < 2020)
                .map((o, index) => {

                    // get the keyword counts for each <SDG,year> 
                    let canvasData = _.filter(
                        keywords,
                        o2 => o2.SDG === o.SDG && o2.year === o.year
                    );
                    // estimate position and size for the canvas
                    // paper.js stuff
                    var pathSDG = new paper.Path(canvasLayout[o.SDG].path);
                    var square = new paper.Path.Rectangle(
                        [scaleX(o.year), 0], [scaleX.step(), window.innerHeight]
                    );
                    if (o.year !== 2007 && o.year !== 2019) {
                        square.scale(offsetWidthFactor, 1)
                    };

                    var pathSDGYear = square.intersect(pathSDG);
                    if (pathSDGYear.children) {
                        // we got a compound path.. ¿?
                        // just get the first children
                        pathSDGYear = pathSDGYear.children[0];
                    }
                    let intersections = square.getIntersections(pathSDG);
                    let offsetX = d3.min(intersections, i => i.point.x);
                    let offsetY = d3.min(intersections, i => i.point.y);
                    try {
                        var curves = this.findUpperPath(pathSDGYear, intersections);
                    } catch (e) {
                        // error likely because we got a CompoundPath in pathSDGYear
                        console.warn("no curves have been found in " + o.SDG + " " + o.year);
                    }

                    // we are going to place the canvas on its position relative
                    // to window 0,0, so translate the path to 0,0 on its canvas
                    pathSDGYear.translate(new paper.Point(-offsetX, -offsetY));

                    // TODO: check ending years for 4 intersections
                    var vector, vector1, vector2;
                    if (intersections && intersections.length === 4) {
                        vector1 = new paper.Point(
                            intersections[3].point.x - intersections[0].point.x,
                            intersections[3].point.y - intersections[0].point.y
                        );
                        vector2 = new paper.Point(
                            intersections[2].point.x - intersections[1].point.x,
                            intersections[2].point.y - intersections[1].point.y
                        );
                        vector = Math.abs(vector1.angle) > Math.abs(vector2.angle) ? vector1 : vector2;
                    }

                    return <div id={o.SDG + '-' + o.year} key={index} style={{ position: 'absolute', left: '0px', top: '0px' }} className='canvas-sdg'>
                        <CanvasSDG id={o.SDG + '-' + o.year}
                            wordCloudCompletionListener={this.wordCloudCompletionListener}
                            path={pathSDGYear.exportSVG().getAttribute('d')}
                            curves={curves}
                            width={pathSDGYear.bounds.width}
                            height={pathSDGYear.bounds.height}
                            x={offsetX}
                            y={offsetY}
                            sdg={o.SDG}
                            year={o.year}
                            offsetWidthFactor={offsetWidthFactor}
                            angle={vector ? vector.angle * (Math.PI / 180) * -1 : 0}
                            color={canvasLayout[o.SDG].color}
                            data={canvasData
                                .map(o => [o.keyword, o.count])
                                .sort((a, b) => b[1] - a[1])
                            }
                        />
                    </div>;
                });

        this.setState({
            amountWordClouds: wordClouds.length
        });

        return <div>{wordClouds}</div>
    };

    shouldComponentUpdate() {
        // we just want to render once
        return false;
    }

    render() {
        return this.generateCanvasLayout();
    }
}

export default WordleGenerator;