import React, { PureComponent } from 'react';
import * as vega from 'vega';
import * as _ from 'lodash';
import { VEGA_CONFIG } from '../constants';
var vegaTooltip = require('vega-tooltip/build/vega-tooltip');



class VegaChart extends PureComponent {

    componentDidMount() {
        this.renderVega();
    }

    componentDidUpdate() {
        //this.renderVega();
    }

    renderVega() {
        const {
            spec,
            data,
            autoSize,
            width,
            height,
            padding,
            dataDefs,
            signals,
            signalListeners,
            runAfterCallback,
            onVegaViewCreated } = this.props;

        // inject some dynamic settings to
        // the vega specification
        data && (spec.data[0].values = data);

        // concat data definitions safely, by checking
        // first if they already exist.
        // If the data definition is not in the spec,
        // just insert it. Otherwise if the data def
        // already exists just override the values, so
        // we mantain possible other attributes 
        // (transforms, etc...)
        if(!_.isNil(dataDefs))
            dataDefs.forEach( dataDef => {
                
                let dd = _.find(spec.data, d => d.name === dataDef.name);

                if( _.isUndefined(dd) )
                    spec.data.push(dataDef);
                else
                    dd.values = dataDef.values;
            });

        //spec.title && (spec.title.text = this.props.title);

        // merging props and spec signals
        if(signals) 
            spec.signals.map(s => s.value = (signals.find(x => x.name === s.name) || s).value)        

        // vega specs have its 'autosize' property to 'fit' (automatically adjust
        // the layout in an attempt to force the total visualization size to fit
        // within the given width, height and padding values).
        // When we have a restricted with, this is the property more suitable
        spec.padding = padding || {
            top: 20,
            left: 15,
            right: 15,
            bottom: 20
        };
        spec.width      = width || this.node.getBoundingClientRect().width - spec.padding.left - spec.padding.right;
        spec.autosize   = autoSize || 'fit';
        spec.height     = height || 450;
                    
        /*console.log("Vega spec: ");
        console.log(
            JSON.stringify(spec)
        );*/

        // create the Vega view
        let view = new vega.View(vega.parse(spec, VEGA_CONFIG || {}))
            .renderer('svg')
             .tooltip(
                (new vegaTooltip.Handler({theme: 'dark'})).call
            )
            .initialize(this.node);

        // Search for SPARQL data transform on any of the datasets.
        // If found, then add a DataListener
        /*spec.data.forEach( dataDef => {
            dataDef.transform.forEach( dataTransform => {
                if(dataTransform.type === 'sparql') {
                    view.addDataListener(
                        dataDef.name,
                        (name, value) => {
                            console.log("* * * * * ", value.length);
                        }
                    )
                }
            });
        });*/
        view.runAsync()
            .then( () => {
                // This code need to be run AFTER the view has rendered:
                // signal listeners will be invoked when the signal value
                // changes during pulse propagation (e.g., after runAsync
                // is called, but before its returned Promise resolves).
                // https://vega.github.io/vega/docs/api/view/#view_runAsync
                signalListeners && signalListeners.forEach(listener => {
                    view.addSignalListener(
                        listener.name,
                        (name, value) => { 
                            listener.handler(name, value, view)
                        }
                        );
                });
                // check if there is any callback to be invoked after
                // the current dataflow evaluation completes
                runAfterCallback && runAfterCallback(view);
            });

        // pass the created view
        onVegaViewCreated && onVegaViewCreated(view);
    }

    refCallback = node => {
        this.node = node;
    }

    render() {
        return (
            <div ref={this.refCallback} className={this.props.chartClass}></div>
        )
    }
}

export default VegaChart;
