import React, { Component } from 'react';
import sirisWhite from '../images/siris_logo_menu1.png'

class Header extends Component {

    getHeaderContent() {
        return <div className="row">
                <div className="col-12 col-md-7">
                    <h6 className="global-preloader text-white">
                        <img src='/images/preloader.gif' style={{maxHeight:'20px', opacity:'0.8', paddingRight:'5px'}}></img><span id="global-preloader">Processing data...</span>
                    </h6>         
                    <h1 className="header_title"><span className="">Is EU-funded research and innovation evolving towards topics related to the Sustainable Development Goals?</span></h1>
                    <div className="sub-header mb-4">
                        <p className="d-inline-block mr-2">A visual essay made by <span className="d-inline-block d-md-none">SIRIS Academic</span></p>
                        <a href="http://www.sirisacademic.com" target='_blank' rel="noopener noreferrer"><img className="d-none d-md-inline-block" src={sirisWhite} alt="SIRIS ACADEMIC"/></a>
                    </div>                
                </div>
                <div className="col-12 col-md-5" style={{fontSize: '0.85rem', opacity:'0.8', lineHeight: '1.6'}}>      
                    <p style={{color:'whitesmoke'}}>
                        <img src='/images/DG logo with UN Emblem_Square_Web_transparent-white.png' style={{maxWidth:'25%', paddingRight:'10px'}} align="left"></img>
                        In the European Union, the <a style={{color:'whitesmoke'}} rel="noopener noreferrer" href='https://ec.europa.eu/commission/publications/reflection-paper-towards-sustainable-europe-2030_en' target='_blank'>Agenda 2030 is preeminent in the strategic framework</a> guiding the next programming period; most or all policy areas are impacted and necessary to achieve the desired progress towards the goals. Particularly, in the <a style={{color:'whitesmoke'}} rel="noopener noreferrer" href='https://ec.europa.eu/info/horizon-europe-next-research-and-innovation-framework-programme_en' target='_blank'>new Research and Innovation Framework Programme</a> of the European Commission, Horizon Europe, the SDGs set the stage for “Clusters in the Global Challenges and Industrial Competitiveness pillar” and the research and innovation <a style={{color:'whitesmoke'}} rel="noopener noreferrer" href='https://ec.europa.eu/info/horizon-europe-next-research-and-innovation-framework-programme/missions-horizon-europe_en' target='_blank'>Missions</a>.
                    </p>
                    <p style={{color:'whitesmoke'}}>
                        <strong>To better understand how science, technology and innovation efforts are thematically related to the Sustainable Development Goals (SDGs), <a href='http://www.sirisacademic.com/wb/' style={{color:'whitesmoke'}} target='_blank' rel="noopener noreferrer">SIRIS Academic</a> developed a novel methodology to identify SDG-related texts and classify them into the first 16 SDGs.</strong>
                    </p>
                </div>
            </div>
        
    }

    getFooterContent() {
        return <div className="row justify-content-md-center">
            <div className="align-self-center col-12">
                <h1>Methodology and data source</h1>
                <p>
                    This project has been possible thanks to the open data provided by the European Commission on FP7 and H2020 projects:<br/> © European Union, 1994-2020 CORDIS, <a href="https://cordis.europa.eu/" target='_blank' rel="noopener noreferrer">cordis.europa.eu</a>
                </p>
                <p>
                    Behind these visualisations, there is a complex <strong>3-step process</strong> that allowed us to link the text of the abstracts of EC-funded research projects to their respective Sustainable Development Goals. The process combines <strong>domain knowledge</strong>, <strong>machine learning technologies</strong> and <strong>ad hoc crafted data visualization</strong>.
                </p>
                <ol>
                    <li>
                        <h4>
                            Creation of a controlled vocabulary for the case of the Sustainable Development Goals
                        </h4>
                        <p>
                            The first step of the process is the creation of a controlled set of terms (a vocabulary) for each of the Sustainable Development Goals.
                            In doing so, we rely both on <strong>expert knowledge</strong> (namely, to define and limit the “semantic” breadth of each SDG) and on <strong>machine learning</strong> to rapidly scale the set of key terms defined by the domain experts
                        </p>
                        <p>
                            Our vocabulary is constructed by means of the following methodology:
                        </p>
                        <ul>
                            <li>
                                Qualitative desk research: We start by reading policy documents, reports and technical documents produced by the UN on the Sustainable Development Goals. This purely human effort allows us to identify an initial set of seed keywords for each SDG.
                            </li>
                            <li>
                                Application of Machine learning: The seed keywords are expanded by machine learning methods. We use both deep learning and AI reasoning on ontologies: on the one hand, a neural network model is trained on a reference textual corpus and it is used to retrieve all synonyms of the seed keywords it learned. On the other, we rely on the ontologies built on top of knowledge repositories to harvest all terms that are logically linked with the seed keywords.
                            </li>
                            <li>
                                Human revision: A final quality check of the results produced by the automated method is performed, to ensure results are in line with the initial definition of the perimeter of the SDGs.
                            </li>
                        </ul>
                        <p></p>
                    </li>

                    <li>
                        <h4>
                            Indexing of the textual records per SDG
                        </h4>
                        <p>
                            Once the vocabulary is created, it must be used to identify pertinent texts and to link them to their respective SDGs.
                        </p>
                        <p>
                            To do so, we use Natural Language Processing techniques: we retrieve the textual records, we process them and we match their content to the vocabulary.
                        </p>
                        <p>
                            We do so in a smart way: we allow up to a certain distance between terms in a keyword, we take into account both plural and singular nouns and we consider contextual terms for the most generic keywords, to avoid indexing “false positive” projects. For instance, the key term “woman’s rights” would match the sentence “empowering the right of all women around the world to choose”.
                        </p>
                    </li>

                    <li>
                        <h4>
                            Crafting of the interactive visualisations
                        </h4>
                        <p>
                            Multiple analyses can be done in relation to mapping Science Technology and Innovation (STI) efforts towards the SDGs, and there are many possible conclusions, that however remain only preliminary.
                        </p>
                        <p>
                            To foster the conversation around the topic and to drive actions, we make use of data-driven storytelling, in order to provide a guidance to the discussion through the visualizations. In doing so, we combine explanatory and exploratory approaches, where narrative and interaction coexist together.    
                        </p>
                        <p>
                            Data is presented to the user from different points of view, where complexity increases slowly. Our view of STI contributions to SDGs starts from annual shares of SDG-related funded projects, explores how countries feature a different SDG R&I portfolio, it goes on to highlight similarities of such countries and it ends up by showing trends on R&I based on SDG-related keywords.
                        </p>
                        <p>
                            For each analysis around the data, we carefully thought  of specific ad-hoc crafted visuals, aimed at revealing effectively the different types of knowledge, from patterns around research activity, behaviours on how countries can be clustered together, or trends over time.
                        </p>
                        <p>
                            When designing information, we take care about choosing effective visuals, eliminate the clutter and focus attention to what matters.
                        </p>
                        <p>
                            We conduct all this process through a transversal co-design approach: involving actors and stakeholders from the beginning, getting from them the questions they need to answer and ideating interactive visualizations to find responses and meaning from the data.   
                        </p>
                    </li>
                </ol>                              
            </div>            
        </div>
    }




    render() {
        return <div className={"header-canvas " + this.props.canvasId + (this.props.canvasId === 'canvas-footer'? ' bg-light':'')}>                        
        {/* <HeaderMosaic canvasId = {this.props.canvasId} direction={this.props.direction} probability={0.9}/> */}
                <div className="container">
                    { this.props.canvasId === 'canvas-header'?
                        this.getHeaderContent() : 
                        this.getFooterContent()
                    }
                </div>
        </div>
    }
}

export default Header;