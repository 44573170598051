/* eslint-disable */

import * as d3 from 'd3';
import * as _ from 'lodash';
import tsnejs from './tsne';
import p_numProjectsSDG from '../../data/tsne/p_numProjectsSDG.csv'

let tsneSolver = function() {

    var data, dataRaw;

    // svg info
    var elementSelector, // selector for svg
        svg,             // d3 svg selection
        width,           // svg width   
        height;          // svg height

    // t-sne settings
    var opt         = {}
    opt.epsilon     = 20; // epsilon is learning rate (10 = default)
    opt.perplexity  = 30; // roughly how many neighbors each point influences (30 = default)
    opt.dim         = 2; // dimensionality of the embedding (2 = default)
    
    // create a tSNE instance
    var tsne        = tsnejs.tSNE(opt); 

    // loads data files containing a specific measure, for all
    // countries and for all SDGs. Generated from R data analysis tasks:
    /*
    |countryName |SDG 1                |SDG 10               |SDG 11              |SDG 12              |SDG 13              |SDG 14               |SDG 15               |SDG 16               |SDG 2                |SDG 3                |SDG 4                |SDG 5                |SDG 6                |SDG 7               |SDG 8                |SDG 9              |
    |:-----------|:--------------------|:--------------------|:-------------------|:-------------------|:-------------------|:--------------------|:--------------------|:--------------------|:--------------------|:--------------------|:--------------------|:--------------------|:--------------------|:-------------------|:--------------------|:------------------|
    |Afghanistan |0                    |0.3333333333333333   |0                   |0                   |0                   |0                    |0                    |0                    |0                    |0                    |0                    |0                    |0                    |0                   |0                    |0                  |
    |Albania     |0.057692307692307696 |0                    |0.09615384615384616 |0                   |0.11538461538461539 |0.019230769230769232 |0.057692307692307696 |0.09615384615384616  |0.057692307692307696 |0.057692307692307696 |0                    |0.038461538461538464 |0.038461538461538464 |0.09615384615384616 |0.057692307692307696 |0.3076923076923077 |
    |Algeria     |0                    |0.044444444444444446 |0.1111111111111111  |0.1111111111111111  |0.13333333333333333 |0.08888888888888889  |0.1111111111111111   |0.044444444444444446 |0.13333333333333333  |0.044444444444444446 |0                    |0.044444444444444446 |0.044444444444444446 |0.08888888888888889 |0.1111111111111111   |0.4                |
    |Angola      |0                    |0                    |0                   |0                   |0.5                 |0.5                  |0                    |0                    |0                    |0.25                 |0                    |0                    |0                    |0                   |0                    |0                  |
    |Anguilla    |0                    |0                    |0                   |0                   |0.25                |0                    |0                    |0                    |0                    |0                    |0                    |0                    |0                    |0.5                 |0.25                 |0                  |
    |Argentina   |0.019230769230769232 |0.0673076923076923   |0.10576923076923077 |0.11057692307692307 |0.14903846153846154 |0.04326923076923077  |0.08173076923076923  |0.09134615384615384  |0.08173076923076923  |0.08173076923076923  |0.019230769230769232 |0.014423076923076924 |0.09134615384615384  |0.08173076923076923 |0.11057692307692307  |0.2692307692307692 |
    */
    function loadData() {
        return new Promise( function(resolve, reject) {
            d3.csv( 
                p_numProjectsSDG, 
                function(d) {
                    // cast to int all columns containing SDGs values
                    return _.mapValues(
                        d,
                        (v, k) => k.indexOf('SDG') !== -1? +v:v
                    );
                })
                .then(d => {
                    // transform the data so we have an array of arrays (high-dimensional 
                    // points that need to be embedded). Each array (country)
                    //contains an array with the SDG values for that country
                    data = d;
                    dataRaw = _.map(
                        d,
                        o => _.transform(o, (result, v, k) => {
                            if(k.indexOf('SDG') !== -1) result.push(v);
                        }, [])
                    );

                    tsne.initDataRaw(dataRaw);

                    // get final positions for each country
                    let coords = getSolution();

                    // join coords with the original country data,
                    // so we can access coords by country
                    data.forEach( (country, i) => {
                        country.tsneCoords = coords[i];
                    });

                    resolve(data);
                });
        });
    };
    

    function tsneCoordsToSVGCoords(elementSelector) {

        // consider some margin so we do not have
        // points on the edges of the SVG
        const margin = 50;

        let width   = +d3.select(elementSelector).attr('width') - margin,
            height  = +d3.select(elementSelector).attr('height') - margin;

        // scales to place the country according the
        // tsne coord using d3 scales
        // create scales 
        const x = d3.scaleLinear()
            .domain(d3.extent(data, function(d) { return d.tsneCoords[0];}))
            .range([0 + margin, width]);

        const y = d3.scaleLinear()
            .domain(d3.extent(data, function(d) { return d.tsneCoords[1];}))
            .range([height, 0 + margin]);
        
        data.forEach( (d, i) => {
            d.svgCoords = [
                x(d.tsneCoords[0]), 
                y(d.tsneCoords[1])
            ];
        });
    }

    function getData() {
        return data;
    }


    function draw(_elementSelector) {
        elementSelector = _elementSelector;
        svg = d3.select(elementSelector);
        width = svg.attr('width');
        height = svg.attr('height');

        let g = svg.selectAll('g.node')
                .data(data, d => d? d.countryName : undefined)
                .enter()
                .append('g')
                .attr('class', 'node');
        
        g.append('circle')
            .attr('cx', 100)
            .attr('cy', 100)
            .attr('r', 10)
            .attr('stroke-width', 1)
            .attr('stroke', 'black')
            .attr('fill', 'rgb(100,100,255)');

        g.append("text")
            .attr("text-anchor", "top")
            .attr("transform", "translate(5, -5)")
            .attr("font-size", 12)
            .attr("fill", "#333")
            .text(function(d,i) { return d.countryName; });
    }


    function getSolution() {
        // run a few steps
        for(var k = 0; k < 500; k++)
            // every time you call this, solution gets better
            tsne.step(); 

        // return array of 2-D points that you can plot
        return tsne.getSolution(); 
    }

    return {
        loadData: loadData,
        getSolution: getSolution,
        getData: getData,
        draw: draw,
        tsneCoordsToSVGCoords: tsneCoordsToSVGCoords
    }
};

const tsnePlot = tsneSolver();
export default tsnePlot;