import React, { PureComponent } from 'react';
import * as d3 from 'd3';
import * as datalib from 'datalib';
import * as _ from 'lodash';
import VegaChart from '../../common/vega/VegaChart';   //from './VegaChart'
import { streamgraph } from '../../common/vega/VegaSpecifications';
import paper from 'paper';
import WordleGenerator from './WordleGenerator';

import streamRiverData from '../../data/streamriver/streamriver-data-new.json';
import streamRiverKeywords from '../../data/streamriver/streamriver-keywords-new.json';

let InfoSDG = {
    'SDG 1' : { color: '#E5243B'},
    'SDG 2' : { color: '#DDA63A'},
    'SDG 3' : { color: '#4C9F38'},
    'SDG 4' : { color: '#C5192D'},
    'SDG 5' : { color: '#FF3A21'},
    'SDG 6' : { color: '#26BDE2'},
    'SDG 7' : { color: '#FCC30B'},
    'SDG 8' : { color: '#A21942'},
    'SDG 9' : { color: '#FD6925'},
    'SDG 10' : { color: '#DD1367'},
    'SDG 11' : { color: '#FD9D24'},
    'SDG 12' : { color: '#BF8B2E'},
    'SDG 13' : { color: '#3F7E44'},
    'SDG 14' : { color: '#0A97D9'},
    'SDG 15' : { color: '#56C02B'},
    'SDG 16' : { color: '#00689D'},
    'SDG 17' : { color: '#00689D'}
}

class StreamRiver extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            canvasLayout: []
        };
        this.saveCanvasLayoutData = this.saveCanvasLayoutData.bind(this);
    }



    componentDidMount() {
        paper.setup([1,1]);
        
        // add a sorting field to data
        streamRiverData.forEach( o => {
            o.order = +o.SDG.substring(("SDG ").length)
        });

        let data = _.filter(streamRiverData, o => o.year > 2007 && o.year < 2020);
        let keywords = _(streamRiverKeywords)
            .filter(o => o.year > 2007 && o.year <2020)
            .filter(o => o.count > 2)
            .value();

        this.setState({
            data: data,
            years: datalib.extent(data, o => o.year),
            keywords: keywords
        });
    }

    

    saveCanvasLayoutData(vegaView) {

        const getSDGLabelFromPath = (path) => {
            return _.find( 
                _.keys(InfoSDG),
                key => d3.color(InfoSDG[key].color).toString() === d3.color(path.style('fill')).toString()
            );            
        };

        d3.selectAll('.role-mark.sdg-serie path')
            .each( function() {
                let SDGLabel = getSDGLabelFromPath(d3.select(this));
                if(SDGLabel) {
                    InfoSDG[SDGLabel].path = d3.select(this).attr('d');
                }
            });
        
        // show the right side of the streamriver
        document.querySelector('.stream-river').scrollLeft = window.innerWidth * 2

        this.setState({
            canvasLayout: InfoSDG,
            // use the horizontal scale in vega to calculate the 
            // cutting points for the streamgraph
            scaleX: vegaView.scale('x')
        });
    };



    render() {
        let {
            data,
            canvasLayout
        } = this.state;

        if(data) 
            return <div className="stream-river">
                <VegaChart
                    chartClass={'vega-layout'}
                    autoSize={{ type: 'none', contains: 'padding'}}
                    padding={{top: 50, bottom: 50}}
                    data={data}
                    height={window.innerHeight * 0.90 /* same as in the CSS, .stream-river-> height: 95vh*/}
                    width={window.innerWidth * 2}
                    spec={streamgraph}
                    runAfterCallback={(vegaView) => {
                        _.isEmpty(canvasLayout) && this.saveCanvasLayoutData(vegaView);
                        }
                    }>
                </VegaChart>
                {
                    !_.isEmpty(canvasLayout) && <WordleGenerator 
                        {...this.state} 
                        handleOpenModalKeyword={this.props.handleOpenModalKeyword}
                    />
                }
            </div>
        else
            return <div/>
    }
}

export default StreamRiver;