import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import { history } from './common';
import Home from './containers/Home';

export const RouteApp = props => {
    return (   
        <Router history={history}>
            <Switch>
                <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />    
            </Switch>
        </Router>
    )        
}