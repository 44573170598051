import * as d3 from 'd3';

const myTooltip = function(){

    function show (title, subtitle, datablocks, vizspecs, footer) {

      //positionate tooltip
      var tooltip = d3.select('#node-tooltip')
        .style('display', 'block')
        .style('left', (d3.event? d3.event.pageX:0) + 'px');
        //.style('top', (d3.event.pageY - 50) + 'px');
      
      // assume that with no data we are just
      //tooltiping static text so make the tooltip smaller
      let noData = (datablocks === undefined || datablocks.length === 0);
      //tooltip.style('max-width',  noData? '125px':null)
      tooltip.style('max-height', noData? '135px':null)
      tooltip.style('overflow',   noData? 'hidden':null)

      //remove previous content
      tooltip.select('.node-tooltip-data-container').selectAll('.node-tooltip-data-block').remove();
      tooltip.select('.node-tooltip-data-container').selectAll('.node-tooltip-data-separator').remove();
      tooltip.select('.node-tooltip-viz-container').selectAll('*').remove();
      tooltip.select('.node-tooltip-data-footer').html('');

      //update title
      tooltip.select('.node-tooltip-title').html(title);

      //update subtitle
      tooltip.select('.node-tooltip-subtitle').html(subtitle);

      datablocks.forEach(function(datablock) {
        //add data
        var datablockDiv = tooltip.select('.node-tooltip-data-container').append('div').attr('class','node-tooltip-data-block')
        datablockDiv.append('div').attr('class', 'node-tooltip-data-name').html(datablock.key);
        datablockDiv.append('div').attr('class', 'node-tooltip-data-value').html(datablock.value);
        tooltip.select('.node-tooltip-data-container').append('div').attr('class','node-tooltip-data-separator')
      });

      // delete last separator
      tooltip.select('.node-tooltip-data-separator:last-of-type').remove();

      // add footer
      if(footer && footer !== ''){
        tooltip.select('.node-tooltip-footer').html(footer);
      } else {
        tooltip.select('.node-tooltip-footer').html('');
      }
        
/*
      //embed viz if any
      if(vizspecs)
        vg.embed('.node-tooltip-viz-container', vizspecs, function(error, result) {});
*/
      // and move up the content according to its size
      // so it does not overlapd with the mouse. Take
      // into account the presence of the viz: don't
      // wait until the viz is rendered to get the height
      // but get it from the viz specs
      var offsetHeight = tooltip.node().offsetHeight
        + ((vizspecs)? vizspecs.spec.height+60 : 20);
      tooltip.style('top', ((d3.event? d3.event.pageY:0) - offsetHeight) + 'px');

      // if tooltip is placed out of the boundaries of the screen,
      // automatically placed it inside
      var tooltipWidth = tooltip.node().getBoundingClientRect().width,
          tooltipX = tooltip.node().getBoundingClientRect().left,
          windowWidth = window.innerWidth;
      if ((tooltipX + tooltipWidth) > windowWidth){
        tooltip.style('left', (tooltipX - tooltipWidth - 5) + 'px');
      }
    }

    function hide() {
      d3.select('#node-tooltip').style('display', 'none');
    };

    function move(coords) {
      d3.select('#node-tooltip')
        .style('display', 'block')
        .style('left', coords[0] + 'px')
        .style('top', coords[1] + 'px');
    }
    

    return {
        show: show,
        hide: hide,
        move: move
    };
}
const Tooltip = myTooltip();
export default Tooltip;