import React, { Component } from 'react';
import * as _ from 'lodash';
import * as d3 from 'd3';
// eslint-disable-next-line
import WordCloud from './wordcloud2';

class Canvas extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }


    componentDidMount() {
        const {
            color,
            path,
            data,
            angle,
            offsetWidthFactor,
            curves,
            year,
            sdg,
            wordCloudCompletionListener            
        } = this.props;
        setTimeout( () => {


            const minFontSize = 4;
            const drawingAreaColor = 'black';
            const foregroundColor = color;
            let context = this.refs.canvas.getContext('2d');
            context.strokeStyle = context.fillStyle = drawingAreaColor;
            var p = new Path2D(path);
            context.stroke(p);
            context.fill(p);
            if(data.length === 0)
                return;
            let maxWeight = d3.max(data, d => d[1]);
            let minWeight = d3.min(data, d => d[1]);

            // given the highest weight, estimate the maximum font
            // size
            const fitTextOnWidth = (text, width) => {    
                // start with a large font size
                var fontsize = 30;
                // lower the font size until the text fits the width
                do {
                    fontsize--;
                    context.font = '300 ' + fontsize.toString() + 'px Trebuchet MS';
                } while(context.measureText(text).width > width)
                return fontsize;
            };

            let maxFontSize = fitTextOnWidth(
                _.first(data)[0], 
                context.canvas.clientWidth / offsetWidthFactor
            );

            let scaleFontSize = d3.scaleLinear()
                .domain([maxWeight, minWeight])
                .range([maxFontSize, minFontSize + 0.1]);

            let scaleOpacity = d3.scaleLinear()
                .domain([maxWeight, minWeight])
                .range([0.9, 0.5]);

            // add listener to control when wordclouds finish
            this.refs.canvas.addEventListener('wordcloudstop', function(e) {
                wordCloudCompletionListener(e);
            }, false);

            window.WordCloud(
                this.refs.canvas,
                {
                    // Presentation
                    list: data.sort( (a, b) => b[1] - a[1]),
                    color: foregroundColor,
                    minSize: minFontSize,
                    weightFactor: scaleFontSize,
                    scaleOpacity: scaleOpacity,
                    backgroundColor: drawingAreaColor,
                    clearCanvas: false,
                    // Dimension
                    gridSize: 4, // but the algorithm doesn't allow less than 4
                    drawOutOfBound: false,
                    shrinkToFit: false,
                    // Mask
                    drawMask: false,
                    // Rotation
                    minRotation: angle,
                    maxRotation: angle,
                    rotateRatio: 1,
                    // shape
                    shape: 'square',

                    // curves,
                    meta: { curves, year, sdg}
                } 
            );         
        }, 1000);
    }

    render() {
        return <div className={'canvasSDG'}>
            <canvas ref='canvas' 
                width={this.props.width} 
                height={this.props.height}
                style={{
                    width: this.props.width, 
                    height: this.props.height,
                    left: this.props.x,
                    top: this.props.y
                }}
            />
        </div>        
    }
}
export default Canvas; 