export var VegaSpecStripPlot = {
  "$schema": "https://vega.github.io/schema/vega/v5.json",  
  "padding": {"top":0, "right":0, "bottom":0, "left": 0},
  "width": 600,
  "height": 25,
  "data": [
    {
      "name": "source_0",
      "values": [
          {"country": "Afghanistan", "total": 3},
          {"country": "Algeria", "total": 45},
          {"country": "Argentina", "total": 208},
          {"country": "Armenia", "total": 69},
          {"country": "Australia", "total": 368},
          {"country": "Austria", "total": 4646},
          {"country": "Azerbaijan", "total": 40},
          {"country": "Bangladesh", "total": 19},
          {"country": "Barbados", "total": 2},
          {"country": "Belarus", "total": 89},
          {"country": "Belgium", "total": 7346},
          {"country": "Bolivia", "total": 16},
          {"country": "Bosnia and Herzegovina", "total": 77},
          {"country": "Botswana", "total": 12},
          {"country": "Brazil", "total": 286},
          {"country": "Bulgaria", "total": 964},
          {"country": "Burkina Faso", "total": 40},
          {"country": "Cameroon", "total": 35},
          {"country": "Canada", "total": 398},
          {"country": "Cape Verde", "total": 19},
          {"country": "Chile", "total": 131},
          {"country": "China", "total": 459},
          {"country": "Colombia", "total": 92},
          {"country": "Costa Rica", "total": 37},
          {"country": "Croatia", "total": 693},
          {"country": "Cyprus", "total": 851},
          {"country": "Czech Republic", "total": 2047},
          {"country": "DR Congo", "total": 5},
          {"country": "Denmark", "total": 4035},
          {"country": "Dominican Republic", "total": 4},
          {"country": "Ecuador", "total": 29},
          {"country": "Egypt", "total": 138},
          {"country": "Estonia", "total": 916},
          {"country": "Ethiopia", "total": 48},
          {"country": "Fiji", "total": 3},
          {"country": "Finland", "total": 3313},
          {"country": "France", "total": 12840},
          {"country": "Georgia", "total": 92},
          {"country": "Germany", "total": 15773},
          {"country": "Ghana", "total": 65},
          {"country": "Greece", "total": 2651},
          {"country": "Hong Kong", "total": 35},
          {"country": "Hungary", "total": 2000},
          {"country": "Iceland", "total": 463},
          {"country": "India", "total": 239},
          {"country": "Indonesia", "total": 45},
          {"country": "Iran", "total": 17},
          {"country": "Iraq", "total": 4},
          {"country": "Ireland", "total": 2945},
          {"country": "Israel", "total": 2805},
          {"country": "Italy", "total": 11730},
          {"country": "Japan", "total": 215},
          {"country": "Jordan", "total": 52},
          {"country": "Kazakhstan", "total": 36},
          {"country": "Kenya", "total": 117},
          {"country": "Kosovo", "total": 14},
          {"country": "Kyrgyzstan", "total": 17},
          {"country": "Latvia", "total": 545},
          {"country": "Lebanon", "total": 51},
          {"country": "Lesotho", "total": 5},
          {"country": "Libya", "total": 4},
          {"country": "Liechtenstein", "total": 17},
          {"country": "Lithuania", "total": 667},
          {"country": "Luxembourg", "total": 578},
          {"country": "Macedonia", "total": 145},
          {"country": "Madagascar", "total": 11},
          {"country": "Mali", "total": 19},
          {"country": "Malta", "total": 291},
          {"country": "Mexico", "total": 136},
          {"country": "Moldova", "total": 91},
          {"country": "Montenegro", "total": 53},
          {"country": "Morocco", "total": 151},
          {"country": "Mozambique", "total": 28},
          {"country": "Namibia", "total": 19},
          {"country": "Nepal", "total": 8},
          {"country": "Netherlands", "total": 9325},
          {"country": "New Caledonia", "total": 11},
          {"country": "New Zealand", "total": 89},
          {"country": "Nigeria", "total": 41},
          {"country": "Norway", "total": 2816},
          {"country": "Pakistan", "total": 16},
          {"country": "Palestine", "total": 29},
          {"country": "Panama", "total": 8},
          {"country": "Papua New Guinea", "total": 4},
          {"country": "Peru", "total": 45},
          {"country": "Philippines", "total": 31},
          {"country": "Poland", "total": 3008},
          {"country": "Portugal", "total": 3328},
          {"country": "Romania", "total": 1553},
          {"country": "Russia", "total": 498},
          {"country": "Rwanda", "total": 13},
          {"country": "Senegal", "total": 69},
          {"country": "Serbia", "total": 505},
          {"country": "Singapore", "total": 45},
          {"country": "Slovakia", "total": 752},
          {"country": "Slovenia", "total": 1418},
          {"country": "Somalia", "total": 2},
          {"country": "South Africa", "total": 333},
          {"country": "South Korea", "total": 112},
          {"country": "Spain", "total": 12537},
          {"country": "Sri Lanka", "total": 13},
          {"country": "Sudan", "total": 8},
          {"country": "Sweden", "total": 5479},
          {"country": "Switzerland", "total": 5888},
          {"country": "Syria", "total": 9},
          {"country": "Taiwan", "total": 74},
          {"country": "Tajikistan", "total": 9},
          {"country": "Tanzania", "total": 61},
          {"country": "Thailand", "total": 66},
          {"country": "Tunisia", "total": 136},
          {"country": "Turkey", "total": 1548},
          {"country": "Uganda", "total": 66},
          {"country": "Ukraine", "total": 312},
          {"country": "United Kingdom", "total": 10752},
          {"country": "United States", "total": 1447},
          {"country": "Uruguay", "total": 50},
          {"country": "Venezuela", "total": 11},
          {"country": "Vietnam", "total": 64},
          {"country": "Zambia", "total": 15},
          {"country": "Albania", "total": 52},
          {"country": "Guatemala", "total": 8},
          {"country": "Guinea", "total": 1},
          {"country": "Ivory Coast", "total": 9},
          {"country": "Laos", "total": 8},
          {"country": "Malawi", "total": 23},
          {"country": "Malaysia", "total": 54},
          {"country": "Nicaragua", "total": 5},
          {"country": "Niger", "total": 11},
          {"country": "Swaziland", "total": 5},
          {"country": "Uzbekistan", "total": 18},
          {"country": "Benin", "total": 16},
          {"country": "Cambodia", "total": 11},
          {"country": "Faroe Islands", "total": 36},
          {"country": "French Polynesia", "total": 5},
          {"country": "Greenland", "total": 17},
          {"country": "Guyana", "total": 3},
          {"country": "Liberia", "total": 1},
          {"country": "Marshall Islands", "total": 1},
          {"country": "Mauritius", "total": 8},
          {"country": "Monaco", "total": 6},
          {"country": "Mongolia", "total": 3},
          {"country": "Samoa", "total": 1},
          {"country": "Saudi Arabia", "total": 11},
          {"country": "São Tomé and Príncipe", "total": 1},
          {"country": "Togo", "total": 4},
          {"country": "United Arab Emirates", "total": 8},
          {"country": "Vanuatu", "total": 1},
          {"country": "Angola", "total": 4},
          {"country": "Anguilla", "total": 4},
          {"country": "Central African Republic", "total": 2},
          {"country": "Cuba", "total": 20},
          {"country": "Curaçao", "total": 2},
          {"country": "Gabon", "total": 7},
          {"country": "Honduras", "total": 4},
          {"country": "Jamaica", "total": 12},
          {"country": "Maldives", "total": 2},
          {"country": "Mauritania", "total": 2},
          {"country": "Republic of the Congo", "total": 3},
          {"country": "Trinidad and Tobago", "total": 2},
          {"country": "Seychelles", "total": 3},
          {"country": "Macau", "total": 2},
          {"country": "Qatar", "total": 5},
          {"country": "Turkmenistan", "total": 5},
          {"country": "El Salvador", "total": 1},
          {"country": "Gambia", "total": 1},
          {"country": "Guinea-Bissau", "total": 1},
          {"country": "Paraguay", "total": 4},
          {"country": "Sierra Leone", "total": 2},
          {"country": "United States Minor Outlying Islands", "total": 2},
          {"country": "Oman", "total": 3},
          {"country": "BES islands", "total": 1},
          {"country": "Gibraltar", "total": 7},
          {"country": "Jersey", "total": 1},
          {"country": "San Marino", "total": 1},
          {"country": "Burundi", "total": 5}
      ],
      "transform": [
        {
          "type": "extent",
          "field": "total",
          "signal": "extent"
        }
      ]
    }
  ],
  "signals": [
    {"name": "tickValues", "value": [1, 50, 100, 500, 1000, 5000, 10000, 15000, 20000]},
    {"name": "height", "update": "height"},
    {
      "name": "initialBrush",
      "value": null,
      "update": "!isValid(initialBrush)? initialBrush : inrange(initialBrush, [tickValues[0], tickValues[2]])? [tickValues[0], tickValues[2]] : inrange(initialBrush, [tickValues[2], tickValues[4]])? [tickValues[2], tickValues[4]] : [tickValues[4], tickValues[8]-2000]"
    },
    {
          "name": "brush", 
          "value": 0,
          "update": "!isValid(initialBrush)? 0 : [scale('x',initialBrush[0]), scale('x', initialBrush[1])]",
          "on": [
            {
              "events": "@distBrush:mousedown",
              "update": "[x(), x()]"
            },
            {
              "events": "[@distBrush:mousedown, window:mouseup] > window:mousemove!",
              "update": "[brush[0], clamp(x(), 0, width)]"
            },
            {
              "description": "to drag the current brush",
              "events": {"signal": "delta"},
              "update": "clampRange([anchor[0] + delta, anchor[1] + delta], 0, width)"
            }
          ]
        },
        {
          "name": "anchor", "value": null,
          "on": [{"events": "@brush:mousedown", "update": "slice(brush)"}]
        },
        {
          "name": "xdown", "value": 0,
          "on": [{"events": "@brush:mousedown", "update": "x()"}]
        },
        {
          "name": "delta", "value": 0,
          "on": [
            {
              "events": "[@brush:mousedown, window:mouseup] > window:mousemove!",
              "update": "x() - xdown"
            }
          ]
        },
        {
          "name": "detailDomain",
          "update": "span(brush) ? invert('x', brush) : null",
          "on": [
            {
              "events": {"signal": "brush"},
              "update": "span(brush) ? invert('x', brush) : null"
            }
          ]
        }
  ],
  "scales": [
    {
      "name": "x",
      "type": "pow",
      "exponent": 0.05 ,      
      "domain": [1, {"signal": "extent[1]"}],
      "range": "width",
      "nice": false,
      "zero": false
    }
  ],
  "axes": [
    {
      "scale": "x",
      "domain": false,
      "ticks": true,
      "orient": "bottom",
      "grid": false,
      "title": "Country distribution by number of R&I projects",
      "titlePadding": 10,
      "labelFlush": true,
      "labelOverlap": true,
      "values": {"signal": "tickValues"},
      "zindex": 0
    }
  ],
  "marks": [
    {
      "type": "rect",
      "name": "texture",
      "interactive": false,
      "encode": {
        "enter": {
          "y": {"value": 0},
          "height": {"signal": "height"},
          "fill": {"value": "#333"},
          "fillOpacity": {"value": 0.7}
        },
        "update": {
          "x": {"signal": "brush[0]"},
          "x2": {"signal": "brush[1]"}
        }
      }
    },
    {
      "type": "rect",
      "name": "distBrush",
      "encode": {
        "enter": {
          "y": {"value": 0},
          "height": {"signal": "height"},
          "width": {"signal": "width"},
          "fill": {"value": "#19486A"},
          "fillOpacity": {"value": 0.03}
        }
      }
    },
    {
      "name": "marks",
      "interactive": false,
      "type": "rect",
      "from": {"data": "source_0"},
      "encode": {
        "enter": {
          "tooltip": {"signal": "datum"},          
          "xc": {"scale": "x", "field": "total"},
          "y": { "value": 0 },
          "height": {"signal": "height"}        
        },
        "update": {
          "width": {
            "signal": "!isValid(detailDomain)? 1 : (inrange(datum.total, detailDomain)? 2 : 1)"
          },
          "fill": {
            "signal": "!isValid(detailDomain)? '#19486A' : (inrange(datum.total, detailDomain)? '#fa8072' : '#19486A')"
          },
          "opacity": {
            "signal": "!isValid(detailDomain)? 0.35 : (inrange(datum.total, detailDomain)? 0.75 : 0.35)"
          }
        }
      }
    },
    {
      "type": "rect",
      "name": "brush",
      "encode": {
        "enter": {
          "y": {"value": 0},
          "height": {"signal": "height"},
          "fill": {"value": "#333"},
          "fillOpacity": {"value": 0}
        },
        "update": {
          "x": {"signal": "brush[0]"},
          "x2": {"signal": "brush[1]"}
        }
      }
    },
    {
      "type": "rect",
      "interactive": false,
      "encode": {
        "enter": {
          "y": {"value": -4},
          "height": {"signal": "height + 8"},
          "width": {"value": 4},
          "fill": {"value": "#19486A"},
        },
        "update": {
          "x": {"signal": "brush[0]"},
          "fillOpacity": { "signal": "isValid(detailDomain)? 0.8:0"}
        }
      }
    },
    {
      "type": "rect",
      "interactive": false,
      "encode": {
        "enter": {
          "y": {"value": -4},
          "height": {"signal": "height + 8"},
          "width": {"value": 4},
          "fill": {"value": "#19486A"}
        },
        "update": {
          "x": {"signal": "brush[1]"},
          "fillOpacity": { "signal": "isValid(detailDomain)? 0.8:0"}
        }
      }
    }
  ]  
};