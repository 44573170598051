import * as _ from 'lodash';
import * as d3 from 'd3';

/**
 * Show/hide those elements acting as placholders of static plots
 */
export function showStaticPlaceholders(visibility = true) {
    var toggle = selection =>
        selection
            .transition()
            .duration(300)
            .style('opacity', visibility ? 1 : 0);

    d3.select('.chart-container.primary').call(toggle);
    d3.selectAll('.chart-container.secondary').call(toggle);
    d3.select('.header-similar-countries').call(toggle);
};



/**
 * When the countries are shown in static positions within
 * the SVG, these positions are visually centered acording
 * other placholders (other divs outside the SVG), so
 * given these DOM elements, calculate its size (the country
 * plot is sized according this sized) and its position (it
 * will be the center of the placeholder)
 * @param {DOM node} reactRefs 
 */
export function getStaticPositions(reactRefs) {

    // get only those react refs nodes that
    // contain country plots AND the header
    const countryRefs = _.filter(
        _.keys(reactRefs),
        ref => ref.indexOf('country') !== -1
    );

    let refsData = {};

    countryRefs
        .concat(['secondary-header'])
        .forEach(ref => {
        switch (ref) {
            case 'secondary-header':
                refsData[ref] = {
                    width: reactRefs['secondary-header'].offsetWidth,
                    height: reactRefs['secondary-header'].offsetHeight
                }; break;

            case 'primary-country':
                refsData[ref] = {
                    x: reactRefs['primary-country'].offsetWidth / 2,
                    y: (reactRefs['primary-country'].offsetHeight / 2) * 1.2,
                    width: reactRefs['primary-country'].offsetWidth,
                    height: reactRefs['primary-country'].offsetHeight,
                    isPrimary: true
                }; break;

            case 'secondary-country-0':
                
                refsData[ref] = {
                    x: reactRefs['primary-country'].offsetWidth +
                        reactRefs['secondary-country-0'].offsetWidth / 2,
                    y: reactRefs['secondary-header'].offsetHeight + (reactRefs['secondary-country-0'].offsetHeight / 2),
                    width: reactRefs['secondary-country-0'].offsetWidth,
                    height: reactRefs['secondary-country-0'].offsetHeight,
                    isPrimary: false
                }; break;

            case 'secondary-country-1':
                refsData[ref] = {
                    x: reactRefs['primary-country'].offsetWidth +
                        reactRefs['secondary-country-0'].offsetWidth +
                        reactRefs['secondary-country-1'].offsetWidth / 2,
                    y: reactRefs['secondary-header'].offsetHeight + (reactRefs['secondary-country-1'].offsetHeight / 2),
                    width: reactRefs['secondary-country-1'].offsetWidth,
                    height: reactRefs['secondary-country-1'].offsetHeight,
                    isPrimary: false
                }; break;

            case 'secondary-country-2':
                refsData[ref] = {
                    x: reactRefs['primary-country'].offsetWidth +
                        reactRefs['secondary-country-2'].offsetWidth / 2,
                    y: reactRefs['secondary-header'].offsetHeight + 
                        reactRefs['secondary-country-0'].offsetHeight +
                        (reactRefs['secondary-country-2'].offsetHeight / 2),
                    width: reactRefs['secondary-country-2'].offsetWidth,
                    height: reactRefs['secondary-country-2'].offsetHeight,
                    isPrimary: false
                }; break;

            case 'secondary-country-3':
                refsData[ref] = {
                    x: reactRefs['primary-country'].offsetWidth +
                        reactRefs['secondary-country-2'].offsetWidth +
                        reactRefs['secondary-country-3'].offsetWidth / 2,
                    y: reactRefs['secondary-header'].offsetHeight + 
                        reactRefs['secondary-country-0'].offsetHeight +
                        (reactRefs['secondary-country-3'].offsetHeight / 2),
                    width: reactRefs['secondary-country-3'].offsetWidth,
                    height: reactRefs['secondary-country-3'].offsetHeight,
                    isPrimary: false
                }; break;
            default: break;
        }
    });

    return refsData;
}


/**
 * Fromt the list of country plots, picks the one
 * with the countryLabel + 4 random
 * @param {Collection} plots 
 * @param {String} countryLabel 
 */
export function pickPlotsForStaticLayout(plots, country, isMobile) {
    let staticPlots = [];

    staticPlots.push(
        _.find(plots, plot => plot.label === country.label)
    );
    !isMobile && country.related.forEach(countryName => {
        staticPlots.push(
            _.find(plots, plot => plot.label === countryName.label)
        );
    })

    return staticPlots;
}

var contains = function (x1, y1, w, h, x, y) {
    return x1 <= x && x <= x1 + w && y1 <= y && y <= y1 + h;
}


export function getCountryHoverListener(reactThis, fixedLayoutData, REFS) {
    const iconSize = 63;
    const cleanHover = () => {
        reactThis.refs['primary-country'].style.boxShadow =
            reactThis.refs['secondary-country-0'].style.boxShadow =
            reactThis.refs['secondary-country-1'].style.boxShadow =
            reactThis.refs['secondary-country-2'].style.boxShadow =
            reactThis.refs['secondary-country-3'].style.boxShadow = null;
        reactThis.refs['primary-country'].style.zIndex =
            reactThis.refs['secondary-country-0'].style.zIndex =
            reactThis.refs['secondary-country-1'].style.zIndex =
            reactThis.refs['secondary-country-2'].style.zIndex =
            reactThis.refs['secondary-country-3'].style.zIndex = 0
    };

        reactThis.refs['show-modal-btn'].style.left = 
                        - iconSize + 10 + 
                        fixedLayoutData[REFS.PRIMARY_COUNTRY].width + 'px';
        reactThis.refs['show-modal-btn'].style.top = 
                        - iconSize + fixedLayoutData[REFS.PRIMARY_COUNTRY].height + 'px';
        
    

    d3.select('.svg-placeholder-countries').node()
        .addEventListener('mousemove', e => {
            // when not in the static step, do not show button
            if(reactThis.state.phase !== 'static')
                return;
            cleanHover();
            if (contains(
                0, 0,
                fixedLayoutData[REFS.PRIMARY_COUNTRY].width,
                fixedLayoutData[REFS.PRIMARY_COUNTRY].height,
                e.offsetX, e.offsetY
            )) {
                reactThis.staticCountryIndex = 0;
                reactThis.refs['primary-country'].style.boxShadow = '0 0 25px rgba(0,0,0,.15)';
                reactThis.refs['primary-country'].style.zIndex = 100;
                reactThis.refs['show-modal-btn'].style.display='block';
                reactThis.refs['show-modal-btn'].style.left = 
                    - iconSize + 10 + 
                    fixedLayoutData[REFS.PRIMARY_COUNTRY].width + 'px';
                reactThis.refs['show-modal-btn'].style.top = 
                    - iconSize + fixedLayoutData[REFS.PRIMARY_COUNTRY].height + 'px';
            }
            else if (contains(
                fixedLayoutData[REFS.PRIMARY_COUNTRY].width,
                fixedLayoutData[REFS.SECONDARY_HEADER].height,
                fixedLayoutData[REFS.SECONDARY_COUNTRY_0].width,
                fixedLayoutData[REFS.SECONDARY_COUNTRY_0].height,
                e.offsetX, e.offsetY
            )) {
                reactThis.staticCountryIndex = 1;
                reactThis.refs['secondary-country-0'].style.boxShadow = '0 0 25px rgba(0,0,0,.15)';
                reactThis.refs['secondary-country-0'].style.zIndex = 100;
                reactThis.refs['show-modal-btn'].style.display='block';
                reactThis.refs['show-modal-btn'].style.left = 
                    - iconSize + 10 +
                    fixedLayoutData[REFS.PRIMARY_COUNTRY].width + 
                    fixedLayoutData[REFS.SECONDARY_COUNTRY_0].width + 'px';                
                reactThis.refs['show-modal-btn'].style.top = 
                    - iconSize + 
                    fixedLayoutData[REFS.SECONDARY_HEADER].height + 
                    fixedLayoutData[REFS.SECONDARY_COUNTRY_0].height  + 'px';
            }
            else if (contains(
                fixedLayoutData[REFS.PRIMARY_COUNTRY].width + fixedLayoutData[REFS.SECONDARY_COUNTRY_0].width,
                fixedLayoutData[REFS.SECONDARY_HEADER].height,
                fixedLayoutData[REFS.SECONDARY_COUNTRY_1].width,
                fixedLayoutData[REFS.SECONDARY_COUNTRY_1].height,
                e.offsetX, e.offsetY
            )) {
                reactThis.staticCountryIndex = 2;
                reactThis.refs['secondary-country-1'].style.boxShadow = '0 0 25px rgba(0,0,0,.15)';
                reactThis.refs['secondary-country-1'].style.zIndex = 100;
                reactThis.refs['show-modal-btn'].style.display='block';
                reactThis.refs['show-modal-btn'].style.left = 
                    - iconSize + 10 +
                    fixedLayoutData[REFS.PRIMARY_COUNTRY].width +
                    fixedLayoutData[REFS.SECONDARY_COUNTRY_0].width +
                    fixedLayoutData[REFS.SECONDARY_COUNTRY_1].width + 'px';
                reactThis.refs['show-modal-btn'].style.top = 
                    - iconSize +
                    fixedLayoutData[REFS.SECONDARY_HEADER].height + 
                    fixedLayoutData[REFS.SECONDARY_COUNTRY_1].height  + 'px';
            }
            else if (contains(
                fixedLayoutData[REFS.PRIMARY_COUNTRY].width,
                fixedLayoutData[REFS.SECONDARY_HEADER].height + fixedLayoutData[REFS.SECONDARY_COUNTRY_0].height,
                fixedLayoutData[REFS.SECONDARY_COUNTRY_2].width,
                fixedLayoutData[REFS.SECONDARY_COUNTRY_2].height,
                e.offsetX, e.offsetY
            )) {
                reactThis.staticCountryIndex = 3;
                reactThis.refs['secondary-country-2'].style.boxShadow = '0 0 25px rgba(0,0,0,.15)';
                reactThis.refs['secondary-country-2'].style.zIndex = 100;
                reactThis.refs['show-modal-btn'].style.display='block';
                reactThis.refs['show-modal-btn'].style.left = 
                    - iconSize + 10 +
                    fixedLayoutData[REFS.PRIMARY_COUNTRY].width + 
                    fixedLayoutData[REFS.SECONDARY_COUNTRY_2].width + 'px';
                reactThis.refs['show-modal-btn'].style.top = 
                    - iconSize + 
                    fixedLayoutData[REFS.SECONDARY_HEADER].height + 
                    fixedLayoutData[REFS.SECONDARY_COUNTRY_0].height +
                    fixedLayoutData[REFS.SECONDARY_COUNTRY_2].height + 'px';
            }
            else if (contains(
                fixedLayoutData[REFS.PRIMARY_COUNTRY].width + fixedLayoutData[REFS.SECONDARY_COUNTRY_0].width,
                fixedLayoutData[REFS.SECONDARY_HEADER].height + fixedLayoutData[REFS.SECONDARY_COUNTRY_0].height,
                fixedLayoutData[REFS.SECONDARY_COUNTRY_3].width,
                fixedLayoutData[REFS.SECONDARY_COUNTRY_3].height,
                e.offsetX, e.offsetY
            )) {
                reactThis.staticCountryIndex = 4;
                reactThis.refs['secondary-country-3'].style.boxShadow = '0 0 25px rgba(0,0,0,.15)';
                reactThis.refs['secondary-country-3'].style.zIndex = 100;
                reactThis.refs['show-modal-btn'].style.display='block';
                reactThis.refs['show-modal-btn'].style.left = 
                    - iconSize + 10 + 
                    fixedLayoutData[REFS.PRIMARY_COUNTRY].width +
                    fixedLayoutData[REFS.SECONDARY_COUNTRY_0].width +
                    fixedLayoutData[REFS.SECONDARY_COUNTRY_3].width + 'px';
                reactThis.refs['show-modal-btn'].style.top = 
                    - iconSize + 
                    fixedLayoutData[REFS.SECONDARY_HEADER].height + 
                    fixedLayoutData[REFS.SECONDARY_COUNTRY_1].height +
                    fixedLayoutData[REFS.SECONDARY_COUNTRY_2].height  + 'px';
            }
        });
    d3.select('.svg-placeholder-countries').node()
        .addEventListener('mouseleave', () => {
            cleanHover();
            //reactThis.refs['show-modal-btn'].style.display='none';
        });

}