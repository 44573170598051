import React, { Component, ReactComponent } from 'react';
import * as d3 from "d3";
import * as _ from 'lodash';
import 'intersection-observer';
import scrollama from 'scrollama';
import CountryPlots from '../CountryPlots'
import Introduction from '../Introduction'
import Header from '../Header';
import StreamRiver from '../Streamriver/StreamRiver';
import { Link, Element, animateScroll as scroll } from 'react-scroll'
import SDGTreemap from '../../components/SDGTreemap';
import withSizes from 'react-sizes';
import streamRiverKeywords from '../../data/streamriver/streamriver-keywords.json';
import modalHeaderData from '../../data/modal/countries_absolute_values.json'
import KeywordData from '../../components/KeywordData';
import keywordsIncrementsData from '../../data/keywords/keyword_relative_increment.json';
import CountryPlotGenerator from '../../components/CountryPlotGenerator';
import * as vega from 'vega';
import SPARQL from 'vega-transform-sparql';
import { IoMdClose } from "react-icons/io";
import { IoIosMenu } from "react-icons/io";
import VegaChart from '../../common/vega/VegaChart';
import { keywordsIncrements } from '../../common/vega/VegaSpecifications';
import { Modal } from 'react-bootstrap';
import {ReactComponent as LegendSDG} from '../../images/svg/SI-legend.svg';


vega.transforms['sparql'] = SPARQL;

var Stickyfill = require('stickyfill');

const variableGroup =
{
    funding: {
        country: 'p_fundingSDG',
        ghost: 'p_totalFundingCORDIS',
        sorting: 'totalFundingCountry'
    },
    projects: {
        country: 'p_numProjectsSDG',
        ghost: 'p_totalNumCORDIS',
        sorting: 'totalProjectsCountry'
    }
}


class Home extends Component {

    constructor() {
        super();
        this.state = {
            loading: true,
            step: 0,
            stepStream: 0,
            activeComponent: null,
            selectedOption: null,
            activeVariable: 'projects',
            showModal: false,
            activeMenu: false,
            showModalKeyword: false
        }

        this.myCountryPlots = React.createRef();

        this.toggleClass = this.toggleClass.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);

        // modal window when clicking a keyword
        this.handleOpenModalKeyword = this.handleOpenModalKeyword.bind(this);
        this.handleCloseModalKeyword = this.handleCloseModalKeyword.bind(this);
        // modal window when clicking a country
        this.handleOpenModalCountry = this.handleOpenModalCountry.bind(this);
        this.handleCloseModalCountry = this.handleCloseModalCountry.bind(this);
    }

    handleOpenModalKeyword(keyword) {
        // get SDGs where this keyword is present. Per each SDG
        // the modal show a column with data related the presence
        // of the keyword on the SDG, show we size the modal
        // window width according the number of columns
        let modalKeywordClassName;
        const sdgLabels = _(streamRiverKeywords)
            .filter(o => o.year > 2007 && o.year < 2020)
            .filter(o => o.keyword === keyword)
            .uniqBy('SDG')
            .map('SDG')
            .value();
        switch (sdgLabels.length) {
            case 1: modalKeywordClassName = 'modal-25w'; break;
            case 2:
            case 3: modalKeywordClassName = 'modal-75w'; break;
            case 4:
            default: modalKeywordClassName = 'modal-90w'; break;
        }

        this.setState({
            showModalKeyword: true,
            modalSDGLabels: sdgLabels,
            modalKeywordClassName: modalKeywordClassName,
            clickedKeyword: keyword
        });
    }

    handleCloseModalKeyword() {
        this.setState({
            showModalKeyword: false,
            modalSDGLabels: null,
            modalKeywordClassName: null,
            clickedKeyword: null
        });
    };

    handleOpenModalCountry(countryName, countryData, id, countryVariable, ghostVariable, sortingVariable) {
        this.setState({
            showModalCountry: true,
            modalTitle: countryName,
            modalData: countryData,
            modalIndicators: {
                totalProjectsCountry: modalHeaderData.find(country => country.countryName === countryName).totalProjectsCountry,
                totalProjectsCountrySDG: modalHeaderData.find(country => country.countryName === countryName).numProjectsInSomeSDG,
                totalFundingCountry: modalHeaderData.find(country => country.countryName === countryName).totalFundingCountry,
                totalFundingCountrySDG: modalHeaderData.find(country => country.countryName === countryName).classifiedFundingInSomeSDG
            },
            modalId: id,
            modalCountryVariable: countryVariable,
            modalGhostVariable: ghostVariable,
            modalSortingVariable: sortingVariable
        });
    }

    handleCloseModalCountry() {
        this.setState({
            showModalCountry: false
        });
    };

    componentDidMount() {

        let that = this;

        var main = d3.select('main')

        // PLOTS
        var scrolly = main.select('#scrolly');
        // var figure = scrolly.select('figure');
        var article = scrolly.select('article');
        var step = article.selectAll('.step');

        // STREAM
        var scrollyStream = main.select('#scrollyStream');
        // var figure = scrolly.select('figure');
        var articleStream = scrollyStream.select('article');
        var stepStream = articleStream.selectAll('.step');

        // initialize the scrollama
        var scroller = scrollama();
        var scrollerStream = scrollama();

        // generic window resize listener event
        function handleResize() {
            // update height of step elements
            // var stepH = Math.floor(window.innerHeight * 0.75);
            // var stepHStream = Math.floor(window.innerHeight);
            // step.style('height', stepH + 'px');
            // stepStream.style('height', stepHStream + 'px');

            // tell scrollama to update new element dimensions
            scroller.resize();
            scrollerStream.resize();
        }

        // scrollama event handlers
        function handleStepEnter(response) {
            // add color to current step only
            step.classed('is-active', function (d, i) {
                return i === response.index;
            })

            // update graphic based on step
            that.setState({ step: response.index })
        }


        function handleStepEnterStream(response) {
            // add color to current step only
            stepStream.classed('is-active', function (d, i) {
                return i === response.index;
            })

            // update graphic based on step
            that.setState({ stepStream: response.index })
        }

        function setupStickyfill() {
            d3.selectAll('.sticky').each(function () {
                Stickyfill.add(this);
            });
        }

        function init() {
            setupStickyfill();

            // 1. force a resize on load to ensure proper dimensions are sent to scrollama
            handleResize();

            // 2. setup the scroller passing options
            // 		this will also initialize trigger observations
            // 3. bind scrollama event handlers (this can be chained like below)
            scroller.setup({
                step: '#scrolly article .step',
                offset: 0.33,
                debug: false,
                progress: false,
                order: true
            }).onStepEnter(handleStepEnter);

            scrollerStream.setup({
                step: '#scrollyStream article .step',
                offset: 0.33,
                debug: false,
                progress: false,
                order: true
            }).onStepEnter(handleStepEnterStream);


            // setup resize event
            window.addEventListener('resize', handleResize);
        }

        // kick things off
        init();
    }

    toggleClass() {
        const currentState = this.state.activeMenu;
        this.setState({ activeMenu: !currentState });
    }

    scrollToTop() {
        scroll.scrollToTop();
    }

    createTinyCountry(label, id, data, countryVariable, ghostVariable, sortingVariable) {
        if(document.querySelector('.tinyCountry') === null)
            return;
    
        const w = document.querySelector('.tinyCountry').offsetWidth,
            h = document.querySelector('.tinyCountry').offsetHeight;

        this.tinyCountryPlot = new CountryPlotGenerator(
            '.tinyCountrySVG',
            '',
            id + '-tiny',
            {
                width: w * 1.2,
                height: h * 1.2,
                x: w / 2,
                y: h / 2
            },
            data,
            countryVariable,
            ghostVariable,
            sortingVariable
        );
        this.tinyCountryPlot.fold(false);
        this.tinyCountryPlot.show();
    };



    render() {

        const { 
            step, 
            activeVariable, 
            modalTitle, 
            modalIndicators,
            modalData,
            modalId,
            modalCountryVariable,
            modalGhostVariable,
            modalSortingVariable
        } = this.state;

        const self = this;

        return <main className="w-100">

            <nav className={`navbar jump-navigation p-0 ${this.state.activeMenu ? 'unfolded' : ''} `}>
                <button className="jump-navigation_button jump-navigation_button-toggle" onClick={this.toggleClass}>
                    <IoMdClose className="opened" color="#97aabb" />
                    <IoIosMenu className="closed" color="#97aabb" />
                </button>

                {/* <button className="jump-navigation_button jump-navigation_button-top" onClick={this.scrollToTop}>
                    <IoMdArrowUp color="#97aabb" />
                </button> */}

                <ul className="nav flex-column">
                    <li className="nav-item"><Link activeClass="active" className="nav-link" to="introduction" spy={true} smooth={true} >Introduction</Link></li>
                    <li className="nav-item"><Link activeClass="active" className="nav-link" to="evolution" spy={true} smooth={true} >Annual share of SDG-related funded projects</Link></li>
                    <li className="nav-item"><Link activeClass="active" className="nav-link" to="relationships" spy={true} smooth={true} >How do countries specialise their R+I on the SDG?</Link></li>
                    <li className="nav-item"><Link activeClass="active" className="nav-link" to="tsne" offset={200} spy={true} smooth={true} >How do countries group according to their R+I on SDGs?</Link></li>
                    <li className="nav-item"><Link activeClass="active" className="nav-link" to="ternary" offset={200} spy={true} smooth={true} >How do countries distribute research between Economy, Society and Biosphere?</Link></li>
                    <li className="nav-item"><Link activeClass="active" className="nav-link" to="stream" spy={true} smooth={true} >How do R+I interests evolve?</Link></li>
                    <li className="nav-item"><Link activeClass="active" className="nav-link" to="methodology" spy={true} smooth={true}>Methodology / Background</Link></li>
                </ul>
            </nav>

            <Element name="introduction" className="anchor">
                <Header canvasId={'canvas-header'} />
                <section id="home" className="container-fluid py-5 bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <p>On this website, we present an application of this methodology: <strong>we have classified the textual descriptions of all research and innovation (R&I) projects</strong> financed by the European Commission under the <strong>7th Framework Programme</strong> (FP7) and <strong><a href='https://ec.europa.eu/programmes/horizon2020/en' target='_blank' rel="noopener noreferrer">Horizon 2020</a></strong> (H2020) into the SDGs, and developed a set of visualisations exploring the temporal evolution and the country specialisation of SDG-related efforts.</p>
                            </div>
                            <div className="col-12 col-md-6">
                                <img src="/images/sdg-hero-800x800.png" style={{width: '100%'}}></img>                                
                            </div>
                        </div>
                    </div>
                </section>

                <section className="container-fluid py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <p>Behind the data there are collaborative projects by actors in Europe and abroad, such as <a href='https://cordis.europa.eu/project/id/727450' target='_blank' rel="noopener noreferrer"><strong>WATERPROTECT</strong></a>, which “contributes to effective uptake and realisation of management practices and mitigation measures to protect drinking water resources” in rural and urban environments, or <a href='https://cordis.europa.eu/project/id/645668' target='_blank' rel="noopener noreferrer"><strong>SALEACOM</strong></a>, which proposes “an innovative education for a new century with, with a global scope, to address educational and social exclusion in schools and learning communities.”</p>
                            </div>                            
                        </div>
                    </div>
                </section>
            </Element>
            
            <Element name="evolution" className="anchor">
                <section id="intro" className="container-fluid px-0 bg-white">
                    {<Introduction isMobile={this.props.isMobile} />}
                </section>
            </Element>

            {   modalTitle && 
                !this.props.isMobile && 
                <Modal show={this.state.showModalCountry}
                    onHide={this.handleCloseModalCountry}
                    dialogClassName={'modal-country modal-90w'}
                    animation={false}>
                    <Modal.Body>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-2 stats-container">   
                                    <h4>{modalTitle.toUpperCase()}</h4>
                                    <div className="tinyCountry" style={{width: '100%', display:'block', height:'200px'}}>
                                        <svg className="tinyCountrySVG" height="100%" width="100%">
                                            {
                                                setTimeout(() => {
                                                    this.createTinyCountry(
                                                        modalTitle, 
                                                        modalId, 
                                                        modalData, 
                                                        modalCountryVariable, 
                                                        modalGhostVariable, 
                                                        modalSortingVariable
                                                    );
                                                }, 300)
                                            }
                                        </svg>
                                    </div>     
                                    <div className="caption">
                                        <strong>How are its R&I projects related to the SDGs?</strong> The plot on the right shows the top 10 most recurrent SDG keywords
                                    </div>                               
                                    <p style={{borderTop: "1px solid whitesmoke", paddingTop: '5px'}}>Total projects:</p>
                                    <h5>{
                                        modalIndicators.totalProjectsCountry < 1000 ? 
                                            modalIndicators.totalProjectsCountry :
                                            d3.format(".3s")(modalIndicators.totalProjectsCountry)
                                    }</h5>
                                    <p>Total SDG-related projects:</p>
                                    <h5>{
                                        modalIndicators.totalProjectsCountrySDG < 1000 ?
                                        modalIndicators.totalProjectsCountrySDG :
                                        d3.format(".3s")(modalIndicators.totalProjectsCountrySDG)
                                    }</h5>
                                    <p>Total funding:</p>
                                    <h5>€{d3.format(",.0f")(modalIndicators.totalFundingCountry)}</h5>
                                    <p>TOTAL funding in SDG-related projects</p>
                                    <h5>€{d3.format(",.0f")(modalIndicators.totalFundingCountrySDG)}</h5>                                    
                                </div>
                                <div className="col-10 treemap-container">
                                    <SDGTreemap country={modalTitle}></SDGTreemap>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            }           
            
            <div className="divider divider-grey2white"/>

            <div className='container-fluid title-section' >
                <div className="row justify-content-md-center">
                    <div className=" align-self-center col-12 col-lg-8 text-center">
                        <h1 className="mt-5 mb-3">
                            “Hot topics”: which keywords have gained more presence in the descriptions of R&I projects?
                        </h1>
                        <p style={{margin: "1rem 10%"}}>
                        This word cloud presents the most distinctive and frequent words in the project’s titles and objectives. The color palette represents words that are relatively more (less) frequent in Horizon 2020 versus the 7th Framework Programme.
                        </p>
                        <p style={{margin: "1rem 10% 0"}}>
                            <span className="highlight instruction">Click on any keyword</span> for more information about the associated SDGs and their most frequent keywords.
                        </p>
                    </div>                                        
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 text-center">
                        <VegaChart
                            id={"keywords"}
                            chartClass={'keywords-container'}
                            spec={keywordsIncrements}
                            data={keywordsIncrementsData.map(o => {
                                o.keyword = _.capitalize(o.keyword);
                                return o;
                            })}
                            width={this.props.keywordsBreakpoint && 900}
                            height={600}
                            runAfterCallback={() => {
                                const svg = d3.select('.keywords-container svg');                                                                
                                svg.selectAll('.nodes-texts text')
                                    .style('fill', function(d) {
                                        return d3.color(
                                            d3.select(this).style('fill')
                                        ).darker(1).formatHex();
                                    })
                                    .style('cursor', 'pointer')
                                    .on('click', function(d) {
                                        const me = d3.select(this);
                                        let keyword = [];
                                        if(me.selectAll('tspan').size() > 0)
                                            me.selectAll('tspan').each( function() {
                                                keyword.push(d3.select(this).text());
                                            })
                                        else
                                            keyword.push(me.text());
    
                                        self.handleOpenModalKeyword(
                                            keyword.join(" ").toLowerCase()
                                        );
                                    })
                                    .on('mouseover', function(d) {
                                        d3.select(this)
                                            .style('text-shadow', '1px 1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, -1px -1px 0 #fff');
                                        d3.select(this.parentNode.parentNode).select('path')
                                            .style('stroke-width', 2)
                                            .style('stroke-dasharray', 'none')
                                    })
                                    .on('mouseout', function(d) {
                                        d3.select(this)
                                            .style('text-shadow', 'none');
                                        d3.select(this.parentNode.parentNode).select('path')
                                            .style('stroke-width', 1)
                                            .style('stroke-dasharray', '1,1')
                                    })
                                    
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className="divider divider-white2grey"/>

            <div className='container-fluid title-section bg-light' >
                <div className="row justify-content-md-center">
                    <div className=" align-self-center col-12 col-lg-8 text-center">
                        <h1 className="mt-5 mb-3">
                            How do different countries specialise their research and innovation on the SDG?
                        </h1>
                    </div>
                </div>
            </div>


            <section id="scrolly" className="scrolly scrolly-dots container-fluid bg-light">

                <figure className="scrolly-wrapper">
                    <CountryPlots
                        ref={this.myCountryPlots}
                        step={step}
                        countryVariable={variableGroup[activeVariable].country}
                        ghostVariable={variableGroup[activeVariable].ghost}
                        sortingVariable={variableGroup[activeVariable].sorting}
                        openModal={this.handleOpenModal}
                        closeModal={this.handleCloseModal}
                        isOpen={this.state.showModal}
                        handleOpenModalCountry={this.handleOpenModalCountry}
                        isMobile={this.props.isMobile}
                    ></CountryPlots>
                </figure>

                <article className="scrolly_tracker">

                    <Element name="relationships" className="anchor">
                        <div className="step is-active" data-scrollama-index="0">
                            <p style={this.props.isMobile ? { marginTop: '50vh' } : {}} className="pt-0 pt-sm-5">This visualization provides two measures describing the <strong>pattern of activity of countries across the SDGs</strong>. <br/><br/>The size of each bar corresponds to the percentage of projects related to a given SDG for that country. <br/>Inside the bar there is a scale depicting the Specialization Index (SI), also known as <a href="https://en.wikipedia.org/wiki/Economic_base_analysis" target='_blank' rel="noopener noreferrer">location quotient</a>, a measure of the distribution of projects across SDGs in the country in comparison to the general distribution for all countries.</p>
                            <p>The inner bar of the SDG bar represents the SI score, as follows:</p>
                            <LegendSDG/>
                        </div>
                        <div className="step" data-scrollama-index="1" style={this.props.isMobile ? { opacity: 0, zIndex: 0 } : {}}>
                            <p className="d-none d-sm-block">To the right of the selected country, the smaller plots show the 4 countries featuring the most similar specialization pattern  in terms of SDG.</p>
                            <p style={this.props.isMobile ? { pointerEvents: 'none' } : {}}>SDGs in this plot are organized in three spheres: those pertinent to the <strong>Economy</strong> only, to <strong>Society</strong> as a whole and to the “all-encompassing” <strong>Biosphere</strong>, as proposed by the <a href='https://www.stockholmresilience.org/SDG2016' target='_blank' rel="noopener noreferrer">Stockholm Resilience Center</a>.</p>
                            <p style={this.props.isMobile ? { pointerEvents: 'none' } : {}}>In this framework, all initiatives supporting the Economy must be consistent with societal needs, and both with sustainability in the Biosphere.</p>
                            <p style={this.props.isMobile ? { pointerEvents: 'none' } : {}}><span className="highlight instruction">Click on the <strong>(+) sign</strong></span> of a country for more detailed information on the projects and specialisation of the country.</p>
                        </div>
                        <div className="step" data-scrollama-index="2">
                            <h3 className="d-none d-sm-block">What characteristic specialisation patterns can we find?</h3>
                            <p style={this.props.isMobile ? { marginTop: '-80vh' } : {}}><span className="highlight instruction">Click on the following countries</span>, to show that <span onClick={() => this.myCountryPlots.current.handleChange({ label: 'Portugal' })} className='highlight sdg-9'>Portugal</span>, <span onClick={() => this.myCountryPlots.current.handleChange({ label: 'Ireland' })} className='highlight sdg-9'>Ireland</span> and <span onClick={() => this.myCountryPlots.current.handleChange({ label: 'Romania' })} className='highlight sdg-9'>Romania</span> are highly specialised in SDG 9 Industry, Innovation and Infrastructure.</p>
                            <p style={this.props.isMobile ? { marginTop: '50vh' } : {}}><span onClick={() => this.myCountryPlots.current.handleChange({ label: 'Croatia' })} className='highlight sdg-11'>Croatia</span> is highly specialised in SDG 11 Sustainable Cities and Communities.</p>
                            <p style={this.props.isMobile ? { marginTop: '50vh' } : {}}><span onClick={() => this.myCountryPlots.current.handleChange({ label: 'Norway' })} className='highlight sdg-14'>Norway</span> is highly specialised in SDG 14 Life Below Water.</p>
                        </div>
                    </Element>

                    <Element name="tsne" className="anchor">
                        <div className="step" data-scrollama-index="3">
                            <h3 className="" style={this.props.isMobile ? { marginTop: '15vh' } : {}}>Can we group countries according to their research and innovation portfolio on SDGs? </h3>
                            <p className="d-none d-sm-block">The research and innovation of each country is distributed across the 16 SDGs.<br />This means that each country can be represented as a 16-dimensional vector.</p>
                            <p className="d-none d-sm-block">Here we use a popular dimensionality reduction technique called <a href='http://www.jmlr.org/papers/volume9/vandermaaten08a/vandermaaten08a.pdf' target="_blank" rel="noopener noreferrer">t-sne</a> to represent those vectors into a 2-dimensional space.</p>
                            <p style={this.props.isMobile ? { marginTop: '75vh' } : {}}>In this representation, <strong>each point is a country and closer points have similar SDG-related research portfolios.</strong></p>
                        </div>
                        <div className="step" data-scrollama-index="4" style={this.props.isMobile ? { opacity: 0, zIndex: 0 } : {}}>
                            <p>Not all countries have equal access to the European Commission Funding.</p>
                            <p>For this reason, European Union members and <a href="https://ec.europa.eu/research/iscp/index.cfm?pg=associated" target="_blank" rel="noopener noreferrer">Associated countries</a> have more projects, and they cluster closely, while third countries are scattered across the plot, with more distinctive research and innovation portfolio.</p>
                            <p><span className="highlight instruction">Use the stripes graph</span>(<img src="images/stripes.png" style={{ maxHeight: '14px', display: 'inline' }} alt="Range stripes" />),  representing the country distribution based on number of projects, to select/mark subsets of data in the graph.</p>
                            <p><span className="highlight instruction">Click</span> on a country point to reveal more data about the country.</p>
                        </div>
                        <div className="step" data-scrollama-index="5">
                            <p>
                                The larger EU countries (<strong>Germany</strong>, <strong>France</strong>, <strong>UK</strong>, <strong>Italy</strong>, <strong>Netherlands</strong>, <strong>Spain</strong>…) cluster together, with a similar, evenly distributed, portfolio of activities.
                            </p>
                            <p>
                                Smaller countries in the EU have a more distinctive pattern of specialisation, with many of them focusing on GOAL 9 Industry, Innovation and Infrastructure.
                            </p>
                        </div>
                        <div className="step" data-scrollama-index="6">
                            <p style={this.props.isMobile ? { marginTop: '25vh' } : {}}>In the middle range of number of projects (countries with 100 to 1000 projects), we see countries with a more specialised pattern of activity across the Goals.</p>
                        </div>
                        <div className="step" data-scrollama-index="7">
                            <p style={this.props.isMobile ? { marginTop: '50vh' } : {}}>In the range with the lower number of projects (countries with 1 to 100 projects), we see the most extreme specialisation and collaboration patterns. Countries orbit in the perimeter of the distributions according to their similarity</p>
                        </div>
                    </Element>

                    <Element name="ternary" className="anchor">
                        <div className="step" data-scrollama-index="8">
                            <h3 className="d-none d-sm-block">How do countries distribute research between Economy, Society and Biosphere related goals?</h3>
                            <p>SDG distributions can be summarised in a three-dimensional space spanning the following three spheres of impact: <strong>Economy only, Society as a whole, Biosphere in general</strong>.</p>
                            <p className="d-none d-sm-block">But since distributions are normalised, each country actually lies in a two-dimensional plane called <a href='https://en.wikipedia.org/wiki/Simplex' target='_blank' rel="noopener noreferrer">simplex</a>.
                            </p>
                            <p>
                                The plot on the left shows the distribution of countries across this plane.
                            </p>
                        </div>
                        <div className="step" data-scrollama-index="9">
                            <p className="d-none d-sm-block">Many of the patterns observed and highlighted in the previous visualisation become apparent here.
                            </p>
                            <p>
                                Feel free to explore how countries specialise and collaborate in research and innovation!
                            </p>
                            <p>
                                <span className="highlight instruction">Click on any country</span> for more information on the projects of the country.
                            </p>
                        </div>
                    </Element>
                </article>
            </section>

            <Modal show={this.state.showModalKeyword}
                onHide={this.handleCloseModalKeyword}
                dialogClassName={'modal-keyword ' + this.state.modalKeywordClassName}
                animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.state.clickedKeyword}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="subheader">appears in the following SDGs:</p>
                    <KeywordData keyword={this.state.clickedKeyword} sdgLabels={this.state.modalSDGLabels} />
                </Modal.Body>
            </Modal>

            <div className="divider divider-grey2white"/>

            <Element name="stream" className="anchor">
                <div className='container-fluid title-section bg-white' >
                    <div className="row justify-content-md-center">
                        <div className=" align-self-center col-12 col-lg-8 text-center">
                            <h1>How do research and innovation interests evolve?</h1>
                        </div>
                        <div className=" align-self-center col-12 col-lg-8 text-center">
                            <p style={{margin: "1rem 10%"}}>
                                This plot presents the evolution of the most frequent SDG-related words found in the titles and descriptions of all the projects, organised by Goal. This provides a view of the specific topics of interest of the policy instruments and of the funded projects, and how these terms evolve through time.
                            </p>
                            <p style={{margin: "1rem 10%"}}>
                                <span className="highlight instruction">Click on any keyword</span> for more information about the associated SDGs and their most frequent keywords.
                            </p>
                        </div>
                    </div>
                </div>
                 <StreamRiver handleOpenModalKeyword={this.handleOpenModalKeyword} />
            </Element>

            <div className="divider divider-white2grey" style={{marginTop: '5vh'}}/>

            <Element name="methodology" className="anchor">
                <Header canvasId={'canvas-footer'} direction='up' />
            </Element>

            <div className="divider divider-grey2white"/>

            <div className="container" style={{marginBottom: "5vh"}}>
                <div className="row justify-content-md-center">
                    <div className="align-self-center col-12">
                        <h1>Background</h1>
                        <p>
                            The United Nations General Assembly ratified in 2015 the <a href="http://www.un.org/ga/search/view_doc.asp?symbol=A/RES/70/1&Lang=E" target="_blank" rel="noopener noreferrer">2030 Agenda for Sustainable Development</a>, as a plan for “action to end poverty, protect the planet and improve the lives and prospects of everyone, everywhere”. The Agenda outlines 17 <a href="https://www.un.org/sustainabledevelopment/sustainable-development-goals/" target="_blank" rel="noopener noreferrer">Sustainable Development Goals (SDGs)</a>, conceived to be a "blueprint to achieve a better and more sustainable future for all”. The Goals concern all continents and societies, they concern all of us and the new generations to come.
                        </p>
                        <p>                                        
                            In this context, the United Nations Sustainable Development Summit in September 2015, <a href="https://sustainabledevelopment.un.org/content/documents/19009STI_Roadmap_Background_Paper_pre_STI_Forum_Final_Draft.pdf" target="_blank" rel="noopener noreferrer">“positioned Science, Technology and Innovation (STI) as key means of implementation of the SDGs”</a>, and is developing a policy framework for the development of <a href="https://sustainabledevelopment.un.org/partnership/?p=33852" target="_blank" rel="noopener noreferrer">STI for SDGs Roadmaps</a> (click <a href="https://sdg.iisd.org/commentary/policy-briefs/sdg-knowledge-weekly-science-technology-and-innovation-roadmaps-for-the-sdgs/" target="_blank" rel="noopener noreferrer">here</a> for more information).  
                        </p>
                        <p>
                            In the European Union, the <a href="https://ec.europa.eu/commission/publications/reflection-paper-towards-sustainable-europe-2030_en" target="_blank" rel="noopener noreferrer">Agenda 2030 is preeminent in the strategic framework</a> guiding the next programming period; most or all policy areas are impacted and necessary to achieve the desired progress towards the goals. Particularly, in the new Research and Innovation Framework Programme of the European Commission, Horizon Europe, the SDGs set the stage for “Clusters in the Global Challenges and Industrial Competitiveness pillar” and the research and innovation Missions, amongst other policy elements.
                        </p>
                        <p>
                            In this context, we have participated in projects aimed at mapping the relationship between science and innovation activities and the SDGs, led by higher education and research institutions, public administrations and sectoral or disciplinary organisations. An exciting example is <a href="http://ris3mcat.gencat.cat/#/" target="_blank" rel="noopener noreferrer">RIS3-MCAT</a>, the Catalan smart specialisation monitoring platform, developed by the <a href="http://catalunya2020.gencat.cat/en/inici/index.html" target="_blank" rel="noopener noreferrer">Generalitat de Catalunya</a>, which allows users to explore publicly-funded research and innovation projects related to each SDG (using the filter “ODS”, Objectius de desenvolupament sostenible). Find <a href="https://s3platform.jrc.ec.europa.eu/sustainable-development-goals" target="_blank" rel="noopener noreferrer">here</a> more information about Smart Specialisation for Sustainable Development Goals.
                        </p>
                        <hr/>
                        <p>
                            <a href="http://www.sirisacademic.com/" target='_blank' rel="noopener noreferrer">SIRIS Academic</a><br/>
                            info@sirisacademic.com
                            <br/>
                            © SIRIS Academic, 2020                    
                        </p>  
                    </div>
                </div>
            </div>
        </main>
    }
}

const mapSizesToProps = ({ width }) => ({
    isMobile: width < 768,
    keywordsBreakpoint: width <= 900
})

export default withSizes(mapSizesToProps)(Home);