import React, { Component } from 'react';
import * as d3 from "d3";
import * as _ from 'lodash';
import Select from 'react-select';
import { IoIosAddCircle } from "react-icons/io";
import {
    SDG_INFO,
    SELECTED_COUNTRIES,
    REFS,
    EMPTY_OBJECT,
    IS_STATIC,
    setAverageBySDGPillars,
    CONTINENT_COLORS
} from '../../common';

import related_countries_data from '../../data/flower/country_similarity.json'
import sdg_by_country_data from '../../data/flower/3-SDGs_by_country.json'
import CountryPlotGenerator from '../../components/CountryPlotGenerator'

import tsnePlot from '../../components/tsne/tsnePlot';
import {
    getStaticPositions,
    pickPlotsForStaticLayout,
    getCountryHoverListener,
    showStaticPlaceholders
} from './countriesLayoutUtils';
import { decidePhase, STATIC, TSNE, TERNARY } from './statemachine';
import ternaryPlot from '../../components/ternayPlot/TernaryPlot';
import VegaChart from '../../common/vega/VegaChart';
import VegaSignalListener from '../../common/vega/VegaSignalListener';
import { VegaSpecStripPlot } from '../../common/vega/specifications/VegaSpecStripPlot';
import textures from 'textures';
import { fisheye } from '../../common/fisheye';
import * as d3Legend from 'd3-svg-legend';

const initialCountry = 'Spain';
const countrySelectOptions = _(sdg_by_country_data)
    .uniqBy('countryName')
    .sortBy('countryName')
    .map(o => { return {label: o.countryName};})
    .value()


class CountryPlots extends Component {

    constructor() {
        super();
        this.state = {
            mockData: [],
            // all the country plots
            plots: [],
            // country plots being show in the STATIC phase
            staticPlots: [],
            phase: 'unset',
            ternaryPlot: ternaryPlot

        };
        this.handleOpenCountry = this.handleOpenCountry.bind(this);

        // state not trackable
        this.initialBrushSignal = undefined;
        this.selectedOption = null;
        this.brushDomain = null;
        this.vegaView = undefined;
        this.tinyCountryPlot = undefined;
    }


    /**
     * Show and place the indicated plots in the
     * static positions of the layout. By convention
     * the first plot goes to the position of the 
     * selected plot and the rest in the positions
     * of 'similar' plots
     * @param {*} plots 
     */
    showStaticPlots(_plots) {
        // if no plots, take the ones in the state
        const plots = _plots || this.state.staticPlots,
            layout = this.state.fixedLayoutData;

        // move first plot to primary position
        _.first(plots)
            .moveTo(
                layout[REFS.PRIMARY_COUNTRY].x,
                layout[REFS.PRIMARY_COUNTRY].y
            )
            .setPlaceholderInfo(layout[REFS.PRIMARY_COUNTRY]);
        // move the rest of plots to secondary positions
        _.tail(plots)
            .forEach((plot, i) => {
                plot.moveTo(
                    layout[REFS['SECONDARY_COUNTRY_' + i]].x,
                    layout[REFS['SECONDARY_COUNTRY_' + i]].y
                )
                .setPlaceholderInfo(layout[REFS['SECONDARY_COUNTRY_' + i]]);
            });

        // change its state to unfold and visible
        plots.forEach(plot => {
            plot.fold(false);
            plot.show();
        });

        // update the signal of the initial brush on the vega view,
        // so we preselect a range of projects around the 
        // totalCountryProjects of the selected country
        this.initialBrushSignal = _.find(
            VegaSpecStripPlot.signals,
            signal => signal.name === 'initialBrush'
        );

        if (!_.isUndefined(this.initialBrushSignal)) {
            this.vegaView.signal(
                this.initialBrushSignal.name,
                _.first(plots).data[0].totalProjectsCountry
            );
            this.vegaView.runAsync();
        }
    }

    // optimize when to re-render (basically never!)
    shouldComponentUpdate(nextProps) {
        // just update the state machine and do stuff, 
        // but DO NOT render again
        if (this.props.step !== nextProps.step)
            this.updateState(nextProps.step)
        return false;
    };

    updateControls(phase) {
        if (phase === STATIC) {
            this.refs['strip-plot'].style.display = 'none'
            this.refs['country-selector'].style.display = 'block';
            if (!this.props.isMobile) {
                this.refs['show-modal-btn'].style.display = 'block'
            }
        } else {
            this.refs['strip-plot'].style.display = 'block'
            this.refs['country-selector'].style.display = 'none';
            if (!this.props.isMobile) {
                this.refs['show-modal-btn'].style.display = 'none';
            }
        }
    }

    createTinyCountry(label, id, data, countryVariable, ghostVariable, sortingVariable) {
        d3.select('.svg-placeholder-countries').append("foreignObject").classed("chart-box", true)
            .attr("x", document.querySelector('.static-plots-container').offsetWidth - 200)
            .attr("y", 0)
            .attr("width", 200)
            .attr("height", 250)
            .append("xhtml:div")
            .classed("chart-box_decorated", true)

        this.tinyCountryPlot = new CountryPlotGenerator(
            '.svg-placeholder-countries',
            label,
            id + '-hovered',
            {
                width: 300,
                height: 300,
                x: document.querySelector('.static-plots-container').offsetWidth - 100,
                y: 150
            },
            data,
            countryVariable,
            ghostVariable,
            sortingVariable
        );
        this.tinyCountryPlot.fold(false);
        this.tinyCountryPlot.show();
    };

    destroyTinyCountry() {
        d3.select('.svg-placeholder-countries')
            .select('#' + this.tinyCountryPlot.id)
            .remove();
        d3.selectAll('.chart-box').remove()
    }

    createFishEye(phase) {
        var self = this;

        this.fisheye = fisheye.circular()
            .radius(100)
            
        var svg = d3.select('.svg-placeholder-countries');

        const width = svg.attr("width"),
            height = svg.attr("height");

        var dotGroups = svg.selectAll('.country-placeholder');

        var distortionScale = d3.scaleQuantize()
                .domain([0, 90, 1000])
                .range([3, 2, 1])                    

        svg.on("mousemove", function () {
            
            let distance = Math.hypot(d3.mouse(this)[0]-width*0.5, d3.mouse(this)[1]-height*0.75)
            self.fisheye.focus(d3.mouse(this));
            self.fisheye.distortion(distortionScale(distance))


            dotGroups.each(function (d) {
                d.fisheye = self.fisheye(d.ternaryCoords);
                d3.select(this)
                    .select('.dotGroup')
                    .attr('transform', 'scale(1)');
            })
                .attr("x", function (d) { return d.fisheye.x; })
                .attr("y", function (d) { return d.fisheye.y; });
        })
            .on('mouseleave', function () {
                dotGroups
                    .attr("x", function (d) { return d.ternaryCoords.x; })
                    .attr("y", function (d) { return d.ternaryCoords.y; });
            });
    }



    createLegendContinents() {
        var svg = d3.select('.svg-placeholder-countries');
        var ordinal = d3.scaleOrdinal()
            .domain(_.keys(CONTINENT_COLORS))
            .range(_.values(CONTINENT_COLORS));
        svg.append("g")
            .attr("class", "legendOrdinal")
            .attr("transform", "translate(5, 15)");
        var legendOrdinal = d3Legend.legendColor()
            .shape("circle")
            .shapeRadius(4)
            .shapePadding(3)            
            .scale(ordinal);
          
          svg.select(".legendOrdinal")
            .call(legendOrdinal);
    }



    destroyLegendContinents() {
        d3.select('.svg-placeholder-countries')
            .select('g.legendOrdinal').remove();
    }



    createCartesian(phase) {

        var svg = d3.select('.svg-placeholder-countries')

        const width = svg.attr("width"),
            height = svg.attr("height");

        const xFisheye = fisheye.scale(d3.scaleIdentity).domain([0, width]).focus(0),
            yFisheye = fisheye.scale(d3.scaleIdentity).domain([0, height]).focus(0);

        var dotGroups = svg.selectAll('.country-placeholder');

        svg.on("mousemove", function () {
            const mouse = d3.mouse(this);
            xFisheye.focus(mouse[0]);
            yFisheye.focus(mouse[1]);
            dotGroups.each(function (d) {
                d.xFisheye = xFisheye(d.svgCoords.x);
                d.yFisheye = yFisheye(d.svgCoords.y);
            })
                .attr("x", function (d) { return d.xFisheye; })
                .attr("y", function (d) { return d.yFisheye; });
        })
            .on('mouseleave', function () {
                dotGroups
                    .attr("x", function (d) { return d.svgCoords.x; })
                    .attr("y", function (d) { return d.svgCoords.y; });
            });
    }

    destroyFishEye() {
        this.fisheye = undefined;
        d3.select('.svg-placeholder-countries').on('mousemove', null);
    }

    destroyCartesian() {
        this.fisheye = undefined;
        d3.select('.svg-placeholder-countries').on('mousemove', null);
        d3.select('.svg-placeholder-countries').on('mouseleave', null);
    }


    /**
     * 
     */
    updateState(step) {
        const { countryVariable, ghostVariable, sortingVariable, isMobile } = this.props;
        const self = this;
        // check active step of the article to define the
        // phase of the storytelling we head to, so we
        // decide whether we go forward or backward
        const phase = decidePhase(step)

        // check whether we have to update the phase,
        // so we have to do something with the stuff
        if (phase !== this.state.phase) {

            // decide what we have to do base
            // on the new phase
            this.updateControls(phase);
            switch (phase) {
                default: break;
                case STATIC:
                    // this.destroyFishEye();
                    this.destroyCartesian();
                    this.destroyLegendContinents();

                    // where I'm coming from?
                    if (this.state.phase === TSNE) {
                        // show the DOM placeholders for static plots
                        
                        showStaticPlaceholders();

                        //const layout = this.state.fixedLayoutData;

                        // clear static plots from brushing
                        _.each(this.state.plots, p => p.select(true))
                        this.showStaticPlots();
                        /*
                        // move first plot to primary position
                        _.first(this.state.staticPlots)
                            .fromTsne(IS_STATIC, layout[REFS.PRIMARY_COUNTRY]);

                        // move the rest of plots to secondary positions
                        _.tail(this.state.staticPlots)
                            .forEach((plot, i) => {
                                plot.fromTsne(IS_STATIC, layout[REFS['SECONDARY_COUNTRY_' + i]]);
                            });*/

                        _.differenceBy(
                            this.state.plots,
                            this.state.staticPlots,
                            'label'
                        ).forEach(plot => {
                            plot.fromTsne(!IS_STATIC);
                        })

                    }
                    else {
                        let plots = [];
                        // load the file with the data
                        // to be used for the t-sne
                        tsnePlot.loadData()
                            .then(function (data) {

                                // once the t-sne has calculated the positions,
                                // compute these positions according our svg space
                                tsnePlot.tsneCoordsToSVGCoords('.svg-placeholder-countries');

                                // tsnePlot.loadData returns data
                                // of the country with the coords
                                // of the t-sne. Link this data
                                // with our general dataset
                                let countries = self.state.mockData;
                                data.forEach(d => {
                                    countries[d.countryName].tsneCoords = d.tsneCoords
                                    countries[d.countryName].svgCoords = d.svgCoords
                                });

                                // add data in the country about total number
                                // of projects classified in SDGs                                
                                _.each(_.keys(countries), k => {
                                    countries[k].numProjectsSDG = _.sumBy(countries[k], 'numProjectsSDG')
                                });

                                // create a scale for the radius and label of the dots
                                // map arbitrary subsets of the domain to discrete values in the range
                                var radiusScale = d3.scaleLinear()
                                    .domain([1, 50, 100, 500, 1000, 5000, 10000])
                                    .range([3, 4, 5, 6, 7, 8, 11]);
                                var labelTextScale = d3.scaleLinear()
                                    .domain([1, 50, 100, 500, 1000, 5000, 10000])
                                    .range([7, 8, 10, 10, 12, 12, 14]);

                                // inject the radius for each country
                                _.each(_.keys(countries), k => {
                                    countries[k].dotRadius    = radiusScale(countries[k].numProjectsSDG);
                                    countries[k].dotLabelSize = labelTextScale(countries[k].numProjectsSDG);
                                });

                                // create all the countries
                                _.keys(countries).forEach(countryLabel => {
                                    plots.push(
                                        new CountryPlotGenerator(
                                            '.svg-placeholder-countries',
                                            countryLabel,
                                            countryLabel.toLowerCase().replace(/\s/g, ''),
                                            undefined,
                                            countries[countryLabel],
                                            countryVariable,
                                            ghostVariable,
                                            sortingVariable,
                                            // add callbacks
                                            [self.createTinyCountry, self.destroyTinyCountry, self.handleOpenCountry]
                                        )
                                    );
                                });

                                let staticPlots = pickPlotsForStaticLayout(plots, self.selectedOption, isMobile);
                                self.showStaticPlots(staticPlots);
                                // save country plots
                                self.setState({
                                    plots: plots,
                                    staticPlots: staticPlots
                                });
                            });
                    }
                    break;

                case TSNE:
                    // just hide the ternary plot
                    this.state.ternaryPlot.hide();
                    this.destroyFishEye();
                    this.createCartesian(phase);
                    this.createLegendContinents();

                    // where I'm coming from?
                    if (this.state.phase === STATIC) {
                        // hide the DOM placeholders for static plots
                        showStaticPlaceholders(false);

                        // current static plots: to dot
                        // and move to your tsne position
                        _.each(this.state.staticPlots, (plot, i) => plot.toTsne(IS_STATIC, i));

                        // rest of plots: show dot at its position already
                        _.differenceBy(
                            this.state.plots,
                            this.state.staticPlots,
                            'label'
                        ).forEach((plot, i) => {
                            plot.moveTo(
                                plot.data.svgCoords[0],
                                plot.data.svgCoords[1]
                            );
                            plot.toTsne(!IS_STATIC, i);
                        })

                        // update brushed countries
                        this.brushCountries();

                    }
                    else if (this.state.phase === TERNARY)
                        _.each(this.state.plots, (plot, i) => plot.fromTernary(i));
                    break;

                case TERNARY:
                    this.destroyCartesian();
                    this.createFishEye(phase);

                    // show the ternary plot
                    this.state.ternaryPlot.show();
                    // here we always come from tsne
                    _.each(this.state.plots, (plot, i) => plot.toTernary(i));
                    break;
            }

            // update the phase on the react state
            this.setState({
                phase: phase
            });
        }
        else {
            // consider also some changes
            // in the step within the same
            // phase
            if (step === 5) {
                // reseting the brush is not working...
                this.vegaView.signal(this.initialBrushSignal.name, null);
                this.vegaView.runAsync();
                // vega view signal listener will
                // get the update and clear the 
                // dots, so wait a bit to select ours
                setTimeout(() => {
                    let countriesHighlighted = ['Germany', 'France', 'United Kingdom', 'Italy', 'Netherlands', 'Spain']
                    _.each(
                        this.state.plots,
                        p => {
                            p.select(_.indexOf(countriesHighlighted, p.label) !== -1)
                        }
                    );
                }, 200);
            }
            else if (step === 6) {
                this.vegaView.signal(
                    this.initialBrushSignal.name,
                    500
                );
                this.vegaView.runAsync();
            }
            else if (step === 7) {
                this.vegaView.signal(
                    this.initialBrushSignal.name,
                    50
                );
                this.vegaView.runAsync();
            }
        }
    }

    componentDidMount() {
        // size the svg according our main container
        d3.select('.svg-placeholder-countries')
            .attr('width', this.refs.container.offsetWidth)
            .attr('height', this.refs.container.offsetHeight);

        //init the ternary plot
        this.state.ternaryPlot.init('.svg-placeholder-countries');
        this.state.ternaryPlot.draw();

        // we have node references of our countries, as follows:
        // primary-country, secondary-country-0, secondary-country-1
        // secondary-country-2 and secondary-country-3.
        // get the dimensions in order to calculate the final position
        // of the countries in the svg. Layout is:
        //  |  Primary  |   1   |   2   |
        //  |           |       |       |
        //  |  Country  |   3   |   4   |
        // and over this layout there is the <svg> with the same
        // dimensions as the div containing this layout
        const fixedLayoutData = getStaticPositions(this.refs);

        // add a listener to know which placeholder to highlight
        // Not possible via css or mouse events as we are overlapping
        // the placholders and the <svg>s     
        !this.props.isMobile && getCountryHoverListener(this, fixedLayoutData, REFS);

        // Sorting data by size value to help on dots mouseover
        sdg_by_country_data.sort((a, b) => parseFloat(b.totalProjectsCountry) - parseFloat(a.totalProjectsCountry));

        const countries = _.mapValues(
            _.groupBy(sdg_by_country_data, 'countryName'),
            clist => clist.map(country => _.omit(country, 'countryName'))
        )

        const allSDGs = _.keys(SDG_INFO);
        let control = 0;
        let globalSDGValues = [];
        _.keys(countries).forEach(k => {
            let country = countries[k];

            if (control === 0 && country.length === 16) {
                control = 1;
                country.forEach(SDG => {
                    globalSDGValues.push(
                        {
                            name: SDG.SDG,
                            p_totalNumCORDIS: SDG.p_totalNumCORDIS,
                            p_totalFundingCORDIS: SDG.p_totalFundingCORDIS
                        }
                    )
                });
            }

            // the countries may not have data about
            // all SDGs. Inject empty SDG data obj
            // for each SDG not present
            let mySDGs = _.map(country, 'SDG');
            _.difference(allSDGs, mySDGs)
                .forEach(missingSDG => {
                    // for each sdg not found in the country
                    // add an empty data SDG object
                    country.push(
                        _.merge(_.clone(EMPTY_OBJECT), { SDG: missingSDG })
                    );
                });

            // now country has all SDGs. Add some more properties
            // regards the SDGs (short titles, ordering, etc...)
            _.each(country, sdg => {
                sdg.sorting = SDG_INFO[sdg.SDG].order;
                sdg.shortTitle = SDG_INFO[sdg.SDG].shortTitle;
                sdg.ghostProjectsValue = _.find(globalSDGValues, function (o) { return o.name === sdg.SDG; });
            });
            // and sort SDGs
            country.sort((a, b) => (a.sorting > b.sorting) ? 1 : ((b.sorting > a.sorting) ? -1 : 0));
        });

        // get averages by SDG groups (economy, society, biosphere)
        setAverageBySDGPillars(countries);

        // get coordinates of each country
        // in the ternary plot
        _.keys(countries).forEach(
            k => countries[k].ternaryCoords = ternaryPlot.getCoord(countries[k])
        );

        // define initial state
        this.selectedOption = {
            label: initialCountry,
            related: this.getRelatedCountries({ label: initialCountry })
        };

        this.setState({
            mockData: countries,
            fixedLayoutData: fixedLayoutData
        });

        // set initial state in the state machine
        this.updateState(this.props.step)
    }

    /**
     * Get 4 most similar countries of the selectedOption
     *
     */
    getRelatedCountries = (selectedOption) => {
        return _.take(related_countries_data[selectedOption.label], 4)
            .map(o => { return { label: o }; });
    }

    handleChange = selectedOption => {
        const { plots, staticPlots } = this.state;
        const newOption = {
            label: selectedOption.label,
            related: this.props.isMobile ? [] : this.getRelatedCountries(selectedOption)
        };

        // hide current static plots
        staticPlots.forEach(plot => plot.show(false));

        const newStaticPlots = pickPlotsForStaticLayout(plots, newOption);
        this.showStaticPlots(newStaticPlots);

        this.selectedOption = newOption;
        this.setState({
            staticPlots: newStaticPlots
        });
    };

    /**
     * 
     */
    brushCountries() {
        if (_.isNull(this.brushDomain))
            _.each(this.state.plots, p => {
                // no brush selected, show all
                p.reset();
                p.show();
            });
        else {
            let isPlotBrushed = plot => _.inRange(
                plot.data[0].totalProjectsCountry,
                Math.floor(this.brushDomain[0]),
                Math.ceil(this.brushDomain[1]) + 1
            );
            _.each(
                this.state.plots,
                p => p.select(isPlotBrushed(p))
            );
        }
    };

    handleOpenCountry(countryInfo) {
        if(_.isUndefined(countryInfo))
            this.props.handleOpenModalCountry(
                this.state.staticPlots[this.staticCountryIndex].label,
                this.state.staticPlots[this.staticCountryIndex].data,
                this.state.staticPlots[this.staticCountryIndex].id,
                this.state.staticPlots[this.staticCountryIndex].countryVariable, 
                this.state.staticPlots[this.staticCountryIndex].ghostVariable, 
                this.state.staticPlots[this.staticCountryIndex].sortingVariable
            );
        else
            this.props.handleOpenModalCountry(
                countryInfo.label,
                countryInfo.data,
                countryInfo.id,
                countryInfo.countryVariable, 
                countryInfo.ghostVariable, 
                countryInfo.sortingVariable
            );
    }

    

    render() {
        const self = this;
        // define a vega signal listener for the brush in the stripPlot.
        // when no brush applied, show all countries otherwise show
        // countries with num of projects within the values of the brush
        let stripPlotBrushListener = new VegaSignalListener('detailDomain', (name, value, view) => {
            self.brushDomain = value;
            self.brushCountries();
        });

        return <div className="container static-plots-container" ref='container' style={{ position: 'relative' }}>

            <div className="row plots-grid position-relative d-flex align-items-start">
                <div ref='primary-country' className="col-12 col-sm-6 p-0 chart-container primary justify-content-md-center d-flex align-items-end">
                </div>
                <div className="col-12 col-sm-6 d-none d-sm-block">
                    <div ref='secondary-header' className="header-similar-countries row">
                        <div className="col-12">SIMILAR COUNTRIES</div>
                    </div>
                    <div className="row align-content-start">
                        {
                            SELECTED_COUNTRIES.slice(1).map((country, index) => {
                                return <div ref={"secondary-country-" + index} className="col-6 p-0 chart-container secondary" key={index} />
                            })
                        }
                    </div>
                </div>
                <div className='country-selector' ref='country-selector' style={{ display: 'none' }}>
                    <Select
                        value={this.selectedOption}
                        defaultValue={this.selectedOption}
                        placeholder={""}
                        onChange={this.handleChange}
                        options={countrySelectOptions}
                        className={"country-select-container"}
                        classNamePrefix={"country-select"}
                        isSearchable={false} />
                </div>
                { /* svg to contain all the multidimensional items */}
                <svg ref="svg-placeholder" style={{ position: 'absolute', top: 0, left: 0 }} className="svg-placeholder-countries" />

                {!this.props.isMobile && <button
                    ref="show-modal-btn"
                    onClick={() => this.handleOpenCountry()}
                    className="button-icon btn-open-modal">
                    <IoIosAddCircle color="#97aabb" />
                </button>}
            </div>
            <div className="row footer strip-plot mx-0" ref="strip-plot">
                <div className="col-12">
                    <p className="d-none d-sm-block">&darr; Apply a selection to highlight countries</p>
                    <VegaChart
                        chartClass="strip-plot"
                        spec={VegaSpecStripPlot}
                        height={75}
                        signalListeners={[stripPlotBrushListener]}
                        autosize={'pad' /* force to all content to be visible */}
                        runAfterCallback={vegaView => {
                            // save the view so we can update signals on it
                            self.vegaView = vegaView;

                            const texture = textures.circles()
                                .size(5)
                                .radius(1)
                                .fill("#fa8072");
                            // to add textures to the brush rectangle
                            const stripPlotSVG = d3.select('.strip-plot').select('svg');
                            stripPlotSVG.call(texture);
                            stripPlotSVG
                                .select('.mark-rect.role-mark.texture path')
                                .style('fill', texture.url());
                        }}
                    />
                </div>
            </div>


        </div>
    }
}

export default CountryPlots;