import React, { Component } from 'react';
import * as _ from 'lodash';
import * as d3 from 'd3';
import VegaChart from '../../common/vega/VegaChart';
import VegaSignalListener from '../../common/vega/VegaSignalListener';
import { areaChart, areaChartAll } from '../../common/vega/VegaSpecifications';
import textures from 'textures';
import { SDG_INFO } from '../../common'
import projectsData from '../../data/areacharts/sdgs_projects_by_year.json'
import scrollama from 'scrollama';
import AreaCharts from '../../components/AreaCharts'

var Stickyfill = require('stickyfill');

class Introduction extends Component {

    constructor() {
        super();
        this.state = {
            loading: true,
            fundingAreaChartValues: [],
            projectsAreaChartValues: []
        }
        this.vegaViews = [];

    }

    componentDidMount() {
        var scrolly = d3.select('#scrolly2');
        var figure = scrolly.select('figure');
        var figureHeight = !this.props.isMobile ? figure.node().getBoundingClientRect().height : 400;
        var article = scrolly.select('article');
        var step = article.selectAll('.step');


        // initialize the scrollama
        var scroller = scrollama();
        // generic window resize listener event
        function handleResize() {
            // update height of step elements
            step.style('height', function(d, i) {
                return Math.floor(window.innerHeight * (i === 0? 0.1 : 0.75)) + 'px';
            })
            scroller.resize();
        }

        // scrollama event handlers
        function handleStepEnter(response) {
            let showGraphs = (response.index === 0 || response.index === 4);

            d3.selectAll('.area-placeholder')
                .style('opacity', showGraphs? 1:0.15);
            
            d3.select('#scrolly2 article')
                .style('pointer-events', showGraphs? 'none':'auto');

            if(!showGraphs) {
                var sdgs;
                switch(response.index) {
                    case 1: sdgs = [0,1]; break;
                    case 2: sdgs = [11,12]; break;
                    case 3: sdgs = [4]; break;
                    default: break;
                }
                sdgs.forEach(i => d3.select('.area-placeholder.area-placeholder-'+i).style('opacity', 1));
            }
        }

        function setupStickyfill() {
            d3.selectAll('.sticky').each(function () {
                Stickyfill.add(this);
            });
        }

        function init() {
            setupStickyfill();
            handleResize();
            scroller.setup({
                step: '#scrolly2 article .step',
                offset: 0.66,
                debug: false,
                progress: false
            }).onStepEnter(handleStepEnter);

            window.addEventListener('resize', handleResize);
        }

        // kick things off
        !this.props.isMobile && init();

        // Data for SDGs evolution
        let allProjects = [];

        Object.entries(SDG_INFO).forEach(function (SDG) {

            // let singular = _.map(fundingData, function (entry) {
            let singular = _.map(projectsData, function (entry) {

                if (entry.SDG === SDG[0]) {
                    entry.color = SDG[1].color;
                    entry.title = SDG[1].title;
                    entry.shortTitle = SDG[1].shortTitle;
                    return entry
                }

            });
            singular = _.without(singular, undefined)
            allProjects.push(singular)

        })

        this.setState({
            projectsAreaChartValues: allProjects,
            areaChartHeight: figureHeight
        })
    }

    onVegaViewCreated = vegaView => {
        this.vegaViews = this.vegaViews.concat(vegaView);
    }

    render() {

        let hoverListener = new VegaSignalListener('symbolMouseover', (name, value, view) => {
            this.vegaViews.forEach(vegaView => {
                if (vegaView !== view) {
                    vegaView.signal('symbolHighlight', value);
                    vegaView.runAsync();
                }
            })
        });

        let that = this;
        const { projectsAreaChartValues, areaChartHeight } = this.state;

        return <div className="container-fluid">
            <div className="container intro-block">
                <div className="row">
                    <div className="col-12 col-md-5">
                        <p style={{margin: '2rem 0 0 0'}}>
                            The first noteworthy result of the analysis is that
                        </p>
                        <br/>
                        <h5>
                            the number of SDG-related projects has grown from 29% to 35%<sup>*</sup> between the two framework programmes (FP7 to H2020).
                        </h5>
                        <span className="note"><sup>*</sup>SDGs 8 and 9 excluded from the analysis.</span>
                    </div>
                    <div className="col-12 col-md-7 text-center">
                        <VegaChart
                            id={"area-chart-all-container"}
                            chartClass={'area-chart-all-container'}
                            spec={areaChartAll}
                            data={projectsData}
                            height={250}
                            onVegaViewCreated={that.onVegaViewCreated}
                            runAfterCallback={view => {
                                const t = textures.lines().size(4).strokeWidth(1).stroke('#19486A');
                                const svg = d3.select('.area-chart-all-container svg');
                                svg.call(t);

                                svg.select('g.mark-area path').style('fill', t.url());

                                svg.select('.mark-group.role-legend-entry .mark-group > g:last-child')
                                    .select('.role-legend-symbol path')
                                    .style('fill', t.url());
                            }}
                            signalListeners={[hoverListener]}
                        />
                    </div>
                </div>
            </div>

            <div className="divider divider-white2grey"/>
            <div className='container-fluid title-section bg-light' >
                <div className="row justify-content-md-center">
                    <div className=" align-self-center col-12 col-lg-8 text-center">
                        <h1 className="mt-5 mb-3">
                            Annual share of SDG-related funded projects, by goal
                        </h1>
                    </div>
                </div>
            </div>
            {this.props.isMobile ?
                <section className="d-block d-md-none container-fluid bg-light">
                    <div className="container">

                        <div className="row">
                            <div className="col">
                                <p>Some Goals have gathered more attention recently, in particular those addressing the most vulnerable, such <strong>No Poverty and  Zero Hunger</strong>.</p>
                                <AreaCharts areaChartValues={projectsAreaChartValues}
                                    excludedIndicators={[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p>And those addressing global environmental threats and a more sustainable development, like <strong>Climate action and Responsible Consumption and Production</strong></p>
                                <AreaCharts areaChartValues={projectsAreaChartValues}
                                    excludedIndicators={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 13, 14, 15]} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <p>Also <strong>Gender Equality</strong> is the object of a growing number of projects, and is also of great concern in the orientations and regulations of the whole funding programme.</p>
                                <AreaCharts areaChartValues={projectsAreaChartValues}
                                    excludedIndicators={[0, 1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]} />
                            </div>
                        </div>
                        <div className="row border-top pt-4 mt-4">
                            <div className="col">
                                <AreaCharts areaChartValues={projectsAreaChartValues}
                                    excludedIndicators={[0, 1, 4, 11, 12]} />
                            </div>
                        </div>
                    </div>
                </section>
                :
                <section id="scrolly2" className="scrolly scrolly-introduction container-fluid bg-light">
                    <figure>
                        <div className="container">
                            <div className="row">
                                {
                                    (projectsAreaChartValues.length > 0) && <div className="col justify-content-md-center">
                                        <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4">
                                            {
                                                projectsAreaChartValues.map(function (SDG, index) {
                                                    let sdgClass = _.replace(_.first(SDG)['SDG'], ' ', '');
                                                    let color = _.first(SDG).color;
                                                    return <div key={index} className={"col p-0 p-xl-1 area-placeholder area-placeholder-" + index}>
                                                        <div className={"w-100 h-100 d-flex flex-wrap area-icon pt-4 area-icon-" + index}>
                                                            <VegaChart
                                                                chartClass={'area w-100 mt-auto ' + sdgClass}
                                                                height={areaChartHeight / 8}
                                                                data={projectsAreaChartValues[index]}
                                                                spec={areaChart}
                                                                onVegaViewCreated={that.onVegaViewCreated}
                                                                signalListeners={[hoverListener]}
                                                                runAfterCallback={view => {
                                                                    const t = textures.lines().size(4).strokeWidth(1).stroke(color);
                                                                    const svg = d3.selectAll('.area.' + sdgClass + ' svg');
                                                                    svg.call(t);
                                                                    svg.selectAll('g.mark-area.role-mark path').style('fill', t.url());
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </figure>

                    <article>
                        <div className='step' data-step='0'>
                            <p style={{ display: 'none' }}></p>
                        </div>
                        <div className='step' data-step='1'>
                            <p>
                                Some Goals have gathered more attention recently, in particular those addressing the most vulnerable, such as <i><strong>Goal 1: End poverty in all its forms everywhere</strong> and <strong>Goal 2: Zero Hunger</strong></i>.
                            </p>
                        </div>
                        <div className='step' data-step='2'>
                            <p>
                                In parallel, those addressing global environmental threats and a more sustainable development, like <i><strong>Goal 13: Take urgent action to combat climate change and its impact</strong> and <strong>Goal 12: Ensure sustainable consumption and production patterns</strong></i>, have also grown in importance.
                            </p>
                        </div>
                        <div className='step' data-step='3'>
                            <p>
                                Also, <i><strong>Goal 5: Achieve gender equality and empower all women and girls</strong></i> is the object of a growing number of projects, and, transversally, is of greater concern in the orientations and regulations of Horizon 2020, affecting all funded projects.
                            </p>
                        </div>
                        <div className='step' data-step='4'>
                            <p style={{ display: 'none' }}></p>
                        </div>
                    </article>

                </section>
            }

        </div >
    }
}


export default Introduction;