import * as d3 from 'd3';

// configuration settings
const margin = {
        top: 25,
        left: 0,
        bottom: 0,
        right: 0
    },
    axis_ticks = [0,100], //[0,20,40,60,80,100],
    //tickLabelMargin = 10,
    axisLabelMargin = 20,
    // positions of the axis:
    // economy: bottom left
    // society: bottom right
    // biosphere: top
    axis_labels = ['economy', 'society', 'biosphere'],
    minor_axis_ticks = d3.range(0, 101, 5);

    



let ternaryGraph = function() {
    var elementSelector,
        side,
        svg,
        axes,
        w,h,
        corners,
        translateX,
        translateY;

    // data coming from outside
    function getCoord(datum) {
        let pos = coord([
            datum[axis_labels[0]],
            datum[axis_labels[1]],
            datum[axis_labels[2]]
        ]);
        return [
            pos[0] + translateX, 
            pos[1] + translateY
        ];
    };

    function coord(arr){
        var a = arr[0], 
            b = arr[1], 
            c = arr[2]; 
    
        var sum, 
            pos = [0,0];
        sum = a + b + c;
        if(sum !== 0) {
            a /= sum;
            b /= sum;
            c /= sum;
            pos[0] =  corners[0][0]  * a + corners[1][0]  * b + corners[2][0]  * c;
            pos[1] =  corners[0][1]  * a + corners[1][1]  * b + corners[2][1]  * c;
        }
        // add the translate of the main <g> placeholder
        return pos;
    }

    function show() { svg.attr('display', 'inline'); }
    function hide() { svg.attr('display', 'none'); }



    function init(_elementSelector) {
        elementSelector = _elementSelector;
        
        // decide size for the ternary plot
        side = Math.min(
            +d3.select(elementSelector).attr('width'),
            +d3.select(elementSelector).attr('height')
        ) * 0.9;    
        // add degs
        let _svg = d3.select(elementSelector);
        let gradient = _svg.append('defs')
                .append('linearGradient')
                .attr('id', 'ternary-gradient');
        gradient.append('stop').attr('offset', '0%').attr('stop-color', '#333');
        gradient.append('stop').attr('offset', '50%').attr('stop-color', '#fff');
        gradient.append('stop').attr('offset', '100%').attr('stop-color', '#333');

        svg = d3.select(elementSelector)
            .append('g')
            .attr('class', 'ternary');
        
        // hide the plot until someone will show it
        hide();

        axes = svg.append('g').attr('class', 'axes')
        w = side;
        h = Math.sqrt( side*side - (side/2)*(side/2));

        // center the ternary
        translateX = +d3.select(elementSelector).attr('width')/2 - side/2;
        translateY = 50;
        svg.attr('transform', 'translate(' + translateX + ', ' + translateY + ')');
        corners = [
            [margin.left, h + margin.top],      // a
            [ w + margin.left, h + margin.top], // b 
            [(w/2) + margin.left, margin.top]   // c
        ];
    }    

    function draw() {
        var line = d3.line()
            .x(d => d[0])
            .y(d => d[1]);

        //axis names
        axes.selectAll('.axis-title')
            .data(axis_labels)
            .enter()
                .append('g')
                    .attr('class', 'axis-title')
                    .attr('transform',function(d,i){
                        return 'translate('+corners[i][0]+','+corners[i][1]+')';
                    })
                    .append('text')
                    .text(function(d){ return d; })
                    .attr('text-anchor', function(d,i){
                        if(i===0) return 'end';
                        if(i===2) return 'middle';
                        return 'start';
                        
                    })
                    .attr('transform', function(d,i){
                        var theta = 0;
                        if(i===0) theta = 120;
                        if(i===1) theta = 60;
                        if(i===2) theta = -90;

                        var x = axisLabelMargin * Math.cos(theta * 0.0174532925),
                            y = axisLabelMargin * Math.sin(theta * 0.0174532925);
                        return 'translate('+x+','+y+')'
                    });

        // ticks
        //var n = axis_ticks.length;
        if(minor_axis_ticks){
            minor_axis_ticks.forEach(function(v) {	
                var coord1 = coord( [v, 0, 100-v] );
                var coord2 = coord( [v, 100-v, 0] );
                var coord3 = coord( [0, 100-v, v] );
                var coord4 = coord( [100-v, 0, v] );

                axes.append("path")
                    .attr('d', line([coord1, coord2]) )
                    .classed('a-axis minor-tick', true);	

                axes.append("path")
                    .attr('d', line([coord2, coord3]) )
                    .classed('b-axis minor-tick', true);	

                axes.append("path")
                    .attr('d', line([coord3, coord4]) )
                    .classed('c-axis minor-tick', true);
            });
        }

        // annotations
        var coordAnnotation,
            offsetAnnotation = 10;
        // first and third axis (economy-biosphere)
        coordAnnotation = coord( [50, 0, 100-50] );
        axes.append('g')
            .attr('transform',d => 'translate(' + coordAnnotation[0] + ',' + coordAnnotation[1] + ')')
            .append("text")
                .attr('transform','rotate(-60)')
                .attr('text-anchor','start')
                .attr('x',offsetAnnotation).attr('y',-offsetAnnotation)
                .text('Biosphere-oriented →' )
                .classed('annotation-text', true );
        axes.append('g')
            .attr('transform',d => 'translate(' + coordAnnotation[0] + ',' + coordAnnotation[1] + ')')
            .append("text")
                .attr('transform','rotate(-60)')
                .attr('text-anchor','end')
                .attr('x',-offsetAnnotation).attr('y',-offsetAnnotation)
                .text('← Economy-oriented' )
                .classed('annotation-text', true );
        
        // first and second axis (economy-society)
        coordAnnotation = coord( [50, 100-50, 0] );
        axes.append('g')
            .attr('transform',d => 'translate(' + coordAnnotation[0] + ',' + coordAnnotation[1] + ')')
            .append("text")
                .attr('x', offsetAnnotation)
                .attr('y', offsetAnnotation*2)
                .text("Society-oriented →")
                .classed('annotation-text', true);
        axes.append('g')
            .attr('transform',d => 'translate(' + coordAnnotation[0] + ',' + coordAnnotation[1] + ')')
            .append("text")
                .attr('x', -offsetAnnotation)
                .attr('y', offsetAnnotation*2)
                .attr('text-anchor','end')
                .text("← Economy-oriented")
                .classed('annotation-text', true);                    

        // second and third axis (society-biosphere)
        coordAnnotation = coord( [0, 100-50, 50] );
        axes.append('g')
            .attr('transform', d => 'translate(' + coordAnnotation[0] + ',' + coordAnnotation[1] + ')')
            .append("text")
                .text( "Society-oriented →")
                .attr('transform','rotate(60)')
                .attr('x', offsetAnnotation)
                .attr('y', -offsetAnnotation)
                .classed('annotation-text', true);
        axes.append('g')
            .attr('transform', d => 'translate(' + coordAnnotation[0] + ',' + coordAnnotation[1] + ')')
            .append("text")
                .text( "← Biosphere-oriented")
                .attr('text-anchor','end')
                .attr('transform','rotate(60)')
                .attr('x', -offsetAnnotation)
                .attr('y', -offsetAnnotation)
                .classed('annotation-text', true);                    

        axis_ticks.forEach(function(v) {	
            var coord1 = coord( [v, 0, 100-v] );
            var coord2 = coord( [v, 100-v, 0] );
            var coord3 = coord( [0, 100-v, v] );
            var coord4 = coord( [100-v, 0, v] );

            axes.append("path")
                .attr('d', line([coord1, coord2]) )
                .classed('a-axis tick', true);	

            axes.append("path")
                .attr('d', line([coord2, coord3]) )
                .classed('b-axis tick', true);	
    
            axes.append("path")
                // hacky way to make the stroke gradient color style
                // visible when the draw is vertical/horizontal. In
                // this cases the gradient is not shown (not diagonals
                // lines have no dim w or h (my guess...) ), so
                // for the bottom axis, make it just a bit diagonal
                // by moving one of the y positions a bit
                .attr('d', line([coord3, [coord4[0], coord4[1]-1]]) )
                .classed('c-axis tick', true);

            //tick labels
/*            
            axes.append('g')
                .attr('transform',function(d){
                    return 'translate(' + coord1[0] + ',' + coord1[1] + ')'
                })
                .append("text")
                    .attr('transform','rotate(60)')
                    .attr('text-anchor','end')
                    .attr('x',-tickLabelMargin)
                    .text( function (d) { return v; } )
                    .classed('a-axis tick-text', true );

            axes.append('g')
                .attr('transform',function(d){
                    return 'translate(' + coord2[0] + ',' + coord2[1] + ')'
                })
                .append("text")
                    .attr('transform','rotate(-60)')
                    .attr('text-anchor','end')
                    .attr('x',-tickLabelMargin)
                    .text( function (d) { return (100- v); } )
                    .classed('b-axis tick-text', true);
    
            axes.append('g')
                .attr('transform',function(d){
                    return 'translate(' + coord3[0] + ',' + coord3[1] + ')'
                })
                .append("text")
                    .text( function (d) { return v; } )
                    .attr('x',tickLabelMargin)
                    .classed('c-axis tick-text', true);
 */
        })
    }
    
    return {
        init: init,
        draw: draw,
        getCoord: getCoord,
        show: show,
        hide: hide
    }
}

const ternaryPlot = ternaryGraph();
export default ternaryPlot;