import StateMachine from 'javascript-state-machine';
import * as _ from 'lodash';

export const UNSET   = 'unset';
export const STATIC  = 'static';
export const TSNE    = 'tsne';
export const TERNARY = 'ternary';

var fsm = new StateMachine({
    init: 'unset',
    transitions: [
      { name: 'toStatic',    from: UNSET,  to: STATIC },
      { name: 'toTsne',      from: STATIC, to: TSNE },
      { name: 'toTernary',   from: TSNE,   to: TERNARY},
      { name: 'fromTernary', from: TERNARY,to: TSNE},
      { name: 'fromTsne',    from: TSNE,   to: STATIC }
    ],
    methods: {
      onToStatic:    function() { console.log('reached static')    },
      onToTsne:      function() { console.log('reached tsne')    },
      onToTernary:   function() { console.log('reached ternary')     },
      onFromTernary: function() { console.log('back to tsne') },
      onFromTsne:    function() { console.log('back to static') }
    }
  });


// decide which phase of the storytelling go to, dependin
// on which step of the article we are. Steps of the
// articles are on the markdown
var decidePhase = function(article_step) {
    if( _.inRange(article_step, 0, 2+1) ) {
        if(fsm.is(UNSET))
            fsm.toStatic();
        if(fsm.is(TSNE))
            fsm.fromTsne();
    }
    if( _.inRange(article_step, 3, 7+1) ) {
        if(fsm.is(STATIC))
            fsm.toTsne();
        else if(fsm.is(TERNARY))
            fsm.fromTernary();
    }
    if( _.inRange(article_step, 8, 9+1) ) {
        if(fsm.is(TSNE))
            fsm.toTernary();
    }
    return fsm.state;
};
export {fsm, decidePhase}
