export const keywordsIncrements = {
    "$schema": "https://vega.github.io/schema/vega/v5.json",
    "height": 500,
    "padding": {"left": 25, "right": 225, "top": 0, "bottom": 20},
    "autosize": "fit",
    "signals": [
      {"name": "cx", "update": "width / 2"},
      {"name": "cy", "update": "height / 2"}, 
      {"name": "gravityX", "value": 1},
      {"name": "gravityY", "value": 0.75},
      {"name": "lineBreak", "value": "@"},
      {"name": "colorAnnotation", "value": "cadetblue"}
    ],
    "data": [
      {
        "name": "keywords",
        "values": undefined,
        "transform": [
          {
            "type": "formula",
            "expr": "datum.total_fp7 + datum.total_h2020",
            "as": "total"
          },
          {"type": "formula", "expr": "datum.increment / 100", "as": "increment"},
          {"type": "formula", "expr": "datum.percent_increment / 100", "as": "percent_increment"},
          { "type": "collect","sort": { "field": ["total_fp7"], "order":["descending"]}},
          {"type": "identifier", "as": "id"},
          {"type": "filter", "expr": "datum.id < 100"},
          {"type": "extent", "field": "percent_increment", "signal": "extent"}
        ]
      },
      {
        "name": "increment",
        "source": "keywords",
        "transform": [
          {"type": "project", "fields": ["percent_increment"], "as": ["percent_increment"]}
        ]
      },
      {
        "name": "positive_increment",
        "source": "increment",
        "transform": [{"type": "filter", "expr": "datum.percent_increment >= 0"}]
      },
      {
        "name": "negative_increment",
        "source": "increment",
        "transform": [{"type": "filter", "expr": "datum.percent_increment < 0"}]
      }
    ],
    "scales": [
      {
        "name": "x",
        "type": "linear",
        "domain": {"signal": "[extent[0] - 0.005, extent[1] + 0.005]"},
        "range": "width"
      },
      {
        "name": "r",
        "type": "pow",
        "exponent": 0.55,
        "domain": {"data": "keywords", "field": "total"},
        "range": [10, 200]
      },
      {
        "name": "font",
        "type": "linear",
        "domain": {"data": "keywords", "field": "total"},
        "range": [6, 40]
      }, 
      {
        "name": "color_negative",
        "type": "linear",
        "domain": {"data": "negative_increment", "field": "percent_increment"},
        "range": ["#f44336", "#888"]
      },
      {
        "name": "color_positive",
        "type": "linear",
        "domain": {"data": "positive_increment", "field": "percent_increment"},
        "range": ["#888", "#008eff"]
      }
    ],
    "axes": [
      {
        "orient": "bottom",
        "grid": true,
        "gridColor": "#ccc",
        "gridDash": [1, 4],
        "scale": "x",
        "labelFontSize": 8,
        "domain": false,
        "ticks": false,
        "encode": {
          "labels": {
            "update": {
              "text": {
                "signal": "datum.value === 0? '' : format(datum.value*1, '+.1%')"
              },
              "grid": {
                  "gridOpacity": {
                      "signal": "datum.value === 0 ? 0:1"
                  }
              }
            }
          }
        }
      }
    ],
    "marks": [
        {   
            "name": "axis-legend",
            "type": "text",
            "encode": {
                "enter": {
                    "x": {
                        "signal": "scale('x', 0)"
                    },
                    "y": {
                        "signal": "height + 15"
                    },
                    "text": {
                        "value": "Keyword frequency@deviation H2020 vs FP7"                        
                    },
                    "align": {
                        "value": "center"
                    },
                    "lineBreak": {
                        "signal": "lineBreak"
                    },
                    "fontSize": {
                        "value": 9
                    },
                    "fill": {
                        "signal": "colorAnnotation"
                    }
                }
            }
        },
      {
        "name": "legend-right",
        "type": "text",
        "encode": {
          "enter": {
            "fill": {
              "signal": "colorAnnotation"
            },
            "x": {
              "signal": "scale('x', 0) + 25"
            },
            "y": {
              "value": 5
            },
            "text": {
              "value": "Topics with more presence@over time →"
            },
            "lineBreak": {
              "signal": "lineBreak"
            },
            "align": {
              "value": "left"
            },
            "fontStyle": {
                "value": "italic"
            }
        }
        }
      },
      {
        "name": "legend-left",
        "type": "text",
        "encode": {
          "enter": {
            "fill": {
              "signal": "colorAnnotation"
            },
            "x": {
              "signal": "scale('x', 0) - 25"
            },
            "y": {
              "value": 5
            },
            "text": {
              "value": "Topics with less presence@← over time"
            },
            "lineBreak": {
              "signal": "lineBreak"
            },
            "align": {
              "value": "right"
            },
            "fontStyle": {
                "value": "italic"
            }
        }
        }
      },
      {
        "name": "threshold",
        "type": "rule",
        "encode": {
          "enter": {
            "x": {"signal": "scale('x', 0)"},
            "x2": {"signal": "scale('x', 0)"},
            "y": {"value": 0},
            "y2": {"signal": "height"},
            "stroke": {"value": "#666"},
            "strokeDash": {"value": [1,4]}
          }
        }
      },
      {
        "name": "keyword-groups",
        "type": "group",
       "from": {
          "facet": {
            "data": "keywords",
            "name": "facet",
            "groupby": ["keyword", "percent_increment", "total"]
          }
        },
        "encode": {
          "enter": {
            "xfocus": {"scale": "x", "field": "percent_increment"},
            "yfocus": {"signal": "cy"}
          }
        },
        "marks": [            
              {
                "name": "nodes-circles-fp7",
                "description": "Colored circles for FP7 (will be masked later)",
                "type": "symbol",
                "from": {"data": "facet"},
                "encode": {
                  "enter": {
                    "xfocus": {"scale": "x", "field": "percent_increment"},
                    "yfocus": {"signal": "cy"},
                    "fill":  {
                        "signal": "scale((datum.percent_increment < 0? 'color_negative':'color_positive'), datum.percent_increment)"
                      },
                    "stroke": {
                        "signal": "scale((datum.percent_increment < 0? 'color_negative':'color_positive'), datum.percent_increment)"
                      },
                    "strokeDash": {"value": [1,1]},
                    "strokeOpacity": {"value": 0.35},
                    "fillOpacity": {"value": 0.2}
                  },
                  "update": {
                    "size": {"signal": "scale('r', datum.total)*20"},          
                    "zindex": {"value": 0}
                  }
                }
              },
              {
                "name": "nodes-texts",
                "type": "text",              
                "from": {"data": "facet"},
                "encode": {
                  "enter": {
                    "text": {"field": "keyword"},
                    "align": {"value": "center"},
                    "lineBreak": {"value": " "},
                    "fill": {
                        "signal": "scale((datum.percent_increment < 0? 'color_negative':'color_positive'), datum.percent_increment)"
                      },
                      "dy": {
                          "signal": "-scale('font', datum.total) * 0.2"
                        }
                  },
                  "update": {
                    "fontSize": {"signal": "scale('font', datum.total)"},          
                    "zindex": {"value": 0}
                  }
                }
              }
        ],
        "transform": [
          {
            "type": "force",
            "iterations": 300,
            "static": true,
            "forces": [
              {
                "force": "collide",
                "iterations": 1,
                "radius": {"expr": "scale('r', datum.datum.total) * 0.45"}
              },
              {"force": "x", "x": "xfocus", "strength": {"signal": "gravityX"}},
              {"force": "y", "y": "yfocus", "strength": {"signal": "gravityY"}}
            ]
          }
        ]
      }       
    ]
  };