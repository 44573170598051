export const areaChartAll = {
   "$schema": "https://vega.github.io/schema/vega/v5.json",
   "padding": 0,
   "title": {
      "text": "Annual number of EU-funded projects, total and SDG-related",
      "offset": 10,
      "color": {"signal": "bgColor"}
   },
   "signals": [
      {
         "name": "symbolMouseover",
         "description": "This signal has a listener on the Vega view runtime",
         "value": null,
         "on": [
            {
               "events": "rect:mouseover",
               "update": "datum.startingYear"
            },
            {
               "events": "rect:mouseout",
               "update": "null"
            }
         ]
      },
      {
         "name": "symbolHighlight",
         "value": null,
         "description": "This signal is updated from the view runtime, and receives the datum of the signal 'symbolMouseover', so we can highlight the hovered point in another view different from this one"
      },
      {
         "name": "hover",
         "on": [
            {
               "events": "*:mouseover",
               "encode": "hover"
            },
            {
               "events": "*:mouseout",
               "encode": "leave"
            }
         ]
      },
      {
         "name": "bgColor",
         "value": "#19486A"
      }
   ],
   "data": [
      {
         "name": "table",
         "values": null,
         "transform": [
            {
               "type": "filter",
               "expr": "datum.SDG == 'ALL'"
            },
            {
               "type": "filter",
               "expr": "datum.startingYear !== 2007 && datum.startingYear !== 2020"
            }
         ]
      },
      {
         "name": "legend",
         "values": [
            { "label": "R&I projects" },
            { "label": "R&I projects classified as SDG-related " }
         ]
      }
   ],
   "legends": [
      {
         "type": "symbol",
         "symbolType": "square",
         "orient": "bottom",
         "stroke": "legend",
         "padding": 4,
         "labelLimit": 400,
         "labelColor": { "signal": "bgColor" },
         "encode": {
            "symbols": {
               "enter": {
                  "strokeOpacity": { "value": 0 },
                  "fill": { "signal": "bgColor" },
                  "strokeWidth": { "value": 2 },
                  "size": { "value": 120 }
               }
            }
         }
      }
   ],
   "scales": [
      {
         "name": "legend",
         "type": "ordinal",
         "domain": {
            "data": "legend",
            "field": "label"
         },
         "range": ["bgColor", "bgColor"]
      },
      {
         "name": "x",
         "type": "point",
         "range": "width",
         "domain": {
            "data": "table",
            "field": "startingYear"
         }
      },
      {
         "name": "yTotal",
         "type": "linear",
         "range": "height",
         "nice": true,
         "zero": true,
         "domain": {
            "data": "table",
            "field": "totalNumProjectsCORDIS"
         },
         "domainMax": 9000
      },
      {
         "name": "xscale",
         "type": "band",
         "domain": {
            "data": "table",
            "field": "startingYear"
         },
         "range": "width",
         "round": true
      },
      {
         "name": "yscale",
         "type": "linear",
         "domain": [
            0,
            1
         ],
         "range": "height"
      }
   ],
   "axes": [
      {
         "orient": "bottom",
         "scale": "x",
         "labelOverlap": true,
         "labelFlush": true,
         "ticks": false,
         "labelColor": { "signal": "bgColor"},
         "values": [2008, 2019],
         "labelPadding": 6,
         "domainOpacity": 0
      },
      {
         "orient": "left",
         "scale": "yTotal",
         "ticks": false,
         "labelPadding": 5,
         "labelColor": { "signal": "bgColor"},
         "domainOpacity": 0,
         "grid": true,
         "gridColor": { "signal": "bgColor" },
         "gridOpacity": .25,
         "values": [5000],
         "encode": {
            "labels": {
               "interactive": true,
               "update": {
                  "text": {
                     "signal": "datum.value ===0? '':format(parseInt(datum.value), '.2s')"
                  }
               }
            }
         }
      }
   ],
   "marks": [
      {
         "type": "group",
         "name": "labelling",
         "style": "cell",
         "marks": [
            {
               "type": "rule",
               "encode": {
                  "enter": {
                     "x": {
                        "scale": "x",
                        "value": 2014
                     },
                     "x2": {
                        "scale": "x",
                        "value": 2019
                     },
                     "y": {
                        "scale": "yscale",
                        "value": .8
                     },
                     "y2": {
                        "scale": "yscale",
                        "value": .8
                     },
                     "stroke": {
                        "value": "#CCC"
                     },
                     "strokeWidth": {
                        "value": 1
                     },
                     "strokeDash": {
                        "value": [2, 2]
                     }
                  }
               }
            },
            {
               "type": "text",
               "encode": {
                  "enter": {
                     "text": {
                        "value": "Programme H2020 period"
                     },
                     "x": {
                        "scale": "x",
                        "value": 2014
                     },
                     "y": {
                        "scale": "yscale",
                        "value": .84
                     },
                     "fill": {
                        "signal": "bgColor"
                     }
                  }
               }
            },
            {
               "type": "rule",
               "encode": {
                  "enter": {
                     "x": {
                        "scale": "x",
                        "value": 2008
                     },
                     "x2": {
                        "scale": "x",
                        "value": 2013
                     },
                     "y": {
                        "scale": "yscale",
                        "value": .8
                     },
                     "y2": {
                        "scale": "yscale",
                        "value": .8
                     },
                     "stroke": {
                        "value": "#CCC"
                     },
                     "strokeWidth": {
                        "value": 1
                     },
                     "strokeDash": {
                        "value": [2, 2]
                     }
                  }
               }
            },
            {
               "type": "text",
               "encode": {
                  "enter": {
                     "text": {
                        "value": "Programme FP7 period"
                     },
                     "x": {
                        "scale": "x",
                        "value": 2008
                     },
                     "y": {
                        "scale": "yscale",
                        "value": .84
                     },
                     "fill": {
                        "signal": "bgColor"
                     }
                  }
               }
            }
         ]
      },
      {
         "type": "area",
         "name": "area-all-sdg",
         "from": {
            "data": "table"
         },
         "encode": {
            "enter": {
               "x": {
                  "scale": "x",
                  "field": "startingYear"
               },
               "y": {
                  "scale": "yTotal",
                  "field": "numProjectsSDG"
               },
               "y2": {
                  "scale": "yTotal",
                  "value": 0
               }
            },
            "update": {
               "interpolate": {
                  "value": "monotone"
               }
            }
         }
      },
      {
         "type": "line",
         "from": {
            "data": "table"
         },
         "encode": {
            "enter": {
               "x": {
                  "scale": "x",
                  "field": "startingYear"
               },
               "y": {
                  "scale": "yTotal",
                  "field": "totalNumProjectsCORDIS"
               },
               "stroke": {
                  "signal": "bgColor"
               },
               "strokeWidth": {
                  "value": 2
               }
            },
            "update": {
               "interpolate": {
                  "value": "monotone"
               },
               "fillOpacity": {
                  "value": 1
               }
            }
         }
      },
      {
         "type": "rect",
         "from": {
            "data": "table"
         },
         "encode": {
            "enter": {
               "x": {
                  "scale": "xscale",
                  "field": "startingYear"
               },
               "width": {
                  "scale": "xscale",
                  "band": 1
               },
               "y": {
                  "scale": "yscale",
                  "value": 1
               },
               "y2": {
                  "scale": "yscale",
                  "value": 0
               },
               "tooltip": {
                  "signal": "{Year: datum.startingYear, 'SDG-related projects': datum.numProjectsSDG, 'R&I projects': datum.totalNumProjectsCORDIS}"
               }
            },
            "update": {
               "opacity": {
                  "value": 0
               },
               "fill": {
                  "value": "#666"
               }
            }
         }
      },
      {
         "type": "group",
         "style": "cell",
         "name": "tooltip-values",
         "marks": [
            {
               "type": "text",
               "from": {
                  "data": "table"
               },
               "encode": {
                  "enter": {
                     "dy": {
                        "value": -10
                     },
                     "text": {
                        "signal": "format(parseInt(datum.numProjectsSDG), ',.4r')"
                     },
                     "opacity": {
                        "value": 0
                     },
                     "align": {
                        "value": "center"
                     },
                     "fill": {
                        "signal": "bgColor"
                     },
                     "fontSize": {
                        "value": 10
                     },
                     "interactive": {
                        "value": false
                     }
                  },
                  "update": {
                     "x": {
                        "scale": "x",
                        "field": "startingYear"
                     },
                     "y": {
                        "scale": "yTotal",
                        "field": "numProjectsSDG"
                     },
                     "opacity": {
                        "signal": "symbolHighlight === datum.startingYear ? 1 : 0"
                     }
                  }
               }
            },
            {
               "type": "text",
               "from": {
                  "data": "table"
               },
               "encode": {
                  "enter": {
                     "dy": {
                        "value": -10
                     },
                     "text": {
                        "signal": "format(parseInt(datum.totalNumProjectsCORDIS), ',.4r')"
                     },
                     "opacity": {
                        "value": 0
                     },
                     "align": {
                        "value": "center"
                     },
                     "fill": {
                        "signal": "bgColor"
                     },
                     "fontSize": {
                        "value": 10
                     },
                     "interactive": {
                        "value": false
                     }
                  },
                  "update": {
                     "x": {
                        "scale": "x",
                        "field": "startingYear"
                     },
                     "y": {
                        "scale": "yTotal",
                        "field": "totalNumProjectsCORDIS"
                     },
                     "opacity": {
                        "signal": "symbolHighlight === datum.startingYear ? 1 : 0"
                     }
                  }
               }
            },
            {
               "type": "symbol",
               "style": "circle",
               "from": {
                 "data": "table"
               },
               "encode": {
                 "enter": {
                   "opacity": {
                     "value": 0
                   },
                   "fill": { "signal": "bgColor" },
                   "stroke": { "signal": "bgColor" },
                   "strokeWidth": { "value": 1 }
                 },
                 "update": {
                   "x": {
                     "scale": "x",
                     "field": "startingYear"
                   },
                   "y": {
                     "scale": "yTotal",
                     "field": "numProjectsSDG"
                   },
                   "opacity": {
                     "signal": "symbolHighlight === datum.startingYear ? 1 : 0"
                   }
                 }
               }
             },
             {
               "type": "symbol",
               "style": "circle",
               "from": {
                 "data": "table"
               },
               "encode": {
                 "enter": {
                   "opacity": {
                     "value": 0
                   },
                   "fill": { "signal": "bgColor" },
                   "stroke": { "signal": "bgColor" },
                   "strokeWidth": { "value": 1 }
                 },
                 "update": {
                   "x": {
                     "scale": "x",
                     "field": "startingYear"
                   },
                   "y": {
                     "scale": "yTotal",
                     "field": "totalNumProjectsCORDIS"
                   },
                   "opacity": {
                     "signal": "symbolHighlight === datum.startingYear ? 1 : 0"
                   }
                 }
               }
             }
         ]
      }

   ]
}