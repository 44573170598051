import React, { Component } from 'react';
import * as _ from 'lodash';
import VegaChart from '../common/vega/VegaChart';
import { areaChart } from '../common/vega/VegaSpecifications';
import * as d3 from 'd3';
import textures from 'textures';


class AreaCharts extends Component {


    render() {
       const { areaChartValues, excludedIndicators } = this.props;

        let clonedValues = [...areaChartValues]
        if (excludedIndicators) {

            var i;
             for (i = 0; i < excludedIndicators.length; i++) {
                 delete clonedValues[excludedIndicators[i]]
             }
        }


        return <div className="col justify-content-md-center">
            <div className="row row-cols-1 row-cols-md-3 row-cols-lg-4">
                {
                    clonedValues.map(function (SDG, index) {
                        let sdgClass = _.replace(_.first(SDG)['SDG'], ' ', '');
                        let color = _.first(SDG).color;

                        return <div key={index} className={"col p-1 area-placeholder area-placeholder-" + index}>
                            <div className={"w-100 h-100 d-flex flex-wrap area-icon pt-4 area-icon-" + index}>
                                <VegaChart
                                    chartClass={'area w-100 mt-auto ' + sdgClass}
                                    height={80}
                                    data={ clonedValues[index]}
                                    spec={areaChart}
                                    runAfterCallback={view => {
                                        const t = textures.lines().size(4).strokeWidth(1).stroke(color);
                                        const svg = d3.selectAll('.area.' + sdgClass + ' svg');
                                        svg.call(t);
                                        svg.selectAll('g.mark-area.role-mark path').style('fill', t.url());
                                    }}
                                />
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    }
}

export default AreaCharts;