import * as d3 from 'd3';
import * as _ from 'lodash';
import { 
    SDG_INFO, 
    SDG_GROUPING,
    CONTINENT_COLORS } from '../common'
import Tooltip from './tooltip';
import textures from 'textures';



export default class CountryPlotGenerator {


    constructor(elementSelector, label, id, placeholderInfo, data, countryVariable, ghostVariable, sortingVariable, callbacks) {

        // set of states for the plot
        this.HIDDEN = 'none';
        this.VISIBLE = 'inline';
        this.FOLDED = 2;
        this.UNFOLDED = 3;
        this.dotRadius = data.dotRadius || 1;
        this.dotLabelSize = data.dotLabelSize || 8;

        this.elementSelector = elementSelector;
        this.placeholderInfo = placeholderInfo || { width: 300, height: 300, x: 0, y: 0, isFake:true };
        this.label = label;
        this.id = id;
        this.radius = this.setRadius();
        this.data = data;
        this.countryVariable = countryVariable;
        this.ghostVariable = ghostVariable;
        this.sortingVariable = sortingVariable;
        this.callbacks = callbacks;

        this.config = {
            svg: d3.select(this.elementSelector),
            width: this.placeholderInfo.width,
            height: this.placeholderInfo.height,
            radius: this.radius,
            innerRadius: this.setInnerRadius(),
            pie: d3.pie()
                .sort(null)
                .value(function (d) { return d.width; }),
            texture: textures.lines().size(4).strokeWidth(1).stroke('#000')
        };

        // set initial state as hidden
        // and create all the elements
        this.display = this.HIDDEN;
        this.createPlaceholder();
        this.drawMultidimensionalPlot();
        this.drawDotPlot();
    }

    /** helpers  */
    updateTitle(selection, self) {
        selection.classed('primary', self.placeholderInfo.isPrimary)
            .attr(
                "transform", 
                "translate(0," + (self.placeholderInfo.isPrimary? -self.config.height * 0.47:-self.config.height * 0.37) + ")"
            )
    };

    updateValue(selection, self) {
        selection.classed('primary', self.placeholderInfo.isPrimary)
    };

    setRadius() { 
        // radius is half the minimum width or height, multiplied
        // by a factor to reduce it a bit, in order to allocate
        // the texts related to the SDG groups 
        return Math.min(this.placeholderInfo.width/2, this.placeholderInfo.height/2) * 0.65;
    };
    setInnerRadius() { return this.radius * 0.35 };




    /************************************************************
     * Methods to change state of the plot
     */



    /**
     * Object with properties width, height, center x, center y of
     * a target placeholder (this results in centering and sizing the
     * plot according the placeholder).
     * @param {Object} placeholderInfo 
     */
    setPlaceholderInfo(placeholderInfo) {
        this.placeholderInfo = placeholderInfo;
        this.radius = this.setRadius();
        this.config.width = this.placeholderInfo.width;
        this.config.height = this.placeholderInfo.height;
        this.config.radius = this.radius;
        this.config.innerRadius = this.setInnerRadius();
        
        // redraw the plot with the new sizing
        this.updateMultidimensionalPlot(this.data, this.label, 'full');
        this.placeholderInfo.isPrimary && this.calculateGroupsScore(SDG_GROUPING)
    }



    /**
     * Change the visibility of the plot 
     */
    show(value = true) {

        if (!this.placeholderInfo.isPrimary) {
            this.config.svgGroup.selectAll(".groupArc").attr("opacity", 0);
            this.config.svgGroup.selectAll(".donutText").attr("opacity", 0);
        }

        this.state = value ? this.VISIBLE : this.HIDDEN;
        this.config.gPlaceholder.attr('display', this.state);
    };



    /**
     * Changes the opacity of the main dot group placeholder.
     * Selection is done when brushing, so only should affect
     * to the dot group
     */
    select(value = true) {
        this.config.dotGroup.attr('opacity', value ? 1 : 0.25);
        this.config.dotGroup.classed('brushed', value);
        this.config.dotGroup.select('circle').classed('brushed', value);
        this.config.dotGroup.select('text').classed('brushed', value);
    }

    reset() {
        this.config.dotGroup.attr('opacity', 1);
        this.config.dotGroup.classed('brushed', false);
        this.config.dotGroup.select('circle').classed('brushed', false);
        this.config.dotGroup.select('text').classed('brushed', false);
    }


    /**
     * Moves the main <g> element to indicate position
     * @param {int} x 
     * @param {int} y 
     */
    moveTo(x, y) {
        this.config.gPlaceholder
            .attr('x', x)
            .attr('y', y);
        return this;
    }


    fromTsne(isStatic = false, info) {
        this.config.gPlaceholder.selectAll('*').interrupt();

        if (isStatic) {

            this.config.dotGroup.select('text').attr('opacity', 0);
            this.config.gPlaceholder
                .transition('fromTsne')
                .duration(1000)
                .ease(d3.easeQuadIn)
                .attr('x', info.x)
                .attr('y', info.y)
                .on('end', () => {
                    this.fold(false);
                    this.updateMultidimensionalPlot(this.data, this.label, 'full')
                });

            this.config.dotGroup.select('circle')
                .attr('r', this.dotRadius)
                .transition('fromTsne')
                .delay(1000)
                .duration(200)
                .attr('r', 0);
        }
        else {
            this.config.dotGroup.select('text').attr('opacity', 0);
            this.config.dotGroup.select('circle')
                .attr('r', this.dotRadius)
                .transition('fromTsne')
                .duration(200)
                .attr('r', 0)
        }
    }

    /**
     * Transition to tsne state
     */
    toTsne(isStatic = false, i) {
        // interrupt ongoing transitions
        this.config.gPlaceholder.selectAll('*').interrupt();

        if (isStatic) {            
            this.updateMultidimensionalPlot(this.data, this.label, 'fold')

            this.show();
            this.config.dotGroup
                .select('circle')
                .attr('r', 0)
                .transition('toTsne')
                .ease(d3.easeQuadOut)
                .attr('r', this.dotRadius)
                .delay(600 + (i * 20))
                .duration(200);

            this.config.gPlaceholder
                .transition('toTsne')
                .ease(d3.easeQuadOut)
                .delay(1200)
                .duration(400)
                .attr('x', this.data.svgCoords[0])
                .attr('y', this.data.svgCoords[1])
                .on('end', () => this.config.dotGroup.select('text').attr('opacity', 1));
        }
        else {
            this.fold(true)
            this.moveTo(this.data.svgCoords[0], this.data.svgCoords[1]);
            this.show();
            this.config.dotGroup.select('text').attr('opacity', 0);
            this.config.dotGroup
                .select('circle')
                .attr('r', 0)
                .transition('toTsne')
                .ease(d3.easeQuadOut)
                .attr('r', this.dotRadius)
                .delay(1400 + (i * 5))
                .duration(100)
                .on('end', () => this.config.dotGroup.select('text').attr('opacity', 1));
        }
        this.config.dotGroup
            .select('circle')
            .on('click', () => {
                //this.callbacks[2]({ label: this.label, data: this.data });
                this.callbacks[2]({
                    label: this.label, 
                    id: this.id, 
                    data: this.data, 
                    countryVariable: this.countryVariable, 
                    ghostVariable: this.ghostVariable, 
                    sortingVariable: this.sortingVariable
                });
            })
            .on('mouseover', () => {
                this.config.dotGroup.attr('opacity', 1).select('text').attr('opacity', 1);
                this.callbacks[0](this.label, this.id, this.data, this.countryVariable, this.ghostVariable, this.sortingVariable);
            })
            .on('mouseout', () => {
                this.config.dotGroup.classed('brushed') ? this.config.dotGroup.attr('opacity', 1) : this.config.dotGroup.attr('opacity', 0.25).select('text').attr('opacity', 1)
                this.callbacks[1]();
            });
    };



    /**
     * 
     */
    toTernary(i) {
        this.config.gPlaceholder
            .transition('toTernary')
            .ease(d3.easeQuadOut)
            .delay(i * 5)
            .duration(200)
            .attr('x', this.data.ternaryCoords[0])
            .attr('y', this.data.ternaryCoords[1]);

        this.config.dotGroup
            .on('mouseover', () => this.config.dotGroup.select('text').attr('opacity', 1))
            .on('mouseout', () => this.config.dotGroup.select('text').attr('opacity', 0))
            .transition('toTernary')
            .ease(d3.easeQuadOut)
            .delay(i * 5)
            .duration(200)
            .on('end', () => this.config.dotGroup.select('text').attr('opacity', 0))


    }


    /**
     * 
     */
    fromTernary(i) {
        this.config.gPlaceholder
            .transition('fromTernary')
            .ease(d3.easeQuadOut)
            .delay(i * 5)
            .duration(200)
            .attr('x', this.data.svgCoords[0])
            .attr('y', this.data.svgCoords[1]);

        this.config.dotGroup
            .on('mouseover', () => this.config.dotGroup.attr('opacity', 1).select('text').attr('opacity', 1))
            .on('mouseout', () => {
                this.config.dotGroup.classed('brushed') ? this.config.dotGroup.attr('opacity', 1) : this.config.dotGroup.attr('opacity', 0.25).select('text').attr('opacity', 1)
            })
            .transition('fromTernary')
            .ease(d3.easeQuadOut)
            .delay(i * 5)
            .duration(200)
            .on('end', () => this.config.dotGroup.select('text').attr('opacity', 1));
    }


    /**
     * Decides whether to show the fold/unfold state
     * @param {*} state 
     */
    fold(state = true) {
        this.config.svgGroup.attr('display', state ? this.HIDDEN : this.VISIBLE);
        this.config.dotGroup.attr('display', state ? this.VISIBLE : this.HIDDEN);
    }

    /************************************************************
     * Methods to create stuff of the plot
     */

    /**
     * Creates the <g> element that will contain
     * all the visual stuff, all the <g>'s for
     * each state
     */
    createPlaceholder() {
        this.config.gPlaceholder = this.config.svg
            .append('svg')
            // this overflow visile is a life-saver...
            .style('overflow', 'visible')
            //.datum(this.label)            
            .attr('id', this.id)
            .attr('class', 'country-placeholder')
            .attr('display', this.display)
            .attr('x', this.placeholderInfo.x)
            .attr('y', this.placeholderInfo.y)
            .datum({
                datid: _.random(1, true),
                svgCoords: { x: this.data.svgCoords[0], y: this.data.svgCoords[1] },
                ternaryCoords: { x: this.data.ternaryCoords[0], y: this.data.ternaryCoords[1] }
            })
            .call(this.config.texture);

    };



    /**
     * Draw all the elements for the dot state (when the 
     * plot is just a point and the label)
     */
    drawDotPlot() {

        this.config.dotGroup = this.config.gPlaceholder
            .append('g')
            .attr('class', 'dotGroup');
        this.config.dotGroup.append('circle')
            .attr('cx', 0)
            .attr('cy', 0)
            .attr('r', this.dotRadius)
            .attr('fill', CONTINENT_COLORS[
                    _.find(this.data, d => !_.isUndefined(d.continent)).continent
                ]
            )
            .attr('fill', CONTINENT_COLORS[
                _.find(this.data, d => !_.isUndefined(d.continent)).continent
            ])
            .attr('stroke', CONTINENT_COLORS[
                _.find(this.data, d => !_.isUndefined(d.continent)).continent
            ]);

        this.config.dotGroup.append("text")
            .attr('pointer-events', 'none')
            .attr("text-anchor", "top")
            .attr("transform", "translate(5, -5)")
            .attr("font-size", this.dotLabelSize)
            .attr("fill", d3.color(CONTINENT_COLORS[
                _.find(this.data, d => !_.isUndefined(d.continent)).continent
            ]).darker())
            .text(this.label);
    };


    /**
     * Draws all the elements for the multidimensional plot
     */
    drawMultidimensionalPlot() {
        this.config.svgGroup = this.config.gPlaceholder
            .append('g')
            .attr('class', 'svgGroup')

        this.config.countryText = this.config.svgGroup.append('text')
            .attr("text-anchor", "middle")
            .attr('class', 'country-plot-title')
            .call(this.updateTitle, this);
        this.calculateScore(this.data, this.label);
    }



    /**
     * 
     * @param {*} data 
     * @param {*} country 
     * @param {*} fold 
     */
    calculateScore(data, country, fold = 'full') {

        data.forEach(SDG => {
            if (!SDG.ghostProjectsValue) {
                SDG.ghostScore = SDG[this.ghostVariable];
            } else {
                SDG.ghostScore = SDG.ghostProjectsValue[this.ghostVariable];
            }
            SDG.color = SDG_INFO[SDG.SDG]['color'];
            SDG.countryScore = SDG[this.countryVariable];
            SDG.width = 1;
            SDG.label = SDG_INFO[SDG.SDG]['title'];
        });

        this.updateMultidimensionalPlot(data, country, fold)
    }



    /**
     * 
     * @param {*} data 
     * @param {*} fold 
     */
    calculateGroupsScore(data, fold = 'full') {
        data.forEach(function (group) {
            group.score = .7;
            group.width = group.includes.length;
            group.label = group.name;
        });

        this.paintGroups(data, fold);
    }



    /**
     * 
     * @param {*} data 
     * @param {*} fold 
     */
    paintGroups(data, fold) {
        // expand a bit the outer radius
        // to allocate the arcs of the 
        // SDG groups
        var r = this.radius * 1.15;
        const groupArc = d3.arc()
            .padAngle(0.02)
            .innerRadius(r)
            .outerRadius(r + 1);

        // Groups flower

        const insideConfig = this.config

        this.config.svgGroup.selectAll(".groupArc")
            .data(this.config.pie(data))
            .join(
                enter => enter.insert("path")
                    .attr("id", function (d, i) {
                        return d.data.name + '-' + i
                    })
                    .attr("class", "groupArc")
                    .attr("fill", function (d) {
                        return d.data.color
                    })
                    .attr("d", groupArc)
                    .each(function (d, i) {
                        //(denoted by ^) and the first capital letter L
                        var firstArcSection = /(^.+?)L/;

                        //The [1] gives back the expression between the () (thus not the L as well)
                        //which is exactly the arc statement
                        var newArc = firstArcSection.exec(d3.select(this).attr("d"))[1];
                        //Replace all the comma's so that IE can handle it -_-
                        //The g after the / is a modifier that "find all matches rather than
                        //stopping after the first match"
                        newArc = newArc.replace(/,/g, " ");

                        //Create a new invisible arc that the text can flow along
                        insideConfig.svgGroup.append("path")
                            .attr("class", "hiddenDonutArcs")
                            .attr("id", "donutArc" + i)
                            .attr("d", newArc)
                            .style("fill", "none");
                    }),
                update => update.transition().duration(300).attr("opacity", fold === 'full' ? 1 : 0)
            )

        this.config.svgGroup.selectAll(".groupText")
            .data(this.config.pie(data))
            .join(
                enter => enter.append("text")
                    .attr("class", "donutText")
                    //Move the labels below the arcs for slices with an end angle > than 90 degrees
                    // .attr("dy", function(d,i) {
                    //     return (d.endAngle > 90 * Math.PI/180 ? 18 : -11);
                    // })
                    .attr("dy", -13)
                    .append("textPath")
                    .attr("startOffset", "50%")
                    // .attr("dx", 20)
                    .style("text-anchor", "middle")
                    .attr("fill", function (d) {
                        return d.data.color
                    })
                    .attr("opacity", .5)
                    .attr("xlink:href", function (d, i) {
                        // return "#" + d.data.name + "-" + i;
                        return "#donutArc" + i;
                    })
                    .text(function (d) { return d.data.name; }),
                update => update.transition().duration(300).attr("fill", function (d) { return fold === 'full' ? d.data.color : 'transparent' })
            )
    }

    updateMultidimensionalPlot(data, country, fold) {
        if(this.placeholderInfo.isFake)
            return;

        // the length of the 'petal' is based on the percentage of each SDG.
        // to avoid having short petals because of low percentages, we look
        // for the max percentage from all the SDGs. This percentage will
        // be the outerRadius for our country
        let pieData = this.config.pie(data),
            maxPercentage = _.max(_.map(pieData, 'data.countryScore')),
            // define an scale to accomodate all the SDG percentages through
            // the inner and outer radius. ScaleSqrt in order to make the
            // small petals (lower percentages) bigger than with a linear scale
            radiusScale = d3.scaleSqrt()
                            .domain([0, maxPercentage])
                            .range([this.config.innerRadius, this.config.radius]);

        // sort data by countryScore, so SDG with
        // less score (they will be smaller) are
        // painted at the end (so they are not
        // overlapped by bigger SDGs)
        pieData = _.reverse(_.sortBy(pieData, o => o.data.countryScore));
        // ... but those with countryScore 0, we
        // want them to be at the bottom! so:
        pieData = _.concat(
            _.reject(pieData, o => o.data.countryScore !== 0), 
            _.filter(pieData, o => o.data.countryScore !== 0)
        );

        // func to calculate coords for lines
        let lineCoord = (d) => {
            var r = d.data.countryScore === 0? 
                this.config.radius / 2 : 
                radiusScale(d.data.countryScore);
            
            let angle = (d.startAngle + d.endAngle) / 2 - Math.PI / 2;
            return [Math.cos(angle) * r, Math.sin(angle) * r];
        }
        // define a stroke width for the SDG's proportional
        // to the space available
        const strokeWidthSDG = this.config.radius * 0.12;
        this.config.svgGroup.selectAll('.line-sdg-' + _.replace(country, ' ', ''))
            .data(pieData)
            .join(                                        
                // lines with a very wide stroke, like:
                // https://truth-and-beauty.net/projects/oecd-regional-wellbeing
                enter => enter.insert('line')
                    .attr('class', 'line-sdg-' + _.replace(country, ' ', ''))
                    .attr('stroke-linecap', 'round')
                    .attr('x1', d => {
                        return Math.cos(
                            (d.startAngle + d.endAngle) / 2 - Math.PI / 2
                        ) * radiusScale(0);
                    })
                    .attr('y1', d => {
                        return Math.sin(
                            (d.startAngle + d.endAngle) / 2 - Math.PI / 2
                        ) * radiusScale(0);
                    })
                    .attr('x2', d => _.first(lineCoord(d)))
                    .attr('y2', d => _.last(lineCoord(d)))
                    .attr('stroke-width', strokeWidthSDG)
                    .attr("fill",   d => d.data.countryScore === 0? '#f4f4f4' : d.data.color)
                    .attr("stroke", d => d.data.countryScore === 0? '#f4f4f4' : d.data.color)
                    .on('mouseover', (d) => {
                        Tooltip.show(
                            d.data.shortTitle, 
                            d.data.SDG + (d.data.countryScore === 0? ' (without classified projects)':''), 
                            d.data.countryScore === 0 ? []:
                            [   { key: "% of projects classified", value: d3.format('.0%')(d.data.p_numProjectsSDG) },
                                { key: "Total project of the country", value: d.data.totalProjectsCountry },
                                { key: 'Specialization Index', value: d3.format(".1f")(d.data.SI_numProjects) }
                            ]);
                    })
                    .on('mouseout', Tooltip.hide),
                update => update
                    .transition()
                    .delay((d, i) => i*50)
                    .duration(300)
                    .attr('x1', d => {
                        return fold === 'full'? Math.cos(
                            (d.startAngle + d.endAngle) / 2 - Math.PI / 2
                        ) * radiusScale(0) : 0;
                    })
                    .attr('y1', d => {
                        return fold === 'full'? Math.sin(
                            (d.startAngle + d.endAngle) / 2 - Math.PI / 2
                        ) * radiusScale(0) : 0;
                    })
                    .attr('x2', d => fold === 'full'? _.first(lineCoord(d)) : 0)
                    .attr('y2', d => fold === 'full'? _.last(lineCoord(d)) : 0)
                    .attr('stroke-width', strokeWidthSDG)
                    .on('end', (d, i) => {
                        // ugly hack to wait for
                        // all the transition ends (16 SDGs)
                        if(i === 15) {
                            if (fold !== 'full') this.fold();
                        }
                    }),
                // exit
                exit => exit.remove()
            );

        // draw the lines for the Specialization Index

        // util function to get the [x2, y2]
        // of the line indicating the SI
        let coordsSI = (d, fixedSIValue) => {
            var r = radiusScale(d.data.countryScore);
            r = (r <= this.config.innerRadius) ? this.config.innerRadius : r;

            const scaleSI = d3.scaleLog()
                .base(2)
                .domain([0.125, 8])
                .range([this.config.innerRadius, r]);
            
            // clamp SI values when they are greater than the
            // domain of the S.I., so we don't get lines out
            // of the outer radius
            let pos = scaleSI(
                    _.clamp(
                        fixedSIValue || d.data.SI_numProjects, 
                        _.first(scaleSI.domain()), 
                        _.last(scaleSI.domain())
                    )
                ),
                angle = (d.startAngle + d.endAngle) / 2 - Math.PI / 2;

            return [ Math.cos(angle) * pos, Math.sin(angle) * pos];
        };
  
        // to represent the SI for an SDG, we 
        // will use an log scale with base 2.
        // our SI scale will have this values:
        // [ 0.125 (8 times less specialized),
        //   0.250 (4 times less specialized),
        //   0,5 (2 times less specialized),
        //   1 (equal specialization than average) ,
        //   2 (2x times more specialized),
        //   4 (4x times more specialized),
        //   8 (8x times more specialized)                      
        // ]
        const SI_values = [8, 4, 2, 1, .5, .25, .125];
            
        this.config.svgGroup.selectAll('.line_si_' + country).remove();
        (fold === 'full') && this.config.svgGroup
            .selectAll('.line_si_' + country)
            .data(pieData)
            .join(
                enter => enter.insert("line")
                    .attr('x1', d => {
                        return Math.cos(
                            (d.startAngle + d.endAngle) / 2 - Math.PI / 2
                        ) * radiusScale(0);
                    })
                    .attr('y1', d => {
                        return Math.sin(
                            (d.startAngle + d.endAngle) / 2 - Math.PI / 2
                        ) * radiusScale(0);
                    })                    
                    .style('pointer-events', 'none')
                    .attr('class', 'line_si_' + country)
                    .attr("stroke-width", strokeWidthSDG / 4)
                    .attr('stroke-linecap', 'round')
                    .attr("stroke", 'white')
                    .attr('x2', d => coordsSI(d)[0])
                    .attr('y2', d => coordsSI(d)[1])
                    .attr('opacity', 0.95)
            );

        // draw line perpendicular to the SDG line
        // to show the SI=1 threshold
        this.config.svgGroup.selectAll('.line_si1_' + country).remove();
        (fold === 'full') && this.config.svgGroup
            .selectAll('.line_si1_' + country)
            .data(pieData)
            .join('line')
            .each( d => {
                if(d.data.countryScore === 0)
                    return;
                var startX = coordsSI(d, _.last(SI_values))[0],
                    startY = coordsSI(d, _.last(SI_values))[1],
                    endX = coordsSI(d, 1)[0],
                    endY = coordsSI(d, 1)[1],
                    angle = Math.atan2(endY - startY, endX - startX),
                    dist = strokeWidthSDG/3;
                
                this.config.svgGroup.append('line')
                    .attr('class', 'line_si1_' + country)
                    .attr('x1',  Math.sin(angle) * dist + endX)
                    .attr('y1', -Math.cos(angle) * dist + endY)
                    .attr('x2', -Math.sin(angle) * dist + endX)
                    .attr('y2',  Math.cos(angle) * dist + endY)
                    .attr('stroke', 'white')
                    .attr('stroke-linecap', 'butt')
                    .attr('stroke-width', strokeWidthSDG/8)
                    .style('pointer-events', 'none')
                    .attr('opacity', 0)
                    .transition()
                    .delay(300)
                    .attr('opacity', 0.9)
            });

        this.config.svgGroup.selectAll('.dots_si' + country).remove();
        (fold === 'full') && SI_values.forEach((p, i) => {            
            this.config.svgGroup.selectAll('.dots_si' + country + ' ' + 'dot' + i)
                .data(pieData)
                .join(
                    enter => enter.insert('circle')
                        .style('pointer-events', 'none')
                        .attr('class', 'dots_si' + country + ' ' + 'dot' + i)
                        .attr('cx', d => coordsSI(d, p)[0])
                        .attr('cy', d => coordsSI(d, p)[1])
                        .attr('r', 1)
                        .attr("fill", d => {
                            let SI_value_dot = p;
                            // decide color whether the si_bar overlaps the point or not
                            let color = SI_value_dot > d.data.SI_numProjects? 'white': d.data.color;                            
                            // but for the dot less than 0.250, just white, not visible
                            return color; //SI_value_dot <= .250 ? 'white':color;
                        })
                        .attr('opacity', 0)
                        .call( enter => enter.transition().delay(1000).attr('opacity', 0.4))
                );
        });

        // update text according whether isPrimary and
        // the current size of the placeholder
        this.config.countryText
            .text(_.truncate(country, {'length': 11}))
            .call(this.updateTitle, this);
    }
}