export const streamgraph = {
    "$schema": "https://vega.github.io/schema/vega/v5.json",
    "title": {},
    "signals": [
      {"name": "splitYear", "value": 2013}
    ],
    "description": `some notes:
      - Order can be done by the field order (to follow alphabetic SDG labels), totalCount (to sort by most overall counted) or by count(to sort by count per year )
      `,
    "data": [
        {
          "name": "table",
          "values": null,
          "transform": [
            {
              "type": "stack",
              "field": "count",
              "offset": "normalize", 
              "groupby": ["year"],
              "sort": {"field": ["order"], "order": ["descending"]}
            }
          ]
        },
        {
          "name": "series",
          "source": "table",
          "transform": [
            {
              "type": "aggregate",
              "groupby": ["SDG"],
              "fields": ["count"],
              "ops": ["sum"],
              "as": ["sum"]
            }
          ]
        },
        {
            "name": "sdg-colors",
            "values": [
                { sdg: 'SDG 1' , color: '#E5243B'},
                { sdg: 'SDG 2' , color: '#DDA63A'},
                { sdg: 'SDG 3' , color: '#4C9F38'},
                { sdg: 'SDG 4' , color: '#C5192D'},
                { sdg: 'SDG 5' , color: '#FF3A21'},
                { sdg: 'SDG 6' , color: '#26BDE2'},
                { sdg: 'SDG 7' , color: '#FCC30B'},
                { sdg: 'SDG 8' , color: '#A21942'},
                { sdg: 'SDG 9' , color: '#FD6925'},
                { sdg: 'SDG 10' , color: '#DD1367'},
                { sdg: 'SDG 11' , color: '#FD9D24'},
                { sdg: 'SDG 12' , color: '#BF8B2E'},
                { sdg: 'SDG 13' , color: '#3F7E44'},
                { sdg: 'SDG 14' , color: '#0A97D9'},
                { sdg: 'SDG 15' , color: '#56C02B'},
                { sdg: 'SDG 16' , color: '#00689D'},
                { sdg: 'SDG 17' , color: '#00689D'}
            ]
        },
        {
          "name": "programs",
          "values": [
            { id:"fp7", name: "7th Framework Programme" },
            { id: "h2020", name: "Horizon 2020 Programme" }
          ]
        }
      ],
    "axes": [
      {
        "orient": "bottom",
        "scale": "x",
        "labelPadding": 5,
        "grid": true,
        "gridColor": "whitesmoke",
        "gridDash": [2, 2],
        "gridOpacity": 1,
        "domainDash": [1, 1],
        "domainOpacity": 0,
        "ticks": false,
        "encode": {
          "labels": {
            "name": "year-labels",
            "update": {
              "align": {"signal": "datum.value === 2008 ? 'left' : datum.value === 2019 ? 'right':'center' "},
              "dx": {"signal": "datum.value === 2008 ?  2 : datum.value === 2019 ? -2:0 "}
            }
          }
        }
      },
      {
        "orient": "top",
        "scale": "x",
        "labelPadding": 5,
        "domainDash": [1, 1],
        "domainOpacity": 0,
        "ticks": false,
        "encode": {
          "labels": {
            "name": "year-labels-2",
            "update": {
              "align": {"signal": "datum.value === 2008 ? 'left' : datum.value === 2019 ? 'right':'center' "},
              "dx": {"signal": "datum.value === 2008 ?  2 : datum.value === 2019 ? -2:0 "}
            }
          }
        }   
      }
    ],
    "scales": [
      {
        "name": "x",
        "type": "point",
        "range": "width",
        "zero": false,
        "round": true,
        "domain": {"data": "table", "field": "year"}
      },
      {
        "name": "y",
        "type": "linear",
        "range": "height",
        "round": true,
        "zero": true,
        "domain": {"data": "table", "field": "y1"}
      },
      {
        "name": "color",
        "type": "ordinal",
        "domain": ['SDG 1','SDG 2','SDG 3','SDG 4','SDG 5','SDG 6','SDG 7','SDG 8','SDG 9','SDG 10','SDG 11','SDG 12','SDG 13','SDG 14','SDG 15','SDG 16','SDG 17'],
        "range": ['#E5243B', '#DDA63A', '#4C9F38', '#C5192D', '#FF3A21', '#26BDE2', '#FCC30B', '#A21942', '#FD6925', '#DD1367', '#FD9D24', '#BF8B2E', '#3F7E44', '#0A97D9', '#56C02B', '#00689D', '#00689D']
      }
    ],
    "marks": [
      {
        "type": "group",
        "from": {
          "data": "series",
          "facet": {"name": "facet", "data": "table", "groupby": ["SDG"]}
        },
        "marks": [
          {
            "type": "area",
            "interactive": false,
            "name": "sdg-serie",
            "from": {"data": "facet"},
            "encode": {
              "update": {
                "interpolate": {"value": "monotone"},
                "x": {"scale": "x", "field": "year"},
                "y": {"scale": "y", "field": "y0"},
                "y2": {"scale": "y", "field": "y1"},
                "fill": {"scale": "color", "field": "SDG"},
                "fillOpacity": {"value": 1}
              }
            }
          }
        ]
      },
      {
        "type": "rect",
        "from": {"data":"programs"},
        "encode": {
          "enter": {
            "x": {
              "signal": "datum.id === 'fp7' ? scale('x', 2008) : scale('x', 2013)"
            },
            "x2": {
              "signal": "datum.id === 'h2020' ? scale('x', 2013) : scale('x', 2019)"
            },
            "y": {"value": -50},
            "y2": {"value": -25},
            "fill": {"value": "#eaeef1"}
          }
        }
      },
      {
        "type": "text",
        "from": {"data":"programs"},
        "name": "eu-program-title",
        "encode": {
          "enter": {
            "x": {
              "signal": "datum.id === 'fp7' ? (scale('x', 2013) - scale('x', 2008))/2 : scale('x', 2013) + (scale('x', 2019) - scale('x', 2013))/2"
            },
            "y": {"value": -33},
            "text": {"signal": "datum.name"},
            "fill":{"value": "#19486A"},
            "baseline": {"value": "middle"},
            "align": {"value": "center"},
            "font": {"value": "sans-serif, Verdana, Arial"},
            "fontWeight": {"value": "bold"}
          }
        }
      },
      {
        "type": "rule",
        "encode": {
          "enter": {
            "y": {"value": -25},
            "y2": {"signal": "height "},
            "stroke": {"value": "#333"},
            "strokeOpacity": {"value": 0.5},
            "strokeWidth": {"value": 3},
            "strokeDash": {
              "value": [5, 5]
            },
            "x": {"scale": "x", "signal": "splitYear"},
            "x2": {"scale": "x", "signal": "splitYear"}
          }
        }
      },
      {
        "type": "text",
        "name": "split-year-annotation",
        "encode": {
          "enter": {
            "x": {
              "signal": "scale('x', 2013)"
            },
            "y": {
              "value": -36
            },
            "text": {"value": "End of FP7 and H2020 starts"},
            "fill": {"value": "whitesmoke"},
            "baseline": {"value": "middle"},
            "align": {"value": "center"},
            "fontWeight": {
              "value": "bold"
            }
          }
        }
      }
    ]
  }