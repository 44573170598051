export const areaChart = {
  "$schema": "https://vega.github.io/schema/vega/v5.json",
  "padding": 0,
  "signals": [
    {
      "name": "symbolMouseover",
      "description": "This signal has a listener on the Vega view runtime",
      "value": null,
      "on": [
        {
          "events": "rect:mouseover",
          "update": "datum.startingYear"
        },
        {
          "events": "rect:mouseout",
          "update": "null"
        }
      ]
    },
    {
      "name": "symbolHighlight",
      "value": null,
      "description": "This signal is updated from the view runtime, and receives the datum of the signal 'symbolMouseover', so we can highlight the hovered point in another view different from this one"
    },
    {
      "name": "hover",
      "on": [
        { "events": "*:mouseover", "encode": "hover" },
        { "events": "*:mouseout", "encode": "leave" }
      ]
    },
    {
       "name": "bgColor",
       "value": "#19486A"
    }
  ],
  "data": [
    {
      "name": "table",
      "values": null,
      "transform": [
        {
          "type": "filter",
          "expr": "datum.startingYear !== 2007 && datum.startingYear !== 2020"
        },
        {
          "type": "stack",
          "groupby": ["startingYear"],
          "sort": { "field": "SDG" },
          "offset": "zero",
          "field": "p_numProjectsSDG"
        }
      ]
    }
  ],
  "scales": [
    {
      "name": "x",
      "type": "point",
      "range": "width",
      "domain": { "data": "table", "field": "startingYear" }
    },
    {
      "name": "y",
      "type": "linear",
      "range": "height",
      "zero": true,
      "domain": { "data": "table", "field": "p_numProjectsSDG" }
    },
    {
      "name": "color",
      "type": "ordinal",
      "range": "category",
      "domain": { "data": "table", "field": "SDG" }
    },
    {
      "name": "xscale",
      "type": "band",
      "domain": { "data": "table", "field": "startingYear" },
      "range": "width",
      "round": true
    },
    {
      "name": "yscale",
      "type": "linear",
      "domain": [0, 1],
      "range": "height"
    }
  ],
  "axes": [
    {
      "orient": "bottom", 
      "scale": "x", 
      "zindex": 1, 
      "labelOverlap": true, 
      "labelFlush": true, 
      "domain": false,
      "ticks": false,
      "labelPadding": 6,
      "labelColor": { "signal": "bgColor" },
      "values": [2008, 2019]
    },
    {
      "orient": "left",
      "scale": "y",
      "encode": {
        "labels": {
          "interactive": true,
          "update": {"text": {"signal": "datum.value === 0? '':format(datum.value, '.1%')"}}
        }
      },
      "zindex": 1,
      "labelOverlap": true,
      "labelFlush": true,
      "ticks": false,
      "tickCount": 0,
      "labelPadding": 5,
      "domain": false
    }
  ],
"marks": [
  {
    "type": "group",
    "from": { "facet": { "name": "series", "data": "table", "groupby": "SDG" } },
    "marks": [
      {
        "type": "area",
        "from": { "data": "series" },
        "encode": {
          "enter": {
            "interpolate": { "value": "monotone" },
            "x": { "scale": "x", "field": "startingYear" },
            "y": { "scale": "y", "field": "y0" },
            "y2": { "scale": "y", "field": "y1" },
            "fill": { "signal": "datum.color" }
          }
        }
      },
      {
        "type": "rect",
        "from": { "data": "series" },
        "encode": {
          "enter": {
            "x": { "scale": "xscale", "field": "startingYear" },
            "width": { "scale": "xscale", "band": 1 },
            "y": { "scale": "yscale", "value": 1 },
            "y2": { "scale": "yscale", "value": 0 }
          },
          "update": {
            "opacity": { "value": 0 },
            "fill": { "signal": "datum.color" },
            "tooltip": {
              "signal": "{Year: datum.startingYear, Projects: format(datum.p_numProjectsSDG, '.1%')}"
            }
          }
        }
      },
      {
        "type": "text",
        "from": {"data": "series"},
        "encode": {
          "enter": {
            "dy": {"value": -6},
            "text": {"signal": "format(datum.p_numProjectsSDG, '.1%')"},
            "fontSize": {"value": 11},
            "baseline": {"value": "bottom"},
            "interactive": {"value": false},
            "fill": {"signal": "bgColor"}
          },
          "update": {
            "x": {"scale": "x", "field": "startingYear"},
            "y": {"scale": "y", "field": "y1"},
            "align": {"signal": "datum.startingYear === 2008 ? 'right' : 'left'"},
            "opacity": {
              "signal": "datum.startingYear === 2008 || datum.startingYear === 2019 ? 1:0"
            }
          }
        }
      },
      {
        "type": "text",
        "from": {
          "data": "series"
        },
        "encode": {
          "enter": {
            "dy": {
              "value": -6
            },
            "text": {
              "signal": "format(datum.p_numProjectsSDG, '.1%')"
            },
            "opacity": {
              "value": 0
            },
            "align": {
              "value": "center"
            },
            "fontSize": {
              "value": 11
            },
            "baseline": {
              "value": "bottom"
            },
            "interactive": {
              "value": false
            },
            "fill": { "signal": "bgColor" }
          },
          "update": {
            "x": {
              "scale": "x",
              "field": "startingYear"
            },
            "y": {
              "scale": "y",
              "field": "y1"
            },
            "opacity": {
              "signal": "symbolHighlight === datum.startingYear && (datum.startingYear !== 2008 && datum.startingYear !== 2019) ? 1 : 0"
            }
          }
        }
      },
      {
        "type": "symbol",
        "style": "circle",
        "from": {
          "data": "series"
        },
        "encode": {
          "enter": {
            "opacity": {
              "value": 0
            },
            "stroke": { "signal": "datum.color" },
            "fill": { "signal": "datum.color" },
            "strokeWidth": { "value": 1 }
          },
          "update": {
            "x": {
              "scale": "x",
              "field": "startingYear"
            },
            "y": {
              "scale": "y",
              "field": "y1"
            },
            "opacity": {
              "signal": "symbolHighlight === datum.startingYear ? 1 : 0"
            }
          }
        }
      }
    ]
  }
]
}