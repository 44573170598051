import React from 'react';
import { RouteApp } from './routes';

class App extends React.Component {
  render() {
      return (
          <div className="app-container">
              <RouteApp />
          </div>
      )        
  } 
}

export default App;
