export async function postQuery(query) {
    const response = await fetch(
        'http://ec2-52-51-177-228.eu-west-1.compute.amazonaws.com/unics/sparql/query', 
        {
            method: 'POST',
            mode: 'cors',
            headers: { 
                // must set this content type when querying via POST
                'Content-Type': 'application/x-www-form-urlencoded',
                // get sure we get a JSON instead default XML
                'Accept': 'application/sparql-results+json'
            },
            body: 'query=' + query
        });

    // fetch won’t reject on HTTP error status even if the response
    // is an HTTP 404 or 500. Instead, it will resolve normally with
    // ok status set to false
    if(response.ok)
        return await response.json();
    else {
        // capture the error message
        const err = await response.json();            
        throw Error(
            (err.error + ': ' + err.message).replace(/(\r\n|\n|\r)/gm, "")
        );
    }
};